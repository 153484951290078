import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomOverlapBannerComponent } from './bottom-overlap-banner/bottom-overlap-banner.component';
import { BottomTextBannerComponent } from './bottom-text-banner/bottom-text-banner.component';
import { LeftImageBannerComponent } from './left-image-banner/left-image-banner.component';
import { LeftOverlapBannerComponent } from './left-overlap-banner/left-overlap-banner.component';
import { RightImageBannerComponent } from './right-image-banner/right-image-banner.component';
import { RightOverlapBannerComponent } from './right-overlap-banner/right-overlap-banner.component';
import { MmPosterComponent } from './mm-poster/mm-poster.component';
import { MMIconsModule, SafePipeModule } from 'mm-ui';
import { MenuTemplatesComponent } from './menu-templates/menu-templates.component';
import { PipesModules } from '../pipes/pipes.modules';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { NguCarouselModule } from '@ngu/carousel';
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        BottomOverlapBannerComponent,
        BottomTextBannerComponent,
        LeftImageBannerComponent,
        LeftOverlapBannerComponent,
        RightImageBannerComponent,
        RightOverlapBannerComponent,
        MmPosterComponent,
        MenuTemplatesComponent,
        ScrollToTopComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MMIconsModule,
        PipesModules,
        RouterModule,
        TranslateModule,
        SafePipeModule,
        NguCarouselModule,
        ReactiveFormsModule
    ],
    exports: [
        BottomOverlapBannerComponent,
        BottomTextBannerComponent,
        LeftImageBannerComponent,
        LeftOverlapBannerComponent,
        RightImageBannerComponent,
        RightOverlapBannerComponent,
        MmPosterComponent,
        MenuTemplatesComponent,
        ScrollToTopComponent
    ],
    providers: [],
})
export class AutonomousComponentsModule {}
