import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';

@Pipe({
  name: "sort"
})
export class ArraySortPipe  implements PipeTransform {
  transform(array: any, field: string): any[] { 
    let a = _.orderBy(array, [item => item[field].toLowerCase().trim()], ['asc'])
    return a
  }
}