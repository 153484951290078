// Generated by https://quicktype.io

export interface HomepageInterface {
    id?:                     string;
    title?:                  string;
    language?:               string;
    completedData?:          boolean;
    colors?:                 Color[];
    completedColors?:        boolean;
    banner?:                BannerInterface;
    widgetMovementAllowed?: boolean 
    column1?:                Column;
    column2?:                Column;
    column3?:                Column;
    column4?:                Column;
}

export interface Color {
    id?:     string;
    first:  string;
    second: string;
    third:  string;
    fourth: string;
    fifth:  string;
    sixth:  string;
    order:  number;
}


export interface BannerInterface {
    id:       string;
    size:     BannerSize;
    position: BannerPosition;
    side:     BannerSide;
}

export enum BannerSide {
	DOUBLE_WIDTH_HIGHLIGHT = "DOUBLE_WIDTH_HIGHLIGHT",
	TWO_HIGHLIGHT = "TWO_HIGHLIGHT",
	HIGHLIGHT = "HIGHLIGHT" ,
	HIGHLIGHT_AND_WIDGET = "HIGHLIGHT_AND_WIDGET",
	TWO_WIDGETS = "TWO_WIDGETS",
	WIDGET = "WIDGET" ,
	LEFT_HIGHLIGHT_RIGHT_WIDGET = "LEFT_HIGHLIGHT_RIGHT_WIDGET" ,
    LEFT_WIDGET_RIGHT_HIGHLIGHT = "LEFT_WIDGET_RIGHT_HIGHLIGHT"  
}

export enum BannerPosition {
    CENTER = 'CENTER',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export enum BannerSize {
	SMALL = 'SMALL',
	MEDIUM = 'MEDIUM',
	BIG = 'BIG'
	
}

export interface Widget {
    widgetMovementAllowed?: boolean;
    column1?:               Column;
    column2?:               Column;
    column3?:               Column;
    column4?:               Column;
}

export interface WidgetConfig {
//...
}

export interface Column {
    id:      string;
    widgets: Column1Widget[];
}

export interface Column1Widget {
    id:       string;
    type:     string;
    reusable: boolean;
    order:    number;
}
