import { NgModule } from "@angular/core";
import { BreadcrumbComponent } from "./breadcrumb.component";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRouteSnapshot } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations:[BreadcrumbComponent],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule
    ],
    exports:[
        BreadcrumbComponent
    ],
    providers: [

    ]
})

export class BreadcrumbModule{

}
