import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppConfig } from "../app.config";
import { ChatService } from "../components/chat/chat.service";
import { ContentMenuService } from "../components/cms/content/menu/content-menu.service";
import { PlatformConfigService } from "../components/platform-config/platform-config.service";
import { GlossaryHighlightService } from "../components/view/dynamic-html/glossary-highlight.service";
import { Notify } from "../interfaces/Notify";
import { EventType } from "../models/events.model";
import { LoggedUser } from "../models/loggedUser.service";
import { b64ToBLob } from "../utils/utils";
import { NotifyService } from "./notify.service";

@Injectable()
export class EventsService {
  constructor(
    private loggedUser: LoggedUser,
    private notifyService: NotifyService,
    private platformConfigService: PlatformConfigService,
    private glossaryHighlightService: GlossaryHighlightService,
    private contentMenuService: ContentMenuService,
  ) { }

  private apiVersion = `v${AppConfig.data['apiVersion']}`;
  private service = AppConfig.data['services']['events'];
  private endpoint = `${this.service}/${this.apiVersion}`
  private authLogout = new Subject<boolean>();
  private chatMessage = new Subject<any>();
  private eventSource: EventSource;


  listenToEvents() {
    const token = this.loggedUser.getToken();

    if (token && !this.eventSource) {
      this.eventSource = new EventSource(`${this.endpoint}/consumer/events?token=${token}`);

      this.eventSource.addEventListener(EventType.NOTIFICATION, (message: MessageEvent) => this.sendNotification(message))
      this.eventSource.addEventListener(EventType.LGPD_UPDATED, () => this.showLgpdModal())
      this.eventSource.addEventListener(EventType.SESSION_EXPIRED, () => this.authLogout.next(true))
      this.eventSource.addEventListener(EventType.GLOSSARY_UPDATED, () => this.glossaryHighlightService.getTermsFromBackend())
      this.eventSource.addEventListener(EventType.SIDEBAR_MENU_UPDATED, (message: MessageEvent) => this.updateSideMenu(message))
      this.eventSource.addEventListener(EventType.CHAT_MESSAGE, (message: MessageEvent) => this.sendChatMessage(message))
      this.eventSource.addEventListener(EventType.FINISHED_PROCESS_REPORT, (message: MessageEvent) => this.downloadFile(JSON.parse(message.data)))
    }
  }

  downloadFile(data: any) {
    const base64 = data.base64;
    const filename = data.filename;
    const contentType = data.contentType;

    const blob = b64ToBLob(base64, contentType);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  }

  sendNotification(message: MessageEvent) {
    const notification = JSON.parse(message.data) as Notify;

    this.notifyService.emitNotification(notification)
  }

  showLgpdModal() {
    this.platformConfigService.getAndShowTerm();
  }

  listenToLogout() {
    return this.authLogout.asObservable();
  }

  updateSideMenu(message: MessageEvent) {
    const { menuId, homepageId } = JSON.parse(message.data);

    if (menuId) {
      return this.contentMenuService.updateMenu(menuId, 'MENUID');
    }

    if (homepageId) {
      this.contentMenuService.updateMenu(homepageId, 'HOMEPAGE')
    }
  }

  sendChatMessage(m: MessageEvent) {
    const data = JSON.parse(m.data);

    this.chatMessage.next(data);
  }

  listenToChatMessage() {
    return this.chatMessage.asObservable();
  }
}
