import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotifyTrayComponent } from './notify-tray.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotifyTrayListComponent } from './notify-tray-list/notify-tray-list.component';
import { MediaService } from '../../components/media/media.service';
import { RouterModule } from '@angular/router';
import { MMIconsModule, TooltipModule } from 'mm-ui';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SafePipeModule } from 'mm-ui';


@NgModule({
  declarations: [NotifyTrayComponent, NotifyTrayListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MMIconsModule,
    TooltipModule,
    NgScrollbarModule,
    SafePipeModule
  ],
  exports: [
    NotifyTrayComponent
  ],
  providers: [
    MediaService
  ]
})
export class NotifyTrayModule { }
