import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class FontFamilyService {

  constructor(
  ) {
  }

  setFontFamily(fontUrl): void {

    let nameFont: string = "custom";
    let urlFont: string = fontUrl;
    // let urlFont: string = "https://storage.googleapis.com/mmp5-br-qa-public/3d358d2a-bf3d-4d52-8e9b-350985bd08e6";

    let style = document.createElement('style');
    style.innerText = "@font-face { font-family: '" + nameFont + "'; src: url('" + urlFont + "'); } \
    html, body, div, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, \
    a, abbr, acronym, address, big, cite, code, del, dfn, img, ins, kbd, q, s, samp,\
    small, strike, p strong, strong, sub, sup, tt, var, b, u, center, dl, dt, dd, ol, ul, li,\
    fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,\
    article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup,\
    menu, nav, output, ruby, section, summary, time, mark, audio, video, a .menu-header, textarea { font-family: '" + nameFont + "' !important; }";
    
    document.head.appendChild(style);

  }


}