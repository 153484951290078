import { Action } from "@ngrx/store";
import { Color } from "../models/homepage";


export const SET_COLORS = '[COLORS] Set Colors'
export const RESET_COLORS = '[COLORS] Reset Colors'

export class SetColors implements Action{
    readonly type = SET_COLORS

    constructor (public payload: Color[]){}
}

export class ResetColors implements Action {
    readonly type = RESET_COLORS

    constructor(){}
}

export type Actions = SetColors | ResetColors