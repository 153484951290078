<div class="container">
    <div class="row mt-2 text-center justify-content-center">        
        <h1 class="col-12 strong-color"> {{title}}  </h1>    
        <p class="col-lg-6 p-color"> {{message}} </p>
    </div>
    <div class="row text-center justify-content-center">
        <p class="col-lg-6 p-color" *ngIf="subMessage">{{subMessage}}</p>  
    </div>
    <div class="row align-top">
        <div class="col-12 text-center">
            <img src="{{imgurl}}" alt="Access Denied Image"  class="{{imgclass}}"/>
        </div>
    </div>
</div>