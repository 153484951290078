<div class="content-search-page">
  <div class="search">
    <mmp5-fieldset-haruki
      [formControl]="search"
      [type]="'search'">
    </mmp5-fieldset-haruki>
    <div class="btn-filter" (click)="$event.stopPropagation();filters = !filters">
        <mmp5-mm-icons [icon]="['icon','icon-filtro']"></mmp5-mm-icons>
    </div>
  </div>
  <div @fadeTop class="filters-content dont-hide" (click)="$event.stopPropagation()" *ngIf="filters">
      <div id="filters-content-container">
          <mmp5-fieldset-haruki
              [formControl]="published"
              [checked]="published.value"
              [type]="'checkbox'"
              [key]="'check_published'"
              [label]="'cms.content.menu.viewPublishedPages' | translate">
          </mmp5-fieldset-haruki>
          <mmp5-fieldset-haruki
              [formControl]="expired"
              [checked]="expired.value"
              [type]="'checkbox'"
              [key]="'check_expired'"
              [label]="'cms.content.menu.viewExpiredPages' | translate">
          </mmp5-fieldset-haruki>
          <mmp5-fieldset-haruki
              [formControl]="validDate"
              [checked]="validDate.value"
              [type]="'checkbox'"
              [key]="'check_validDate'"
              [label]="'cms.content.menu.viewPagesScheduled' | translate">
          </mmp5-fieldset-haruki>
          <mmp5-fieldset-haruki
              [formControl]="noPublishedDate"
              [checked]="noPublishedDate.value"
              [type]="'checkbox'"
              [key]="'check_noPublishedDate'"
              [label]="'cms.content.menu.viewPagesWithoutPublication' | translate">
          </mmp5-fieldset-haruki>
      </div>
  </div>
</div>
