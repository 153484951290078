import { Pageable } from "../interfaces/pageable";
import { AclInterface } from "./aclInterface";

// Generated by https://quicktype.io

export interface Media {
  title: string;
  title_original?: string;
  url?: string;
  content_type?: string;
  description: string;
  is_image?: boolean;
  url_download?: string;
  publishing_date: string;
  publishing_hour: string;
  tags: string;
  expiration_date: string;
  expiration_hour: string;
  base64_complete: string;
  id: string;
  width?: string;
  height?: string;
  filename?: string;
  original_filename?: string;
  extension?: string;
  size?: string;
  original_size?: number;
  larger_size?: boolean;
  max_size?: number;
  acl?: AclInterface;
  mime_codec?: string;
}

export interface Tag {
  name: string;
}

export interface MediaSearchFilters {
  query: string;
  format: string;
  pre_page: string;
  sort: string;
  end_date: string;
  start_date: string;
}

// Generated by https://quicktype.io

export interface MediaShort {
  id: string;
  client_id: string;
  title: string;
  title_original: string;
  content_type: string;
  contentType: string;
  format: string;
  created_at: string;
  updated_at: string;
  url: string;
}

export interface MediaShortView {
  id: string,
  mediaId: string,
  mediaTitle: string,
  mediaUrl: string,
  order: string,
}

export interface MediaContent {
  content: MediaShort[];
  pageNumber: number;
  pageSize: string;
  total: string;
  totalElements: number;
  number?: number;
}

export interface MediaSelectedOptions {
  image: boolean;
  video: boolean;
  document: boolean;
  sheet: boolean;
  presentation: boolean;
  other: boolean;
  pdf: boolean;
}
