import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ZoomImageComponent } from "./zoom-image.component";
import { ZoomServiceController } from "./zoom-image.service";
import { MMPdfViewerModule } from "../mm-pdf-viewer/mm-pdf-viewer.module";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { SafePipeModule } from "../safePipe/safePipe.module";
import { NgScrollbarModule } from "ngx-scrollbar";
import { MMIconsModule } from "../mm-icons/mm-icons.module";


@NgModule({
    imports: [
        CommonModule,
        PdfViewerModule,
        SafePipeModule,
        MMPdfViewerModule,
        NgScrollbarModule,
        MMIconsModule
    ],
    declarations: [
        ZoomImageComponent
    ],
    exports: [
        ZoomImageComponent
    ],
    providers: [
        ZoomServiceController
    ]
})

export class ZoomImageModule {}
