import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { toDataURL } from 'mm-ui';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'authImage'
})

export class AuthImagePipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    protected sanitizer: DomSanitizer
  ) { }

  async transform(src: string, type: string): Promise<any> {

    if (src && src.indexOf('/getmedia/view') >= 0) {
      const token = localStorage.getItem('accessToken');
      const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}`, 'Content-Disposition': 'attachment' });
      const imageBlob = await firstValueFrom(this.http.get(src, { headers, responseType: 'blob' }))

      let a = toDataURL(imageBlob)



      switch (type) {

        case 'image': return a.then((data: any) => {
          // console.log({data:data.result});
          return this.sanitizer.bypassSecurityTrustUrl(data.result);
        });
        case 'background': return a.then((data: any) => {
          // console.log({data:data.result})
          return this.sanitizer.bypassSecurityTrustStyle(`url(${data.result})`);
        });

      }

    } else {

      switch (type) {
        case 'image':
          return src
        case 'background':
          return this.sanitizer.bypassSecurityTrustStyle(`url(${src})`);
      }
    }

  }

}
