import { Component, OnInit, OnChanges, Input } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Utils } from '../../utils/utils';
import { MediaService } from '../../components/media/media.service';

@Component({
  selector: 'app-bottom-overlap-banner',
  templateUrl: './bottom-overlap-banner.component.html',
  styleUrls: ['./bottom-overlap-banner.component.scss']
})
export class BottomOverlapBannerComponent implements OnInit{

  // This class is extended on the other banners, one change here affect the others.

  @Input('form') template: any;
  mobile: boolean = false;
  mdSrvc:string = '';
  iTk: any;

  constructor(
    public router: Router,
    public _mediaService: MediaService,
    public utils: Utils
  ) {

    if (window.screen.width < 768)
      this.mobile = true
   }

  ngOnInit() {
    this.onChanges();
    if(this.utils.hasStorage('mdService')){
      this.mdSrvc = this.utils.getStorage('mdService')
    }
    this._mediaService.getIamToken().subscribe(itk => this.iTk = itk);
  }

  val(val){
    if(val.banner && _.isArray(val.banner)){
      let banner = val.banner[0].data.result
      this.template.controls.banner.setValue(banner, {emitEvent: false})
    }
  }

  onChanges(): void {
    this.template.valueChanges
      .pipe(debounceTime(400))
      .subscribe(val => {
        this.val(val);
      });
  }
  gotoPage(type: string){
    let banner = this.template.value;
    if(banner.clickArea == 'FULL_IMAGE' && type == 'image') {
      this.navigate(banner);
    } else if(banner.clickArea == 'BUTTON' && type == 'button') {
      this.navigate(banner)
    }
  }

  navigate(banner: any){
    if(banner.targetingType == 'INTERNAL_LINK'){
      this.router.navigate([banner.targeting.split('?')[0]])
    }else {
      window.open(banner.targeting, '_blank')
    }
  }
}
