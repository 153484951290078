import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import {MatTreeModule} from '@angular/material/tree'


import { ContentMenuComponent } from "./content-menu.component";
import { RouterModule } from "@angular/router";
import { NgScrollbarModule } from "ngx-scrollbar";
import { PageActionsComponent } from './page-actions/page-actions.component';
import { OverlayModule } from "@angular/cdk/overlay";
import { ViewPageActionComponent } from './page-actions/view/view.component';
import { MMIconsModule, TooltipModule, FieldsetHarukiModule } from "mm-ui";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ResizableModule } from 'angular-resizable-element'
import { PageActionsDialogComponent } from "./page-actions-dialog/page-actions-dialog.component";
import { DefineListPageModule } from "../create-page/page-features/define-list-page/define-list-page.module";
import { CreatePageService } from "../create-page/create-page.service";

@NgModule({
    declarations: [
        ContentMenuComponent,
        PageActionsComponent,
        ViewPageActionComponent,
        PageActionsDialogComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatTreeModule,
        MatButtonModule,
        TooltipModule,
        MatTooltipModule,
        OverlayModule,
        MMIconsModule,
        FieldsetHarukiModule,
        RouterModule,
        NgScrollbarModule,
        TranslateModule,
        ResizableModule,
        MatDialogModule,
        DefineListPageModule
    ],
    exports: [
        ContentMenuComponent
    ],
    providers: [
        // OverlayContainer,
        CreatePageService
    ],
    bootstrap: [
        ContentMenuComponent
    ]
})

export class ContentMenuModule{}
