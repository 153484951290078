export enum EventType {
  GLOSSARY_UPDATED = 'GLOSSARY_UPDATED',
  LGPD_UPDATED = 'LGPD_UPDATED',
  NOTIFICATION = 'NOTIFICATION',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  SIDEBAR_MENU_UPDATED = 'SIDEBAR_MENU_UPDATED',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  FINISHED_PROCESS_REPORT = 'FINISHED_PROCESS_REPORT',
}

