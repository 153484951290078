import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SearchSuggestComponent } from './search-suggest.component';
import { SearchSuggestDirective } from './search-suggest.directive';
import { FieldsetHarukiModule } from '../fieldset-haruki/fieldset-haruki.module';
import { MMIconsModule } from "../mm-icons/mm-icons.module";

@NgModule({
  declarations: [ 
    SearchSuggestComponent,
    SearchSuggestDirective
   ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FieldsetHarukiModule,
    MMIconsModule
  ],
  providers: [],
  exports: [
    SearchSuggestComponent
  ]
})
export class SearchSuggestModule { }
