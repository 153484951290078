import { NgModule, ModuleWithProviders } from '@angular/core';
import { DynamicHTMLComponent } from './dynamic-html.component';
import { DynamicHTMLOptions, DynamicDirectOptions } from './options';
import { DynamicHTMLRenderer } from './renderer';
import { CustomAlertModule } from '../page/custom-alert/custom-alert.module';
import { MediaViewModule } from '../page/media-view/media-view.module';
import { MediaLinkModule } from '../../../directives/media-link/media-link.module';
import { MediaService } from '../../media/media.service';
import { HighlightModule } from '../highlight/highlight.module';



@NgModule({
  declarations: [DynamicHTMLComponent],
  exports: [DynamicHTMLComponent],
  imports: [CustomAlertModule, MediaViewModule, MediaLinkModule, HighlightModule],
  providers: [MediaService]
})
export class DynamicHTMLModule {
  static forRoot(options: DynamicHTMLOptions, directives: DynamicDirectOptions): ModuleWithProviders<DynamicHTMLModule> {
    return {
      ngModule: DynamicHTMLModule,
      providers: [
        DynamicHTMLRenderer,
        { provide: DynamicHTMLOptions, useValue: options },
        { provide: DynamicDirectOptions, useValue: directives },
      ],
    };
  }
}
