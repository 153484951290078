import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardStepsComponent } from './wizard-steps.component';
import { MMIconsModule } from "../mm-icons/mm-icons.module";


@NgModule({
  declarations: [
    WizardStepsComponent
  ],
  imports: [
    CommonModule,
    MMIconsModule
  ],
  providers: [],
  exports: [
    WizardStepsComponent
  ]
})
export class WizardStepsModule { }
