import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash'
import { Router } from '@angular/router';
import { BottomOverlapBannerComponent } from '../bottom-overlap-banner/bottom-overlap-banner.component';
import { Utils } from '../../utils/utils';
import { MediaService } from '../../components/media/media.service';

@Component({
  selector: 'app-bottom-text-banner',
  templateUrl: './bottom-text-banner.component.html',
  styleUrls: ['./bottom-text-banner.component.scss']
})
export class BottomTextBannerComponent extends BottomOverlapBannerComponent {

  constructor(
    public router: Router,
    public utils: Utils,
    public _mediaService: MediaService
    ) {
    super(router, _mediaService, utils);
  }

}
