<div class="mm-ngx-pagination">
    <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
        <a
            [ngClass]="['btn', 'btn-outline-lines', (p.pages.length > 6 && isMobile) ? 'format-for-mobile': '']"
            *ngIf="!p.isFirstPage()"
            (click)="p.previous()"
        >
            Anterior
        </a>
    </div>
    <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
        <a
            [ngClass]="['btn', 'btn-outline-lines', (p.pages.length > 6 && isMobile) ? 'format-for-mobile': '']"
            (click)="p.setCurrent(page.value)"
            *ngIf="p.getCurrent() !== page.value"
        >
            <span>{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value">
            <span [ngClass]="['btn', 'btn-outline-lines', (p.pages.length > 6 && isMobile) ? 'format-for-mobile': '']">
                {{ page.label }}
            </span>
        </div>
    </div>

    <div class="pagination-next" [class.disabled]="p.isLastPage()">
        <a
            [ngClass]="['btn', 'btn-outline-lines', (p.pages.length > 6 && isMobile) ? 'format-for-mobile': '']"
            *ngIf="!p.isLastPage()"
            (click)="p.next()"
        >
            Próximo
        </a>
    </div>
</div>
