import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefineListPageComponent } from './define-list-page.component';
import { PageSearchComponent } from './page-search/page-search.component';
import { PageListComponent } from './page-list/page-list.component';
import { PagePickerComponent } from './page-picker/page-picker.component';
import { MMIconsModule, TooltipModule, AlertModule, SafePipeModule, SortPipeModule, FieldsetHarukiModule } from 'mm-ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    DefineListPageComponent,
    PageSearchComponent,
    PageListComponent,
    PagePickerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatTreeModule,
    MatExpansionModule,
    MatButtonModule,
    MMIconsModule,
    TooltipModule,
    AlertModule,
    MatTooltipModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    FieldsetHarukiModule,
    DragDropModule,

  ],
  exports: [
    DefineListPageComponent,
    PageSearchComponent,
    PageListComponent,
    PagePickerComponent
  ]
})
export class DefineListPageModule { }
