import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { PlatformConfigService } from '../../platform-config/platform-config.service';
import { ViewService } from '../view.service';

@Component({
  selector: 'mm-lgpd-dialog',
  templateUrl: './lgpd-dialog.component.html',
  styleUrls: ['./lgpd-dialog.component.scss']
})
export class LgpdDialogComponent implements OnInit {

  accept = new UntypedFormControl(null);
  term: any;
  blockCopy: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LgpdDialogComponent>,
    private _viewService: ViewService
  ) { }

  ngOnInit(): void {
    this.term = this.data.term;
    this._viewService.getBlockCopyOnView().subscribe({
      next: resp => this.blockCopy = resp.blockCopy,
      error: err => console.log({blockCopyError: err})
    });
  }

  close(){
    this.dialogRef.close(this.accept.value)
  }
}
