import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaLinkDirective } from './media-link.directive';
import { MediaService } from '../../components/media/media.service';



@NgModule({
  declarations: [
    MediaLinkDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MediaLinkDirective
  ],
  providers: [
    MediaService
  ]
})
export class MediaLinkModule { }
