import { NotifyService } from "../services/notify.service";
import { ModuleWithProviders, NgModule } from "@angular/core";

@NgModule({})
export class NotifyServiceModule{
    static forRoot(): ModuleWithProviders<NotifyServiceModule>{
        return {
            ngModule: NotifyServiceModule,
            providers: [NotifyService]
        }
    }
}