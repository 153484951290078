'use strict';

import { FieldsetHarukiSelectOption } from "mm-ui";

export const show_menu: boolean = true;
export const showContentMenu: boolean = false;

export const icons = [
  ['fa-solid', 'fa-address-book'],
  ['fa-regular', 'fa-address-book'],
  ['fa-solid', 'fa-address-card'],
  ['fa-regular', 'fa-address-card'],
  ['fa-solid', 'fa-ambulance'],
  ['fa-solid', 'fa-anchor'],
  ['fa-brands', 'fa-android'],
  ['fa-brands', 'fa-apple'],
  ['fa-solid', 'fa-archive'],
  ['fa-solid', 'fa-area-chart'],
  ['fa-solid', 'fa-asterisk'],
  ['fa-solid', 'fa-automobile'],
  ['fa-solid', 'fa-balance-scale'],
  ['fa-solid', 'fa-ban'],
  ['fa-solid', 'fa-bar-chart'],
  ['fa-solid', 'fa-barcode'],
  ['fa-solid', 'fa-battery-full'],
  ['fa-solid', 'fa-bed'],
  ['fa-solid', 'fa-bell'],
  ['fa-regular', 'fa-bell'],
  ['fa-solid', 'fa-bicycle'],
  ['fa-solid', 'fa-binoculars'],
  ['fa-solid', 'fa-birthday-cake'],
  ['fa-solid', 'fa-bolt'],
  ['fa-solid', 'fa-bomb'],
  ['fa-solid', 'fa-book'],
  ['fa-solid', 'fa-bookmark'],
  ['fa-regular', 'fa-bookmark'],
  ['fa-solid', 'fa-briefcase'],
  ['fa-solid', 'fa-bug'],
  ['fa-solid', 'fa-building'],
  ['fa-regular', 'fa-building'],
  ['fa-solid', 'fa-bullhorn'],
  ['fa-solid', 'fa-bus'],
  ['fa-solid', 'fa-cab'],
  ['fa-solid', 'fa-calculator'],
  ['fa-solid', 'fa-calendar'],
  ['fa-regular', 'fa-calendar-check'],
  ['fa-solid', 'fa-camera'],
  ['fa-solid', 'fa-camera-retro'],
  ['fa-solid', 'fa-car'],
  ['fa-solid', 'fa-certificate'],
  ['fa-solid', 'fa-chain'],
  ['fa-solid', 'fa-child'],
  ['fa-regular', 'fa-clock'],
  ['fa-solid', 'fa-cloud'],
  ['fa-solid', 'fa-coffee'],
  ['fa-solid', 'fa-cog'],
  ['fa-solid', 'fa-comment'],
  ['fa-solid', 'fa-comments'],
  ['fa-solid', 'fa-compass'],
  ['fa-solid', 'fa-credit-card'],
  ['fa-solid', 'fa-credit-card-alt'],
  ['fa-solid', 'fa-cube'],
  ['fa-solid', 'fa-cutlery'],
  ['fa-solid', 'fa-dashboard'],
  ['fa-solid', 'fa-database'],
  ['fa-solid', 'fa-desktop'],
  ['fa-solid', 'fa-diamond'],
  ['fa-solid', 'fa-dollar'],
  ['fa-brands', 'fa-dropbox'],
  ['fa-solid', 'fa-envelope'],
  ['fa-regular', 'fa-envelope'],
  ['fa-solid', 'fa-euro'],
  ['fa-solid', 'fa-exclamation'],
  ['fa-solid', 'fa-exclamation-circle'],
  ['fa-solid', 'fa-exclamation-triangle'],
  ['fa-solid', 'fa-eye'],
  ['fa-solid', 'fa-feed'],
  ['fa-solid', 'fa-female'],
  ['fa-solid', 'fa-fighter-jet'],
  ['fa-solid', 'fa-file'],
  ['fa-solid', 'fa-film'],
  ['fa-solid', 'fa-flag'],
  ['fa-regular', 'fa-flag'],
  ['fa-solid', 'fa-flask'],
  ['fa-regular', 'fa-floppy-disk'],
  ['fa-solid', 'fa-folder'],
  ['fa-regular', 'fa-folder'],
  ['fa-brands', 'fa-fort-awesome'],
  ['fa-solid', 'fa-gamepad'],
  ['fa-solid', 'fa-gift'],
  ['fa-solid', 'fa-globe'],
  ['fa-solid', 'fa-graduation-cap'],
  ['fa-brands', 'fa-grav'],
  ['fa-solid', 'fa-people-group'],
  ['fa-regular', 'fa-hand-peace'],
  ['fa-regular', 'fa-hand-pointer'],
  ['fa-regular', 'fa-handshake'],
  ['fa-regular', 'fa-heart'],
  ['fa-regular', 'fa-heart'],
  ['fa-solid', 'fa-home'],
  ['fa-solid', 'fa-headphones'],
  ['fa-solid', 'fa-hourglass'],
  ['fa-solid', 'fa-id-badge'],
  ['fa-solid', 'fa-image'],
  ['fa-solid', 'fa-inbox'],
  ['fa-solid', 'fa-institution'],
  ['fa-solid', 'fa-key'],
  ['fa-regular', 'fa-keyboard'],
  ['fa-solid', 'fa-laptop'],
  ['fa-solid', 'fa-leaf'],
  ['fa-solid', 'fa-life-ring'],
  ['fa-regular', 'fa-lightbulb'],
  ['fa-solid', 'fa-line-chart'],
  ['fa-solid', 'fa-list-ul'],
  ['fa-solid', 'fa-location-arrow'],
  ['fa-solid', 'fa-lock'],
  ['fa-solid', 'fa-male'],
  ['fa-solid', 'fa-map'],
  ['fa-solid', 'fa-map-marker'],
  ['fa-regular', 'fa-map'],
  ['fa-solid', 'fa-map-signs'],
  ['fa-solid', 'fa-medkit'],
  ['fa-solid', 'fa-microphone'],
  ['fa-solid', 'fa-mobile'],
  ['fa-solid', 'fa-money-bill'],
  ['fa-solid', 'fa-motorcycle'],
  ['fa-solid', 'fa-mouse-pointer'],
  ['fa-solid', 'fa-music'],
  ['fa-regular', 'fa-newspaper'],
  ['fa-solid', 'fa-paint-brush'],
  ['fa-solid', 'fa-paper-plane'],
  ['fa-solid', 'fa-paperclip'],
  ['fa-solid', 'fa-plane'],
  ['fa-solid', 'fa-power-off'],
  ['fa-solid', 'fa-print'],
  ['fa-solid', 'fa-puzzle-piece'],
  ['fa-solid', 'fa-question'],
  ['fa-solid', 'fa-question-circle'],
  ['fa-regular', 'fa-question-circle'],
  ['fa-solid', 'fa-quote-left'],
  ['fa-solid', 'fa-recycle'],
  ['fa-solid', 'fa-refresh'],
  ['fa-solid', 'fa-reorder'],
  ['fa-solid', 'fa-shopping-cart'],
  ['fa-solid', 'fa-sitemap'],
  ['fa-solid', 'fa-sliders'],
  ['fa-regular', 'fa-smile'],
  ['fa-solid', 'fa-space-shuttle'],
  ['fa-solid', 'fa-star'],
  ['fa-regular', 'fa-star'],
  ['fa-solid', 'fa-sticky-note'],
  ['fa-regular', 'fa-sticky-note'],
  ['fa-solid', 'fa-suitcase'],
  ['fa-solid', 'fa-tag'],
  ['fa-solid', 'fa-thumb-tack'],
  ['fa-solid', 'fa-trophy'],
  ['fa-solid', 'fa-unlock-alt'],
  ['fa-solid', 'fa-user'],
  ['fa-regular', 'fa-user'],
  ['fa-solid', 'fa-wifi'],
  ['fa-solid', 'fa-wrench'],
  ['icon', 'icon-busca'],
  ['icon', 'icon-calendario'],
  ['icon', 'icon-configuracoes'],
  ['icon', 'icon-editar'],
  ['icon', 'icon-fechar'],
  ['icon', 'icon-filtro'],
  ['icon', 'icon-fixos'],
  ['icon', 'icon-home'],
  ['icon', 'icon-info'],
  ['icon', 'icon-manual'],
  ['icon', 'icon-nao-tarifavel'],
  ['icon', 'icon-notificacoes'],
  ['icon', 'icon-sair'],
  ['icon', 'icon-sensivel'],
  ['icon', 'icon-seta-1'],
  ['icon', 'icon-seta-2'],
  ['icon', 'icon-seta-3'],
  ['icon', 'icon-toolbar-apagar'],
  ['icon', 'icon-toolbar-config'],
  ['icon', 'icon-toolbar-editar'],
  ['icon', 'icon-toolbar-historico'],
  ['icon', 'icon-tooltip'],
  ['icon', 'icon-usuario-add'],
  ['icon', 'icon-usuario-deletar'],
  ['icon', 'icon-usuario-perfil'],
  ['icon', 'icon-visualizacao'],
  ['icon', 'icon-widget-tempo-de-casa-do-dia'],
  ['icon', 'icon-wizard-1-b'],
  ['icon', 'icon-wizard-1-c'],
  ['icon', 'icon-wizard-1-d'],
  ['icon', 'icon-wizard-1-e'],
  ['icon', 'icon-wizard-2-a'],
  ['icon', 'icon-wizard-2-b'],
  ['icon', 'icon-wizard-2-c'],
  ['icon', 'icon-wizard-2-d'],
  ['icon', 'icon-grupos-comunicacao'],
  ['icon', 'icon-grupos-dados'],
  ['icon', 'icon-grupos-fluxo'],
  ['icon', 'icon-grupos-plataforma'],
  ['icon', 'icon-grupos-plus'],
  ['icon', 'icon-grupos-publicacao'],
  ['icon', 'icon-grupos-usuarios'],
  ['icon', 'icon-grupos-usuarios-plus'],
  ['icon', 'icon-check'],
  ['icon', 'icon-exclamacao'],
  ['icon', 'icon-tooltip-2'],
  ['icon', 'icon-oops'],
  ['icon', 'icon-wizard-3-a'],
  ['icon', 'icon-wizard-3-b'],
  ['icon', 'icon-wizard-3-c'],
  ['icon', 'icon-wizard-3-d'],
  ['icon', 'icon-wizard-4-a'],
  ['icon', 'icon-wizard-4-b'],
  ['icon', 'icon-wizard-4-c'],
  ['icon', 'icon-wizard-4-d'],
  ['icon', 'icon-wizard-4-e'],
  ['icon', 'icon-wizard-4-f'],
  ['icon', 'icon-add'],
  ['icon', 'icon-excluir'],
  ['icon', 'icon-add-arquivo'],
  ['icon', 'icon-alerta'],
  ['icon', 'icon-busca-usuario'],
  ['icon', 'icon-buscar-arquivo'],
  ['icon', 'icon-download'],
  ['icon', 'icon-editor-de-imagem-1'],
  ['icon', 'icon-editor-de-imagem-2'],
  ['icon', 'icon-editor-de-imagem-3'],
  ['icon', 'icon-editor-de-imagem-4'],
  ['icon', 'icon-editor-de-imagem-5'],
  ['icon', 'icon-editor-de-imagem-6'],
  ['icon', 'icon-editor-de-imagem-7'],
  ['icon', 'icon-expandir'],
  ['icon', 'icon-feedback'],
  ['icon', 'icon-gestao-de-midias-1'],
  ['icon', 'icon-gestao-de-midias-2'],
  ['icon', 'icon-gestao-de-midias-3'],
  ['icon', 'icon-gestao-de-midias-4'],
  ['icon', 'icon-interacoes-1'],
  ['icon', 'icon-interacoes-2'],
  ['icon', 'icon-interacoes-3'],
  ['icon', 'icon-interacoes-4'],
  ['icon', 'icon-interacoes-5'],
  ['icon', 'icon-interacoes-6'],
  ['icon', 'icon-menu-direito-1'],
  ['icon', 'icon-menu-direito-2'],
  ['icon', 'icon-menu-direito-3'],
  ['icon', 'icon-menu-direito-4'],
  ['icon', 'icon-salvar'],
  ['icon', 'icon-wizar-5-1'],
  ['icon', 'icon-wizar-5-2'],
  ['icon', 'icon-homepages'],
  ['icon', 'icon-wizard-criar-template'],
  ['icon', 'icon-x'],
  ['icon', 'icon-comparacao-mover'],
  ['icon', 'icon-drop-perfil-1'],
  ['icon', 'icon-drop-perfil-2'],
  ['icon', 'icon-drop-perfil-3'],
  ['icon', 'icon-drop-perfil-4'],
  ['icon', 'icon-drop-perfil-5'],
  ['icon', 'icon-menu-direito-5'],
  ['icon', 'icon-menu-direito-6'],
  ['icon', 'icon-menu-direito-7'],
  ['icon', 'icon-menu-direito-8'],
  ['icon', 'icon-minimizar'],
  ['icon', 'icon-mover-horizontal'],
  ['icon', 'icon-mover-vertical'],
  ['icon', 'icon-zoom-in-foto'],
  ['icon', 'icon-zoom-out-foto'],
  ['icon', 'icon-widget-aniversariantes'],
  ['icon', 'icon-widget-calendario-aniversariantes'],
  ['icon', 'icon-widget-calendario-tempo-de-casa'],
  ['icon', 'icon-widget-destaques'],
  ['icon', 'icon-widget-parabenizar'],
  ['icon', 'icon-widget-tempo-de-casa'],
  ['icon', 'icon-wizard-1-a'],
  ['icon', 'icon-widget-ultimas-atualizacoes'],
  ['icon', 'icon-wizard-5-escolha-template'],
  ['icon', 'icon-wizard-5-finalize-sua-pagina'],
  ['icon', 'icon-bloco-de-notas'],
  ['icon', 'icon-favorito-link-dinamico'],
  ['icon', 'icon-favoritos'],
  ['icon', 'icon-link-dinamico'],
  ['icon', 'icon-gestao-de-conteudos-2'],
  ['icon', 'icon-gestao-de-conteudos-3'],
  ['icon', 'icon-gestao-de-conteudos-4'],
  ['icon', 'icon-gestao-de-conteudos-5'],
  ['icon', 'icon-mensagem-de-aniversario'],
  ['icon', 'icon-banner-anterior'],
  ['icon', 'icon-banner-proximo'],
  ['icon', 'icon-busca-de-usuarios'],
  ['icon', 'icon-busca-em-area'],
  ['icon', 'icon-contratacoes'],
  ['icon', 'icon-desligamentos'],
  ['icon', 'icon-lida'],
  ['icon', 'icon-listagem-de-paginas'],
  ['icon', 'icon-maximizar'],
  ['icon', 'icon-movimentacoes-recentes'],
  ['icon', 'icon-mudancas-de-cargo'],
  ['icon', 'icon-nao-lida'],
  ['icon', 'icon-ultimas-paginas-navegadas'],
  ['icon', 'icon-wizard-notificacoes-2'],
  ['icon', 'icon-wizard-notificacoes-3'],
  ['icon', 'icon-wizard-notificacoes-4'],
  ['icon', 'icon-by-mmcafe'],
  ['icon', 'icon-logo-mm-red'],
  ['icon', 'icon-logo-mmcafe'],
  ['icon', 'icon-andamento'],
  ['icon', 'icon-ck-editor-abas'],
  ['icon', 'icon-ck-editor-collapse'],
  ['icon', 'icon-ck-editor-html'],
  ['icon', 'icon-mover-collapse'],
  ['icon', 'icon-musica'],
  ['icon', 'icon-video'],
  ['icon', 'icon-html-galeria'],
  ['icon', 'icon-tipo-galeria'],
  ['icon', 'icon-tipo-mural'],
  ['icon', 'icon-tipo-album'],
  ['icon', 'icon-tipo-mosaico'],
  ['icon', 'icon-check-box'],
  ['icon', 'icon-seta-4'],
  ['icon', 'icon-enviar'],
  ['icon', 'icon-filtro-ativo'],
  ['icon', 'icon-rating-sem-clique-1'],
  ['icon', 'icon-rating-sem-clique-2'],
  ['icon', 'icon-rating-sem-clique-3'],
  ['icon', 'icon-rating-sem-clique-4'],
  ['icon', 'icon-rating-sem-clique-5'],
  ['icon', 'icon-rating-com-clique-fundo'],
  ['icon', 'icon-rating-com-clique-1'],
  ['icon', 'icon-rating-com-clique-2'],
  ['icon', 'icon-rating-com-clique-3'],
  ['icon', 'icon-rating-com-clique-4'],
  ['icon', 'icon-rating-com-clique-5'],
  ['icon', 'icon-sobe'],
  ['icon', 'icon-desce'],
  ['icon', 'icon-calendario-novo'],
  ['icon', 'icon-mapa-do-site'],
  ['icon', 'icon-tags'],
  ['icon', 'icon-seta-5'],
  ['icon', 'icon-seta-6'],
  ['icon', 'icon-nota-quiz'],
  ['icon', 'icon-callcenter'],
  ['icon', 'icon-wizard-gami-1'],
  ['icon', 'icon-wizard-gami-2'],
  ['icon', 'icon-wizard-gami-3'],
  ['icon', 'icon-reserva-1'],
  ['icon', 'icon-reserva-2'],
  ['icon', 'icon-reserva-3'],
  ['icon', 'icon-reserva-4'],
  ['icon', 'icon-by-mmcafe-v2'],
  ['icon', 'icon-seta-7'],
  ['icon', 'icon-seta-8'],
  ['icon', 'icon-chat-anexo'],
  ['icon', 'icon-chat-emoji'],
  ['icon', 'icon-chat-enviar'],
  ['icon', 'icon-chat-logo'],
  ['icon', 'icon-chat-nova-conversa'],
  ['icon', 'icon-mais-v2'],
  ['icon', 'icon-alertas-custmizados'],
  ['icon', 'icon-midias'],
  ['icon', 'icon-encaminhar']
]

export const menu_items = [

  {
    icon: 'menu-direito-4',
    title: 'Homepages e páginas',
    data: 'cms/content',
    contentMenu: true,
    requireFunction: true,
    roles: ['PS', 'CI']
  },
  {
    icon: 'menu-direito-5',
    title: 'Mídias',
    data: '/media',
    roles: ['PS', 'CI'],
    url: '/media'
  },
  {
    icon: 'menu-direito-6',
    title: 'Templates',
    data: '/templates',
    url: '/templates',
    roles: ['CI']
  },
  {
    icon: 'grupos-fluxo',
    title: 'Fluxo de Trabalho',
    data: '/workflow',
    url: '/workflow',
    roles: ['FT']
  },
  {
    icon: 'grupos-plus',
    title: 'Módulos',
    data: '/modules',
    url: '/modules',
    roles: ['CI']
  },
  {
    icon: 'calendario-novo',
    title: 'Calendários',
    data: '/calendar',
    url: '/calendar',
    roles: ['CI']
  },
  {
    icon: 'menu-direito-2',
    title: 'Usuários',
    data: '/users',
    url: '/users/content',
    roles: ['UP_PLUS', 'UP'],
  },
  {
    icon: 'interacoes-4',
    title: 'Perfis de Acesso',
    data: '/accessprofiles',
    url: '/accessprofiles',
    roles: ['UP_PLUS', 'UP']
  },
  {
    icon: 'menu-direito-3',
    title: 'Configurações da Plataforma',
    data: '/platform',
    url: '/platform',
    roles: ['PC']
  },
  // {
  //   icon: 'menu-direito-7',
  //   title: 'Gestão de Features',
  //   data: 'featuresmanagement',
  //   roles: ['CI']
  // },
  {
    icon: 'menu-direito-8',
    title: 'Dashboard',
    data: '/dashboard',
    url: '/dashboard',
    roles: ['DR']
  },
]

export const navbar_items = [

  {
    logo_url: '/users/list',
    settings_url: '/users/settings',
    view_user_url: '/users/management/view/'
  }

]

export const accessProfilesCodes: Array<string> = [
  'UP',
  'UP_PLUS',
  'PC',
  'DR',
  'CI',
  'PS',
  'FT',
  'AI_REQUEST',
  'AI_REPORT'
]

export const excelFilesMIMEtypes = [
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
]

export const acceptedExtensions: Array<FieldsetHarukiSelectOption> = [
  {
    label: "JPG e JPEG",
    value: 'jpg'
  },
  {
    label: "TIF e TIFF",
    value: 'tif'
  },
  {
    label: "PNG",
    value: 'png'
  },
  {
    label: "GIF",
    value: 'gif'
  },
  {
    label: "BMP",
    value: 'bmp'
  },
  {
    label: "DOC",
    value: 'doc'
  },
  {
    label: "DOCX",
    value: 'docx'
  },
  {
    label: "RTF",
    value: 'rtf'
  },
  {
    label: "TXT",
    value: 'txt'
  },
  {
    label: "CSV",
    value: 'csv'
  },
  {
    label: "XLS",
    value: 'xls'
  },
  {
    label: "XLSX",
    value: 'xlsx'
  },
  {
    label: "PPT",
    value: 'ppt'
  },
  {
    label: "PPTX",
    value: 'pptx'
  },
  {
    label: "PDF",
    value: 'pdf'
  },
  {
    label: "MP4",
    value: 'mp4'
  },
  {
    label: "MPEG",
    value: 'mpeg'
  },
  {
    label: "AVI",
    value: 'avi'
  },
  {
    label: "WMV",
    value: 'wmv'
  },
  {
    label: "HTM",
    value: 'htm'
  },
  {
    label: "HTML",
    value: 'html'
  },
]
