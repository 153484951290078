import * as ColorsActions from '../actions/colors.actions'
import { ColorsState } from '../stores/colors.state';

export function reducerColors (state: ColorsState, action: ColorsActions.Actions) {
    switch (action.type){
        case ColorsActions.SET_COLORS:
            return {
                ...state,
                colors: action.payload
            }
        case ColorsActions.RESET_COLORS:
            return {}
        default:
            return state;
    }
}