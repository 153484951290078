import { Action } from "@ngrx/store";
import { Page } from "../models/page";

export const SET_PAGE = '[PAGE] Set Page'
export const RESET_PAGE = '[PAGE] Reset Page'
export const LOAD_PAGE = '[PAGE] Load Page'
export const SAVE_PAGE = '[PAGE] Save Page'
export const SET_ERROR = '[PAGE] Set Error'

export class SetPage implements Action {
    readonly type = SET_PAGE

    constructor(public payload: Page){}
}

export class ResetPage implements Action {
    readonly type = RESET_PAGE

    constructor(){}
}

export class LoadPage implements Action {
    readonly type = LOAD_PAGE

    constructor(public payload: {id: string}){}
}

export class SavePage implements Action {
    readonly type = SAVE_PAGE

    constructor(public payload: Page){}
}

export class SetError implements Action {
    readonly type = SET_ERROR

    constructor(public payload: any){}
}

export type Actions = SetPage |
                      ResetPage |
                      LoadPage | 
                      SavePage | 
                      SetError