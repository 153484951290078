import { Type } from '@angular/core';

export interface ComponentWithSelector {
    selector: string;
    component: Type<any>;
}
export interface DirectiveWithSelector {
    selector: string;
    directive: Type<any>;
    element: string;
}
export class DynamicHTMLOptions {
    components: Array<ComponentWithSelector>;
}

export class DynamicDirectOptions {
    directives: Array<DirectiveWithSelector>;
}