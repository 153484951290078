import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as PendingActions from '../actions/pendentItems.actions'
import * as HomepageDefinitionActions from '../components/cms/actions/homepageDefinition.actions'
import * as UserCreateActions from '../components/users/actions/users.actions'
import { switchMap, map, mergeMap, tap } from "rxjs/operators";
import { PendentItem } from "../models/pendentItem";
import { Store } from "@ngrx/store";
import { Homepage } from "../models/homepage";
import { LoggedUser } from "../models/loggedUser.service";

@Injectable()
export class PendingItemEffects {
    constructor(
        private action$: Actions,
        private user: LoggedUser,
        private store: Store<PendentItem>){}

    
    homepageDefinitionSet$ = createEffect(() => this.action$
        .pipe(
            ofType(
                HomepageDefinitionActions.SET_HOMEPAGE_DATA,
                // HomepageDefinitionActions.SET_HOMEPAGE_BANNER,
                // HomepageDefinitionActions.SET_HOMEPAGE_COLORS,
                // HomepageDefinitionActions.SET_HOMEPAGE_LOGO,
                // HomepageDefinitionActions.SET_HOMEPAGE_WIDGET,
                HomepageDefinitionActions.SET_FORM
            ),
            tap((actions: HomepageDefinitionActions.SetForm) => this.definePendendHomepageItens(actions.payload))
        ), {dispatch: false});
    
    HomepageDefinitionReset$ = createEffect(() => this.action$
        .pipe(
            ofType(HomepageDefinitionActions.RESET_FORM),
            tap(() => this.store.dispatch(new PendingActions.ResetDefineHomepage()))
        ), {dispatch: false})

    
    userCreateSet$ = createEffect(() => this.action$
        .pipe(
            ofType(UserCreateActions.SET_USER),
            tap(() => this.store.dispatch(new PendingActions.SetCreateUser()))
        ), {dispatch: false})
    
    UserCreateReset$ = createEffect(() => this.action$
        .pipe(
            ofType(UserCreateActions.RESET_FORM),
            tap(() => this.store.dispatch(new PendingActions.ResetCreateUser()))
        ), {dispatch: false})

    
    definePendendHomepageItens({completedColors, completedData, completedSetUpBanner, completedSetUpLogo, completedWidgets, completedWidgetsConfig, id}: Homepage): void {
        let clientId = this.user.getClientId();
        if (completedColors && completedData && completedSetUpBanner && completedSetUpLogo && completedWidgets && completedWidgetsConfig) {
            return this.store.dispatch(new PendingActions.ResetDefineHomepage())
        } else {
            return this.store.dispatch(new PendingActions.SetDefineHomepage({id, clientId}))
        }
    }
}