import { Injectable } from '@angular/core';
import { GlossaryService } from '../../glossary/glossary.service';
import { BehaviorSubject } from 'rxjs';
import { AllTerms } from '../../../interfaces/glossary';
import { AppConfig } from '../../../app.config';
import { LoggedUser } from '../../../models/loggedUser.service';

@Injectable({ providedIn: 'root' })
export class GlossaryHighlightService {

  private allTerms$ = new BehaviorSubject<AllTerms>({})
  private endpointWebsocket = AppConfig.data['endpointNotifyWebsocket'];
  private ws: WebSocket

  constructor(
    private _glossaryService: GlossaryService,
    private user: LoggedUser
  ) { }

  getTermsFromBackend() {
    this._glossaryService.getAllTermsHighlight().subscribe(terms => {
      this.allTerms$.next(terms)
    });
  }

  disconnectWebsocket() {
    this.ws ? this.ws.close() : false;
  }

  getAllTerms() {
    if (this.user.validateModule('glossary')) {
      return this.allTerms$.asObservable();
    } else {
      return new BehaviorSubject<AllTerms>({}).asObservable();
    }
  }
}
