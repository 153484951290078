import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filterByAttr',
    pure: false
})
export class FilterByAttr implements PipeTransform {
    transform(items: any[], attr: string){

        if(!items || !attr) {
            return items;
        }

        return items.filter(item => item[attr])
    }
}
