import { ActivatedRouteSnapshot } from "@angular/router";
import { NotifyService } from "../services/notify.service";
import { Injectable } from "@angular/core";

@Injectable()
export class NotificationGuard {
    constructor (
        private _notifyService: NotifyService
    ) {}
    canActivate(route: ActivatedRouteSnapshot): boolean{        
        this._notifyService.openAllNotifications();
        return true
    } 
}