import { NgModule } from "@angular/core";
import { MmTableComponent } from "./mm-table.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FieldsetHarukiModule } from "../fieldset-haruki/fieldset-haruki.module";
import { MatMenuModule } from "@angular/material/menu";
import { NgScrollbarModule } from "ngx-scrollbar";
import { MMIconsModule } from "../mm-icons/mm-icons.module";
import { TooltipModule } from "../tooltip/tooltip.module";

@NgModule({
    declarations: [
        MmTableComponent
    ],
    exports: [
        MmTableComponent
    ],
    imports: [
        CommonModule,
        NgScrollbarModule,
        TranslateModule,
        FieldsetHarukiModule,
        MatMenuModule,
        MMIconsModule,
        TooltipModule
    ]
})

export class MmTableModule{

}
