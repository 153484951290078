import { Pageable } from "./pageable";
import { Page } from "../models/page";

export interface Notify {
    id: string
    name: string;
    title: string;
    message: string;
    ico: string;
    icoUrl?:string;
    content: string;
    schedule: boolean;
    notifyInteraction: NotifyInteraction;
    publishingDate: string;
    publishingHour?: string;
    creationDate?: string;
    viewed?: boolean;
    viewDate?: string;
    lastModifiedDate?: string;
    receiver?: string;
    createdById?: string;
    createdByIp?: string;
    groups?: any[];
    groupsInfo?: any[];
    users?: any[];
    usersInfo?: any[]
    activity?:boolean;
    sendDate?:any;
    publisherService?: any;
    homePage?: HomePage[];
    htmlPlus?: any[];

}

export interface PageableNotify extends Pageable {
    content: Notify[]
}

export interface NotifyInteraction {
    type: NotifyLinkType;
    requiredInteractionChk: boolean;
    requiredInteractionChkTxt: string;
    requiredInteractionBtnChkTxt: string;
    pageId: string;
    menuId?: string,
    requiredInteractionPageBtnTxt: string;
    urlTitle: string;
    url: string;
    page?: Page;
    domain?: string;
}

export enum NotifyLinkType {
    none = 'NONE',
    page = 'PAGE',
    link = 'LINK'
}

export enum NotifyRecipientsType {
    all = 'ALL',
    recipients = 'SELECTED'
}

export interface NotifyStats {
    notViewsPercentage:         number;
    notifyConfigId:             string;
    creationDate:               string;
    notViews:                   number;
    interactions:               number;
    total:                      number;
    viewsPercentage:            number;
    createdBy:                  CreatedBy;
    interactionsPercentage:     number;
    notifyConfigName:           string;
    notInteractionsPercentage:  number;
    notificationHasInteraction: boolean;
    createdById:                string;
    views:                      number;
    order:                      number;
    notInteractions:            number;
}
export interface CreatedBy {
    birthday:    boolean;
    notViewUser: boolean;
    clientId:    string;
    coverImage:  CoverImage;
    fullName:    string;
    photo:       Photo;
    id:          string;
    login:       string;
    status:      string;
}

export interface HomePage {
    id:          string;
    homePageId:  string;
    clientId:    string;
}

export interface CoverImage {
    mediaId: string;
}

export interface Photo {
    mediaUrl: string;
    mediaId:  string;
}

