import { trigger, transition, style, query, animateChild, group, animate, state, stagger } from "@angular/animations";

export const slideAnimation =
  trigger('routeAnimations', [
    transition('* <=> pagr', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%' }))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%' }))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* <=> Page', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '100%' }))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%' }))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ]);

export const inOutAnimation =  trigger(
  'inOutAnimation', 
  [
    transition(
      ':enter', 
      [
        style({ height: 0, opacity: 0 }),
        animate('1s ease-out', 
                style({ height: 300, opacity: 1 }))
      ]
    ),
    transition(
      ':leave', 
      [
        style({ height: 300, opacity: 1 }),
        animate('1s ease-in', 
                style({ height: 0, opacity: 0 }))
      ]
    )
  ]
)

export const fadeAnimation =
  trigger('fadeAnimation', [
    transition(':enter', [
      style({ transform: 'translateX(-50px)', opacity: 0 }),  // initial
      animate('0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({
          transform: 'none', opacity: 1,
        }))  // final
    ]),
    transition(':leave', [
      style({ transform: 'none', opacity: 1, height: '*' }),
      animate('0.2s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({
          opacity: 0, transform: 'translateX(50px)'
        }))
    ])
  ])

export const fadeOpacity =
  trigger('fadeOpacity', [
    state('void',
      style({
        opacity: 0,
      })
    ),
    state('*', style({
      opacity: 1
    })),
    transition('* <=> void', animate('0.2s ease-out'))
  ])

export const fadeRight =
  trigger('fadeRight', [
    state('void',
      style({
        opacity: 0,
        transform: 'translateX(-100px)'
      })
    ),
    state('*',
      style({
        opacity: 1,
        transform: 'none'
      })),
    transition('* <=> void', animate('0.2s ease-in'))
  ])

export const fadeTop =
  trigger('fadeTop', [
    state('void',
      style({
        opacity: 0,
        transform: 'translateY(-50px)'
      })
    ),
    state('*',
      style({
        opacity: 1,
        transform: 'none'
      })),
    transition('* <=> void', animate('0.1s ease-in'))
  ])

  export const fadeBottom =
  trigger('fadeBottom', [
    state('void',
      style({
        opacity: 0,
        transform: 'translateY(50px)'
      })
    ),
    state('*',
      style({
        opacity: 1,
        transform: 'none'
      })),
    transition('* <=> void', animate('0.1s ease-in'))
  ])

export const fadeLeft =
  trigger('fadeLeft', [
    transition(':enter', [
      style({ transform: 'translateX(100px)', opacity: 0 }),  // initial
      animate('0.5s 0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({
          transform: 'none', opacity: 1,
        }))  // final
    ]),
    transition(':leave', [
      style({ transform: 'none', opacity: 1, height: '*' }),
      animate('0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({
          opacity: 0, transform: 'translateX(50px)'
        }))
    ])
  ])

export const slideToTop = trigger('slideToTop', [
  state('maximize', style({
    width: '100%',
    opacity: 1,
    display: 'block'
  })),
  state('minimize', style({
    height: '0',
    opacity: 0,
    display: 'none'
  })),
  transition('maximize => minimize', [
    animate('0.3s ease-in-out')
  ]),
  transition('minimize => maximize', [
    animate('0.3s ease-in')
  ]),
])

export const slidefromRight = trigger('slidefromRight', [
  state('open', style({
    right: '0px',
    // opacity: 1,
    display: 'block'
  })),
  state('closed', style({
    // opacity: 0,
    right: '-1000px',
    display: 'none'
  })),
  transition('open => closed', [
    animate('0.3s ease-in-out')
  ]),
  transition('closed => open', [
    animate('0.3s ease-in')
  ]),
])

export const childAnimation = trigger('childAnimation', [
  state('open', style({
    width: '100%',
    opacity: 1,
    display: 'block'
  })),
  state('closed', style({
    display: 'none',
    opacity: 0,
    height:0
  })),
  transition('open => closed', [
    animate('0.3s ease-in-out')
  ]),
  transition('closed => open', [
    animate('0.3s ease-in')
  ])
])

export function scrollToTop() {
  (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8))
      }
  })();
}