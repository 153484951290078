import { Pipe, PipeTransform } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { toDataURL } from '../utils/traversing';
import { DomSanitizer,SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'authImage'
  })

export class AuthImagePipe implements PipeTransform {

  private httpClient: HttpClient;

  constructor(
      private http: HttpClient,
      private handler: HttpBackend,
      protected sanitizer: DomSanitizer
  ) {
    this.httpClient = new HttpClient(handler);
  }

  async transform(src: string, type: string, iam:string, url?:string,avOpts?:Array<any>): Promise<any> {

    if(src && url) {

          const headers = new HttpHeaders({'Authorization': `Bearer ${iam}`});
          const imageBlob = await firstValueFrom(this.httpClient.get(src, {headers, responseType: 'blob'}))

            let a =  toDataURL(imageBlob)

            switch(type){

              case 'image': return a.then((data:any) => {
                return this.sanitizer.bypassSecurityTrustUrl(data.result);
              });
              case 'background': return a.then((data:any) => {
                return this.sanitizer.bypassSecurityTrustStyle(`url(${data.result})`);
              });
              case 'stringOnly': return a.then((data:any) => {
                return data.result;
              });
              case 'av' : return a.then((data:any) => {

                if(avOpts[0].avType == 'audio'){

                  let audio = `
                            <audio controls style="margin-top: 50px; margin-bottom: 20px;">
                                <source src="${data.result}">
                                Seu navegador não suporta exibição de video.
                            </audio>
                          `
                  return this.sanitizer.bypassSecurityTrustHtml(audio);
                }

                if(avOpts[0].avType == 'video'){

                  let video = `
                            <video
                              preload="none"
                              controls
                              [muted]="${avOpts[0].noaudio}"
                              style="width: 100%"
                            >
                              <source src="${data.result}">
                              Seu navegador não suporta exibição de video.
                          </video>
                          `
                  return this.sanitizer.bypassSecurityTrustHtml(video);
                }
              });

            }

    }else {

      switch(type){

        case 'image':
          return src
        case 'background':
          return this.sanitizer.bypassSecurityTrustStyle(`url(${src})`);
      }
    }

  }

}
