import { Directive, HostListener, Output, EventEmitter } from "@angular/core";

@Directive({
    selector: '[search-suggest]',
})


export class SearchSuggestDirective {
    
    @Output() suggestionSelectOpt =  new EventEmitter();
    
    
    @HostListener('click', ['$event']) 
    select(event) {}
}