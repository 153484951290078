import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { NgScrollbarModule } from "ngx-scrollbar";

import { MMIconsModule, TooltipModule, LoadingSpinnerModule, SafePipeModule } from "mm-ui";

import { ChatTrayComponent } from "./chat-tray.component";
import { ChatUsersImagesComponent } from "./chat-users-images/chat-users-images.component";
import { MediaService } from "../../components/media/media.service";
import { ChatService } from "../../components/chat/chat.service";
import { ChatTrayDataComponent } from "./chat-tray-data/chat-tray-data.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

@NgModule({
    declarations: [
      ChatTrayComponent,
      ChatUsersImagesComponent,
      ChatTrayDataComponent
    ],
    imports: [
      CommonModule,
      TranslateModule,
      RouterModule,
      MMIconsModule,
      TooltipModule,
      NgScrollbarModule,
      LoadingSpinnerModule,
      InfiniteScrollModule,
      SafePipeModule
    ],
    exports: [
      ChatTrayComponent,
      ChatUsersImagesComponent
    ],
    providers: [
      MediaService,
      ChatService
    ]
  })
  export class ChatTrayModule { }
