import { NgModule } from "@angular/core";
import { ArraySortPipe } from "./sortPipe.pipe";

@NgModule({
    declarations: [
        ArraySortPipe
    ], exports: [
        ArraySortPipe
    ]
})

export class SortPipeModule{}