import { Component, OnInit, Input, Output, EventEmitter, ElementRef, HostListener, forwardRef } from '@angular/core';
import { Observable, fromEvent, of} from 'rxjs';

import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { filter, debounceTime, distinctUntilChanged, delay } from 'rxjs/operators';

@Component({
  selector: 'mmp5-search-suggest',
  templateUrl: './search-suggest.component.html',
  styleUrls: ['./search-suggest.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSuggestComponent),
      multi: true
    }
  ]
})
export class SearchSuggestComponent implements OnInit, ControlValueAccessor {

  @Input() cssclasses = '';
  @Input() placeholder = '';
  @Input() searchFn: Function;
  @Input() debounceTime: number = 0;
  @Input() formControl = new UntypedFormControl('')
  @Input() fieldset: Boolean;
  @Input() key: string;
  @Input() type:string;
  @Input() showButton: boolean = false;
  @Input() buttonClass: string;
  @Input() icon: Array<string> = ['icon icon-busca']
  @Input() addCustomSelection = false;
  @Input() addCustomSelectionText = null;
  @Input() hiddenSearchButton = false;

  @Output() suggestionSelect = new EventEmitter();
  @Output() keyupFieldset = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  @Output() buttonClick = new EventEmitter();

  removing: boolean = false;
  public inputValue: string;
  suggestions: any = [];

  selected = -1;

  propagateChange = (_: any) => {};

  constructor(private elementRef: ElementRef) {

  }

  ngOnInit() {
    this.icon =[
      'icon icon-busca',
      'icon icon-toolbar-apagar'
    ]

    // mock sugestions to test
    // this.suggestions = [
    //   {
    //     name: 'test'
    //   },
    //   {
    //     name: 'stest'
    //   }
    // ]

    this.formControl.valueChanges.subscribe(val => this.inputValue = val)

    const eventStream = fromEvent(this.elementRef.nativeElement, 'keyup').pipe(debounceTime(this.debounceTime), distinctUntilChanged());

    eventStream.subscribe( (event: KeyboardEvent) => {
      const input = this.inputValue;
      const key = event.key;
      if(this.formControl){
        this.formControl.setValue(input)
      }
      if (key === 'Enter') {
        if (this.selected >= 0 && this.suggestions[this.selected]) {
          this.suggestionSelect.emit(this.suggestions[this.selected]);
        }else {
          this.buttonClick.emit(input);
        }
        this.suggestions = null;
      } else if ( key === 'ArrowDown' && this.suggestions) {
        if (this.selected + 1 < this.suggestions.length) {
          this.selected++;
        }
      } else if (key === 'ArrowUp') {
        if (this.selected > 0) {
          this.selected--;
        } else {
          this.selected = -1;
        }
      } else {
        if (input === '') {
          // this.suggestions = null;
        } else {
          this.selected = -1;
          this.searchFn(input).subscribe( data => {
            this.suggestions = data;
          }, err => {
            console.error(err);
          });
        }
      }
    });

    fromEvent(this.elementRef.nativeElement, 'keyup')
    .subscribe( () => {
      this.propagateChange(this.inputValue);
    });

  }

  addCustom(){
    this.select(null, {name: this.formControl.value, value: this.formControl.value})
  }

  writeValue(value: any) {
    this.inputValue = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  validateKeyUp(event){
    if(this.selected == -1) {
      this.keyupFieldset.emit(event)
    }
  }

  registerOnTouched() {}

  select(event, item) {
    this.suggestionSelect.emit(item);
    of(true).pipe(delay(100)).subscribe( () => {
      this.suggestions = null;
    });
  }

  blur(event) {
    of(true).pipe(delay(1000)).subscribe( () => {
      this.suggestions = null;
    });

  }

  changed(event, item:{}) {

    this.removing = !this.removing;
    item["removing"] = this.removing;
    this.valueChange.emit(item)
  }

  onClick(event) {
    this.buttonClick.emit(this.inputValue);
  }

}
