import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject, merge, fromEvent } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { MessageService } from "primeng/api";

import { LoginService } from "../components/login/login.service";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private user$: BehaviorSubject<any> = new BehaviorSubject({})

  constructor(
    loginService: LoginService,
    private messageService: MessageService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {


    const events$ = merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'keyup'),
      fromEvent(document, 'mousemove')
    )

    events$.subscribe(resp => {
      loginService.userHasInterected()
    })
  }

  setUser(user: any) {
    this.user$.next(user);
  }

  getUser() {
    return this.user$.asObservable()
  }

  changeFavicon(faviconUrl) {
    this._document.getElementById('appFavicon').setAttribute('href', faviconUrl);
  }

  setMessage(arg0: string, arg1: string, detail: string): void {

    this.messageService.add({ severity: arg0, summary: arg1, detail });
  }
}
