import { Component, OnInit, Inject, Optional, SimpleChanges } from '@angular/core';
import { Notify, NotifyLinkType } from '../../../interfaces/Notify';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyService } from '../../../services/notify.service';
import { take, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { whichProtocol } from "../../../utils/utils";
import { PlatformConfigService } from '../../../components/platform-config/platform-config.service';
import { CustomAlertService } from '../../../components/view/page/custom-alert/custom-alert.service';

@Component({
  selector: 'mm-notify-dialog',
  templateUrl: './notify-dialog.component.html',
  styleUrls: ['./notify-dialog.component.scss']
})
export class NotifyDialogComponent implements OnInit {
  notifications: Notify[];
  actual: Notify;
  index: number = 0;
  textButton: string;
  socketNotifications$: Subscription;
  type: string

  check = new UntypedFormControl();
  checked: boolean;
  blockCopy: any;
  customAlerts = []

  constructor(
    public dialogRef: MatDialogRef<NotifyDialogComponent>,
    private _notifyService: NotifyService,
    private router: Router,
    private _platformConfig: PlatformConfigService,
    private _customAlertService: CustomAlertService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { notifications: Notify[] }
  ) { }

  ngOnInit() {

    this._platformConfig.getBlockCopy().subscribe({
      next: resp => this.blockCopy = resp.blockCopy,
      error: err => console.log({ blockCopyError: err })
    });

    this.notifications = this.data.notifications;
    this.socketNotifications$ = this._notifyService.getSocketNotifys()
      .subscribe(notify => this.notifications.push(notify));

    if (this.notifications.length) {
      this.actual = this.notifications[0];
      if (this.actual) {
        this.actualHtmplus(this.actual);
        this.actualText(this.actual)
      }
      if (this.actual && this.actual.notifyInteraction) {
        this.isRequired(this.actual.notifyInteraction.requiredInteractionChk)
      }
      this.index = 0;
    }

    this.changeValue()

  }

  changeValue() {
    this.check.valueChanges.subscribe(e => this.checked = e)
  }

  buttonEvent(actual) {

    if (actual.notifyInteraction.type == 'PAGE') {
      this._notifyService.registerInteraction(actual.id).pipe(take(1)).subscribe(() => { })
      window.open(`/hp/page/${actual.notifyInteraction.pageId}`)
    } else if (actual.notifyInteraction.type == 'LINK') {
      this._notifyService.registerInteraction(actual.id).pipe(take(1)).subscribe(() => { })
      let domain = '';
      if (actual.notifyInteraction.url.indexOf('http://') <= -1 && actual.notifyInteraction.url.indexOf('https://') <= -1) {
        domain = whichProtocol();
        window.open(`${domain}${actual.notifyInteraction.url}`);
      } else {
        window.open(`${actual.notifyInteraction.url}`);
      }

    }

    this.next();
  }

  next() {
    let type = this.actual.activity ? 'activities' : 'default'
    this._notifyService.viewNotify(this.actual.id, type).pipe(take(1)).subscribe(() => { });
    if (this.index < (this.notifications.length - 1)) {
      this.index++;
      this.actual = this.notifications[this.index];
      this.actualHtmplus(this.actual);
      this.isRequired(this.actual.notifyInteraction.requiredInteractionChk);
      this.actualText(this.actual);
    } else {
      this.dialogRef.close();
    }
  }

  actualText(actual: Notify) {
    if (actual.notifyInteraction.type == 'PAGE') {
      this.textButton = actual.notifyInteraction.requiredInteractionPageBtnTxt ? actual.notifyInteraction.requiredInteractionPageBtnTxt : 'Submeter';
    } else if (actual.notifyInteraction.type == 'LINK') {
      this.textButton = actual.notifyInteraction.urlTitle ? actual.notifyInteraction.urlTitle : 'Submeter';
    }
    if (actual.notifyInteraction.requiredInteractionChk) {
      this.textButton = actual.notifyInteraction.requiredInteractionBtnChkTxt ? actual.notifyInteraction.requiredInteractionBtnChkTxt : 'Submeter';
    }
  }

  isRequired(required) {

    if (!required) {
      this.checked = true;
    } else {
      this.checked = false;
    }
  }


  actualHtmplus(actual) {
    if (!actual.htmlPlus) return;
  }


  close() {
    let type = this.actual.activity ? 'activities' : 'default'
    this._notifyService.viewNotify(this.actual.id, type).pipe(take(1)).subscribe(() => { });
    this.dialogRef.close();
  }

  ngOnDestroy(): void {

    this.socketNotifications$ && this.socketNotifications$.unsubscribe();
  }
}
