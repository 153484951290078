<div class="template" [ngClass]="{'hand': template.value.clickArea == 'FULL_IMAGE' }">
  <!-- <div class="image-background" [ngStyle]="{'background-size':'cover', 'background-image': 'url(' + (template?.value?.banner || template.value?.bannerImage?.mediaUrl) + ')', 'background-repeat': 'no-repeat'}">
  </div> -->
  <img [src]="(template.value.banner || template.value?.bannerImage?.mediaUrl) | authImage:'image':iTk:mdSrvc | async" (click)="gotoPage('image')" alt="">
  <div class="template__action">
    <div class="action_content">
      <h5 *ngIf="template.value.displayTitle">{{template.value.title}}</h5>
      <p *ngIf="template.value.displayDescription">{{template.value.description}}</p>
      <button *ngIf="template.value.clickArea !== 'FULL_IMAGE' && template.value.clickArea !== 'NO_CLICK_AREA'" (click)="gotoPage('button')" class="btn btn-primary">{{template.value.labelButton}}</button>
    </div>
  </div>
</div>

