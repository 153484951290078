import { Component, ViewContainerRef, Input, OnInit, Inject, AfterContentChecked, AfterViewChecked, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, throttleTime, skip, take, map } from 'rxjs/operators';
import { NgProgress } from 'ngx-progressbar';
import { Observable, Subscription, fromEvent } from 'rxjs';

import { AlertController, LoadingSpinner } from 'mm-ui';

import { AppService } from './services/app.service';
import { ColorsState } from './stores/colors.state';
import * as myGlobals from './utils/globals';
import { LoggedUser } from './models/loggedUser.service';
import { MediaService } from './components/media/media.service';
import { HomepageColorsService } from './services/homepageColors.service';
import { ContentService } from './components/cms/content/content.service';
import { Homepage } from './models/homepage';
import { UserInterface } from './interfaces/userInterface';
import { PendentItemsState } from './stores/pendentItems.state';
import { ResetCreatePage } from './actions/pendentItems.actions';
import * as moment from 'moment';
import * as PendentActions from './actions/pendentItems.actions';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from './services/notify.service';
import { Notify } from './interfaces/Notify';
import { isOnLogin } from './utils/utils';
import { LoginService } from './components/login/login.service';
import { LanguageService } from './services/language.service';
import { FontFamilyService } from './services/fontFamily.service';
import { MessagingService } from './services/messaging.service';
import { EventsService } from './services/events.service';
import { ChatService } from './components/chat/chat.service';
import { PlatformConfigService } from './components/platform-config/platform-config.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'n-cms';
  progressColor = '#ff5e3a';

  show_menu = myGlobals.show_menu;
  menu_items = myGlobals.menu_items;
  navbar_items = myGlobals.navbar_items;
  showCM: boolean = myGlobals.showContentMenu;
  hide_breadcrumb: boolean = false;
  showAppBar: boolean = false;
  mediaUrl: string;
  userInfo: any;
  colors: any[] = [];
  colorSelected: string;
  selectedPageId: string;
  homePage$: Observable<Homepage>;
  logged;
  userHasConfigPermission: boolean;
  user: UserInterface;
  notifys: number;
  token: string;
  totalNotifications$: Subscription;
  readedBellNotifications$: Subscription;
  bellNotifications$: Subscription;
  notifyConnected: boolean;
  nots = {
    activities: 0,
    default: 0
  };
  chatNots = {
    activities: 0,
    default: 0
  }

  message: any;
  hasAccess: boolean;
  showHomepagesOnNavbar = false;

  // private listenerCopy: () => void;
  private listenerCopy = () => { }
  private listenerCtxMenu: () => void;
  private listenerPtrscr: () => void;


  constructor(
    public vcr: ViewContainerRef,
    private alertController: AlertController,
    private loadingSpinner: LoadingSpinner,
    private store: Store<ColorsState>,
    private _ColorService: HomepageColorsService,
    private _pendentStore: Store<PendentItemsState>,
    private appService: AppService,
    private mediaService: MediaService,
    private router: Router,
    private _contentService: ContentService,
    private notifyService: NotifyService,
    private _loginService: LoginService,
    private _user: LoggedUser,
    public ngProgress: NgProgress,
    private _language: LanguageService,
    private _fontService: FontFamilyService,
    private eventsService: EventsService,
    private chatSerivce: ChatService,
    private _platformConfig: PlatformConfigService,
    private messageService: MessageService,
    private translate: TranslateService,
    private _renderer2: Renderer2,
  ) {

    this.alertController.setViewContainerRef(vcr);
    this.loadingSpinner.setViewContainerRef(vcr);

    this.getColorChange();
    this.getLanguage();
    this.getFontFamily();
    this.setGoogleAnalyticsEvent();

    this.router
      .events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setNavbarHidden(event);
        this.subscribeToUserChange();
        this.getLanguage();
        this.getFontFamily();

        if (this.listenerCopy) {
          this.listenerCopy();
        }
        if (this.listenerCtxMenu) {
          this.listenerCtxMenu();
        }
        if (this.listenerPtrscr) {
          this.listenerPtrscr();
        }
      });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let cms = localStorage.getItem('cms');
        let parsed = JSON.parse(cms);
        if (parsed && parsed.homepageDefinition)
          gtag('config', parsed.homepageDefinition.googleMonitoringCode, { 'page_path': event.urlAfterRedirects });
      }
    });

  }

  ngOnInit() {
    this.getColorsFromRedux();
    this.getFontFamily();
    this.subcribeToUserProfileChange();
    // this.appService.changeFavicon('assets/favicon/32x32.png');
    this.homePage$ = this._contentService.getHomepageObj();
    this.logged = this._user.isAuthenticated();
    this.user = this._user.getUser() && this._user.getUser().user;
    this.token = localStorage.getItem('accessToken');
    this.configAuthTimeout();
    // this.subscribeToUserChange();
    this.validadeModulesEnabled(this.user);
    this.connectLoginWebsocket();

    if (this._user.isAuthenticated()) {
      this.eventsService.listenToEvents();
      this.mediaService.getIamToken().subscribe();
    }
    this.listenToNotifyEvents();
    this.listenToChatEvents();
    this.getReadedBellNotifications();
    this.getUnreadedBellNotifications();
    this.getReadedChatMessages();
  }

  blockFunctions() {

    this._platformConfig.getBlockCopy().subscribe({
      next: resp => {

        if (resp.blockCopy) {

          this.listenerCtxMenu = this._renderer2.listen('document', 'contextmenu', event => {
            event.stopPropagation();
            event.preventDefault();
            this.setMessage('error', this.translate.instant('errors.interactionBlocked'), '')
          })

          this.listenerCopy = this._renderer2.listen('document', 'keydown', event => {
            if ((event.ctrlKey || event.metaKey) && (event.key === 'c' || event.key === 'C')) {
              event.stopPropagation();
              event.preventDefault();
              this.setMessage('error', this.translate.instant('errors.interactionBlocked'), '')
            }
          })

          this.listenerPtrscr = this._renderer2.listen('window', 'keydown', event => {
            if (event.key === 'PrtScr' || (event.metaKey && event.shiftKey && (event.key === 's' || event.key === 'S'))) {
              event.stopPropagation();
              event.preventDefault();
              this.setMessage('error', this.translate.instant('errors.interactionBlocked'), '')
            }
          })
        }
      },
      error: err => {
        console.log({ blockCopyError: err })
      }
    });
  }

  getReadedChatMessages() {
    const hasModule = this._user.getModules().includes('chat');

    if (hasModule) {
      this.chatSerivce.getUnreadedMessages().subscribe(({ unreadedMessages }: any) => {
        if (unreadedMessages) {
          this.chatNots.default = unreadedMessages;
        }
      });
    }
  }

  getUnreadedBellNotifications() {
    this.totalNotifications$ = this.notifyService.getBellNotify().subscribe((total: any) => {
      if (total) {
        this.nots.default = total;
      }
    });
  }

  getReadedBellNotifications() {
    this.readedBellNotifications$ = this.notifyService.getReadBellNotification().subscribe((id: any) => {
      console.log({id})
      if (id != 'all' && this.nots.default > 0) {
        this.nots.default--;
      }else if(id == 'all' && this.nots.default > 0) {
        this.nots.default = 0
      }
    });
  }

  listenToNotifyEvents() {
    this.notifyService.getSocketNotifys().subscribe(() => this.nots.default++);
  }

  listenToChatEvents() {
    this.eventsService.listenToChatMessage()
      .pipe(
        filter(message => {
          const userId = this._user.getUserId();

          return message?.type === 'MESSAGE' && message?.message?.user?.userId !== userId
        }),
        map(message => message?.message)
      ).subscribe(() => this.chatNots.default++);
  }

  subscribeToUserChange() {

    // this._user.subscribeUserChange$().pipe(take(1)).subscribe(user => {
    //   console.log('subscribeToUserChange');
    this.validadeModulesEnabled(this.user);

    // })
  }

  connectLoginWebsocket() {
    if (!isOnLogin()) {
      this._loginService.connectEventSource();
    }
  }

  validadeModulesEnabled(user?: any) {
    this._user.getModules();
  }

  configAuthTimeout() {
    this._loginService.listenToUserInterection();
    if (this._user.isAuthenticated()) {
      let accessToken = this._user.getToken();
      this._loginService.setAuthTimeout({ accessToken });
    }
  }

  // getTotalNotifications(): void {
  //   this.bellNotifications$ = this.notifyService.getBellNotify().subscribe((value: number) => this.notifys = value)
  // }

  getBellActivitiesNotifys() {
    this.notifyService.getBellActivitiesNotify().subscribe(total => this.nots.activities = total);
  }


  getBellDefaultNotifys() {
    this.notifyService.getBellDefaultNotify().subscribe(total => this.nots.default = total);
  }

  subcribeToUserProfileChange() {
    this._user.subscribeUserChange$().pipe(skip(1)).subscribe((user: UserInterface) => {
      this.user = { ...this.user, ...user };
      this.token = localStorage.getItem('accessToken');
      this.validateLoggedUser(this._user.isAuthenticated());
    });
  }

  validateUserHasConfigPermission() {
    if (this._user.isAuthenticated()) {
      this.userHasConfigPermission = this._user.checkRole('UP_PLUS');
    }
  }

  logout() {
    this._pendentStore.dispatch(new PendentActions.ResetCreatePage);
    this._pendentStore.dispatch(new PendentActions.ResetCreateUser);
    this._pendentStore.dispatch(new PendentActions.ResetDefineHomepage);
    this.notifyService.cancelAlreadyOpenAll();

    let clientid = this._user.getClientId();
    let modeAuth = this._user.getDecodedToken().modeAuth;
    let auth = '';

    if (modeAuth == 'ACTIVE_DIRECTORY') {
      auth = 'ad';
    } else if (modeAuth == 'GOOGLE') {
      auth = 'google';
    } else if (modeAuth == 'OFFICE_365') {
      auth = 'office';
    } else if (modeAuth == 'ADFS') {
      auth = 'adfs';
    } else if (modeAuth == 'MANUAL') {
      auth = "manual";
    } else if (modeAuth == 'OKTA') {
      auth = "okta";
    }

    this._loginService.signout().subscribe(() => {
      if (clientid) {
        this.router.navigate([`/login${auth ? `/${auth}` : ''}`], { queryParams: { clientid, signedOut: 'true' } });
      } else {
        this.router.navigate([`/login${auth ? `/${auth}` : ''}`], { queryParams: { signedOut: 'true' } });
      }
    });

    this.listenerCopy();
    this.listenerCtxMenu();
    this.listenerPtrscr();

  }


  getColorsFromRedux() {
    this.store.select('colors').subscribe(colorsArray => {
      if (colorsArray) {
        this.colors = colorsArray['colors'];
      }

      let colorsId = this.colors.map(color => color.id);
      let selectedColor = this.colors.filter(color => color['selected']).map(color => color.id);
      if (selectedColor.length > 0) {
        this._ColorService.setColor(selectedColor[0]);
        this.colorSelected = selectedColor[0];
      } else if (colorsId.indexOf(this.colorSelected) == -1) {
        this._ColorService.setColor(colorsId[0]);
        this.colorSelected = colorsId[0];
      }

      this.validateUserHasConfigPermission();
    });
  }

  setNavbarHidden(event: NavigationEnd): void {
    event.url == '/' ||
      event.url.includes('/login') ||
      event.url === '/chat' ||
      event.url.includes('/validate') ||
      event.url.includes('/newlogin') ||
      event.url.includes('set_password') ||
      event.url.includes('/chats') ||
      event.url.includes('email-reset') ? this.showAppBar = false : this.showAppBar = true;

    let access = localStorage.getItem('accessToken');

    this.hasAccess = !!access

    this.blockFunctions();

    if (this.showAppBar) {
      this.validateShowHomepagesOnNavbar();
    }

    if (event.url.includes('/hp/page/')) {
      this.setActivePage(event.url);
    } else {
      this.selectedPageId = '';
    }

    this.validateLoggedUser(access);
  }

  validateShowHomepagesOnNavbar() {
    this._platformConfig.getShowHomepagesOnNavbar().subscribe({
      next: ({ showHomepagesOnNavbar }) => {
        this.showHomepagesOnNavbar = showHomepagesOnNavbar;
        localStorage.setItem('showHomepagesOnNavbar', showHomepagesOnNavbar);
      },
      error: (_) => { }
    })
  }

  validateLoggedUser(access) {

    if (this.showAppBar && !!access) {
      let media = this.user.photo;


      if (media && media.mediaId) {
        this.getPhoto(media.mediaId);
      }
      this.userInfo = this._user.getToken();
      this.getReadedChatMessages();
    }

  }
  setActivePage(url: string) {
    let pageId = url.replace('/hp/page/', '').split('?')[0];
    this.selectedPageId = pageId;
  }

  // getPhoto(mediaId) {
  //   this.mediaService
  //     .getPhoto(mediaId)
  //     .subscribe((media: any) => {
  //       this.mediaUrl = media.url;
  //     });
  // }

  getPhoto(mediaId) {
    this.mediaService.getMedia(mediaId).subscribe((media: any) => this.mediaUrl = media.url_thumbnail);
  }
  setColor(id: string) {
    this.colorSelected = id;
    this._ColorService.setColor(id);
    this._ColorService.updateColors(this.colors.map(color => {
      let newColor = { ...color };
      if (color.id == this.colorSelected) {
        newColor['selected'] = true;
      } else {
        newColor['selected'] = false;
      }
      return newColor;
    }));
  }

  getColorChange() {
    // this.strore.pipe(filter(colors => colors.colors.length>0)).subscribe(colors => colors.colors[0].first ? console.log(this.ngProgress.config.color) : null)
  }

  getFontFamily(): void {
    let localCms = localStorage.getItem('cms');
    let cms = JSON.parse(localCms);
    if (cms.homepageDefinition !== undefined && cms.homepageDefinition.customFontUrl !== "" &&
      cms.homepageDefinition.customFontUrl !== null && cms.homepageDefinition.customFontUrl !== undefined) {
      this._fontService.setFontFamily(cms.homepageDefinition.customFontUrl);
    }
  }

  getLanguage(): void {

    const cms = localStorage.getItem('cms');
    let parsed = JSON.parse(cms);
    if (parsed.homepageDefinition !== undefined) {
      this._language.getLanguage(parsed.homepageDefinition.language);
    } else {
      this._language.getLanguage("pt-Br");
    }

  }

  setGoogleAnalyticsEvent(): void {
    let cms = localStorage.getItem('cms');
    let parsed = JSON.parse(cms);

    if (parsed.homepageDefinition && parsed.homepageDefinition.googleMonitoringCode) {
      let gtagScript: HTMLScriptElement = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${parsed.homepageDefinition.googleMonitoringCode}`;
      document.head.prepend(gtagScript);
      gtag('config', parsed.homepageDefinition.googleMonitoringCode, { send_page_view: true });
    }

  }

  setMessage(severity: string, summary: string, detail: string): void {
    this.messageService.add({ severity, summary, detail })
  }

}
