import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, first, map, merge } from 'rxjs/operators';
import * as lodash from 'lodash';
import deepdash from 'deepdash';

import { fadeTop } from 'projects/f-cms/src/app/animations/animations';
import { Page } from 'projects/f-cms/src/app/models/page';
import { ContentMenu } from 'projects/f-cms/src/app/interfaces/contentMenu';

const _ = deepdash(lodash);

@Component({
  selector: 'app-page-search',
  templateUrl: './page-search.component.html',
  styleUrls: ['./page-search.component.scss'],
  animations: [fadeTop]
})
export class PageSearchComponent implements OnInit {

  search = new UntypedFormControl('')
  filters = false
  published = new UntypedFormControl(true)
  expired = new UntypedFormControl(true)
  validDate = new UntypedFormControl(true)
  noPublishedDate = new UntypedFormControl(true)
  all: Page[];

  @Output('search') searchEmitter = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.search.valueChanges
      .pipe(
        debounceTime(500),
        merge(
          this.published.valueChanges,
          this.expired.valueChanges,
          this.validDate.valueChanges,
          this.noPublishedDate.valueChanges
        ),
        map(() => this.search.value)
      )
      .subscribe(value => this.filter(value))
  }

  filter(name: string) {
    let published = this.published.value
    let expired = this.expired.value
    let validDate = this.validDate.value
    let noPublishedDate = this.noPublishedDate.value

    const search = {
      name,
      published,
      expired,
      validDate,
      noPublishedDate
    }

    this.searchEmitter.emit(search);
  }
}
