<nav *ngIf="loggedUser" (mouseleave)="dropbox=false" class="navbar fixed-top justify-content-between mmp-nav ms-4 mmp-nav--left">
    <div class="container large nv" *ngFor="let navbar_item of navbar_items">
        <!-- <a class="navbar-brand nav-icon nav-header" [routerLink]="[navbar_item.logo_url]"> -->
            <!-- <img src="../../assets/images/mmp5_opaque.png" /> -->
            <!-- </a> -->
        <div class="position-relative search-bar">
            <label for="search" [ngClass]="['label-input-search', active || search ? 'focus' : '']">Buscar</label>
            <input id="search" [(ngModel)]="search" (focus)="active = true" (blur)="active = false;log()" class="input-search-bar"/>
            <div [ngClass]="['search-icon-nav', active || search ? 'focus' : '']">
                <!-- <i class="fa fa-search"></i> -->
                <mmp5-mm-icons class="magnifying-glass" [icon]="['fa-solid','fa-magnifying-glass']"></mmp5-mm-icons>
            </div>
        </div>

        <div class="form-inline my-2 my-lg-0 d-flex align-items-center">
            <a class="navbar-link btn-as-link">
                <mmp5-mm-icons [ngStyle]="{'color': 'var(--navbar-subtitle-color)'}" [badge]="6" [color]="'secondary'" [icon]="['icon', 'icon-notificacoes']"></mmp5-mm-icons>
            </a>
            <a class="navbar-link btn-as-link" (click)="settings()" *ngIf="hasPermissionToConfig">
                <mmp5-mm-icons [ngStyle]="{'color': 'var(--navbar-subtitle-color)'}" [badge]="2" [color]="'sixth'" [icon]="['icon', 'icon-configuracoes']"></mmp5-mm-icons>
            </a>
            <p class="nav-text">
                <!-- Olá,
                <a [routerLink]="[navbar_item.view_user_url + loggedUser?.sub]" alt="loggedUser?.login" class="nav-icon me-1 user-link" *ngIf="loggedUser && loggedUser?.login; else licenseUser">
                        {{loggedUser?.displayName}}
                </a> -->
                <ng-template #licenseUser>
                    <span class="nav-text"> {{loggedUser?.email}}</span>
                </ng-template>
            </p>
            <div class="profile-info" (mouseenter)="dropbox=true">

                <img [routerLink]="'/hp/user-profile/'+loggedUser?.id" class="img-fluid profile-avatar" [src]="mediaUrl ? mediaUrl : '../../assets/images/avatar.jpg'" />
                <div class="name">
                    <span [routerLink]="'/users/management/view/'+loggedUser?.id" class="profile-name">
                        <strong>Olá, {{loggedUser?.displayName || loggedUser?.email}} <mmp5-mm-icons class="small" [icon]="['icon', 'icon-seta-2']"></mmp5-mm-icons></strong>
                    </span>
                    <p class="profile-nickname mb-0">{{loggedUser?.fullName}}</p>
                </div>

                <mmp-dropbox (mouseover)="dropbox=true" (mouseleave)="dropbox=false" (logout)="signout.emit(true)" [colors]="colors" [selectedId]="selectedColorId" (selected)="selectColor($event)" *ngIf="dropbox" [editProfile]="loggedUser?.id" class="dropbox-container"></mmp-dropbox>
            </div>
        </div>
    </div>
</nav>

<ng-content></ng-content>
