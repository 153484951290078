import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlPlusComponent } from './html-plus.component';
import { HtmlPlusTabsComponent } from './html-plus-tabs/html-plus-tabs.component';
import { DynamicHTMLModule } from '../../dynamic-html';
import { MatExpansionModule } from '@angular/material/expansion';
import { ImageGalleryModule } from '../image-gallery/image-gallery.module';
import { TranslateModule } from "@ngx-translate/core";
import { MediaViewModule } from '../media-view/media-view.module';
import { MMIconsModule } from 'mm-ui';
import { CustomAlertModule } from '../custom-alert/custom-alert.module';




@NgModule({
  declarations: [HtmlPlusComponent, HtmlPlusTabsComponent],
  imports: [
    CommonModule,
    DynamicHTMLModule,
    MatExpansionModule,
    ImageGalleryModule,
    TranslateModule,
    MediaViewModule,
    MMIconsModule,
    CustomAlertModule
  ],
  exports: [
    HtmlPlusComponent
  ]
})
export class HtmlPlusModule { }
