import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
@Component({
  selector: 'mmp5-mm-icons',
  templateUrl: './mm-icons.component.html',
  styleUrls: ['./mm-icons.component.scss']
})
export class MMIconsComponent implements OnInit, OnChanges {

  @Input() name: any = '';
  @Input() icon: any;
  @Input() badge: any
  @Input() color: string;

  constructor(){
  }

  ngOnInit() {
    this.setIcon(this.icon)
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes && changes.icon && changes.icon.currentValue) {
      this.setIcon(changes.icon.currentValue)
    }
  }

  setIcon(icon){
    if(typeof icon == 'string' && (icon.indexOf('fas') >= 0 || icon.indexOf('far') >= 0 || icon.indexOf('fab') >= 0 )){
      this.icon = icon.split(' ');
    }
  }


}

