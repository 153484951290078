<div [ngClass]="['navbar-homepage-list-content', (homepages?.length) > 12 ? 'diminuir' : '']" *ngIf="homepages?.length">
    <ul class="homepage-list">
        <a *ngFor="let homepage of homepages" (click)="onClickHandler(homepage?.id)">
            <li>
                <div  class="homepage-item">
                    <p class="homepage-title">
                        {{homepage?.title}}
                    </p>
                </div>
            </li>
        </a>
    </ul>
</div>