import * as HomepageDefinitionActions from "../actions/homepageDefinition.actions"
import { Homepage } from "../../../models/homepage";

export function reducerHomepageDefinition(state: Homepage, action: HomepageDefinitionActions.Actions){
    switch(action.type){
        case HomepageDefinitionActions.SET_FORM: 
            return {
                ...state,
                ...action.payload
            }
        case HomepageDefinitionActions.SET_FORM_EDIT: 
            return {
                ...state,
                ...action.payload
            }
        case HomepageDefinitionActions.RESET_FORM:
            return {}
        case HomepageDefinitionActions.SET_HOMEPAGE_DATA:
            return {
                ...state,
                title: action.payload.title,
                language: action.payload.language,
                id: action.payload.id,
                completedData: action.payload.completedData,
                clientId: action.payload.clientId
            }
        case HomepageDefinitionActions.SET_HOMEPAGE_COLORS:
            return {
                ...state,
                colors: action.payload.colors,
                completedColors: action.payload.completedColors
            }
        case HomepageDefinitionActions.SET_HOMEPAGE_BANNER:
            return {
                ...state,
                banner: action.payload.banner,
                completedSetUpBanner: action.payload.completedSetUpBanner
            }
        case HomepageDefinitionActions.SET_HOMEPAGE_WIDGET: 
            return Object.assign({}, state, action.payload)
        case HomepageDefinitionActions.SET_HOMEPAGE_LOGO:
            return {
                ...state,
                completedSetUpLogo: true
            }
        default: 
            return state;
    }   
}