<!-- ng-template ref -->
<ng-template #recursiveList let-list let-parent="parent">
    <div [ngClass]="
      {'content-list':true,
      'html':content?.contentType == 'HTML' || content?.contentType == 'MEDIAS' && !child,
      'collapse':content?.contentType == 'COLLAPSE' && !child,
      'tabs':content?.contentType == 'TABS' && !child,
      'custom_alerts': content?.contentType == 'CUSTOM_ALERTS' && !child}"
      [ngStyle]="{
        backgroundColor: content?.contentType != 'GRID' ? content?.backgroundColor : 'unset',
        border: content?.contentType != 'GRID' ? (content?.borderSize + 'px solid' + content?.borderColor) : 'unset' ,
        gridColumn: content?.columnSpan != undefined ? getGridColumn(content?.columnSpan, i, parent) : 'unset'
      }"
      *ngFor="let content of list; let i = index"
      [attr.data-border-left-none]="getBorderLeftNone(i, parent)"
    >
        <!-- HTML -->
        <div class="html-content" *ngIf="content?.contentType == 'HTML'">
            <dynamic-html [ngClass]="{'dynamic-html':true, 'blockCopy': this.blockCopy}" [content]="content?.htmlContent"></dynamic-html>
        </div>

        <!-- COLLAPSE -->
        <div class="collapse-content" *ngIf="content?.contentType == 'COLLAPSE'">
            <mat-expansion-panel class="expansion-panel" (opened)="getCollapseContent(content, content?.id)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h6 class="mb-0">
                            <dynamic-html [ngClass]="{'dynamic-html':true, 'blockCopy': this.blockCopy}" [content]="content?.title"></dynamic-html>
                        </h6>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="expansion-content">
                  <p *ngIf="downloading == true"> {{'general.loading' | translate}}</p>
                    <dynamic-html [ngClass]="{'dynamic-html':true, 'blockCopy': this.blockCopy}" [content]="content.htmlContent"></dynamic-html>
                    <div *ngIf="content?.contents?.length > 0">
                      <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: content.contents, parent: content}"></ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>

        <!-- IMAGE-GALLERY -->
        <div class="gallery-content" *ngIf="content?.contentType == 'IMAGE_GALLERY'">
            <page-image-gallery [images]="content?.images" [featureItem]="false" [feature]="content" [htmlPlus]="true" [position]="'CENTER'" [size]="'BIG'" [title]="content.displayTitle ? content?.title : '' "></page-image-gallery>
        </div>

        <!-- TABS -->
        <html-plus-tabs [content]="content" [not-a-feature]="notAFeature" *ngIf="content?.contentType == 'TABS'" [child]="child"></html-plus-tabs>

        <!--CUSTOM ALERTS-->
        <div class="custom-alerts-container" *ngIf="content?.contentType == 'CUSTOM_ALERTS' && content.contents.length > 0">
          <div *ngFor="let alert of content?.contents" class="ct-alt">
            <custom-alert [id]="alert.customAlertId" [config]="alert?.customAlert">
              <dynamic-html class="dynamic-html" [content]="alert.htmlContent"></dynamic-html>
            </custom-alert>
           </div>
        </div>

        <!--MEDIAS-->
        <div class="medias-container" *ngIf="content?.contentType == 'MEDIAS' ">
          <div *ngFor="let media of content?.contents" [ngClass]="['media-container', media?.mediaInfo?.align || '']">
            <media [id]="media.mediaInfo.mediaId || media.mediaInfo.id" [data-media]="media" [data-mediapicker]="media.mediaInfo.mediaPicker"></media>
            <dynamic-html *ngIf="media.htmlContent" class="dynamic-html" [content]="media.htmlContent"></dynamic-html>
          </div>
        </div>

        <!-- GRID -->
        <div class="grid-border" *ngIf="content?.contentType == 'GRID'">
          <div class="grid-container" [ngClass]="[content?.gridType || '', content?.columnAmount ? 'padding' : '']" [ngStyle]="content.columnSize ? { gridTemplateColumns: content.columnSize } : undefined">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: content.contents, parent: content}"></ng-container>
          </div>
        </div>
        <div *ngIf="content?.contents?.length > 0 && content?.contentType == 'HTML'">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: content.contents, parent: content}"></ng-container>
        </div>
    </div>
</ng-template>

<div [ngClass]="{'html-plus-content': !child, 'not-a-feature':notAFeature}">
    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: contents}"></ng-container>
</div>
