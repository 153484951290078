import { Component, OnInit, Input, ElementRef, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CustomAlert, mediaType } from 'projects/f-cms/src/app/interfaces/customAlert';
import { Utils } from 'projects/f-cms/src/app/utils/utils';
import { MediaService } from '../../../media/media.service';
import { CustomAlertService } from './custom-alert.service';

@Component({
  selector: 'custom-alert',
  templateUrl: './custom-alert.component.html',
  styleUrls: ['./custom-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomAlertComponent implements OnInit {

  @Input('config') config: CustomAlert;
  @Input('id') id: any;
  mediaType = mediaType;

  mdSrvc: any;
  iTk: any;

  constructor(
    private elRef: ElementRef,
    private customAlertService: CustomAlertService,
    private _mediaService: MediaService,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.getId();
    if (this.utils.hasStorage('mdService')) {
      this.mdSrvc = this.utils.getStorage('mdService')
    }
    this._mediaService.getIamToken().subscribe(itk => {
      this.iTk = itk
    });
  }

  getId() {
    if (this.elRef && !this.id) {
      this.id = this.elRef.nativeElement.id;
    }

    if (this.id && !this.config) {
      this.customAlertService.getAlertById(this.id).subscribe((alert: CustomAlert) => {
        this.config = alert;
      });
    }
  }
}
