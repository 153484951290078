import * as myGlobals from '../../utils/globals'
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'mm-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {


  show_menu:boolean = myGlobals.show_menu;
  menu_items = myGlobals.menu_items;
  navbar_items = myGlobals.navbar_items;
  exact: boolean = false;

  status: string
  messageStatus: string
  messageText: string

  imgurl: Array<string> = []
  imgclass: Array<string> = []

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.status = this.route.snapshot.data.status
    if(!this.status){
      this.status = this.route.snapshot.params.status
    }
    if(this.status){
      this.messageStatus = 'danger'
      this.messageText = `error_page.${this.status}`
    }

    this.imgurl =[
      '../assets/images/403image.png'
    ]

    this.imgclass =[
      'col-lg-8 text-center'
    ]

  }

}
