import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MMIconsComponent } from './mm-icons.component';

@NgModule({
  declarations: [
    MMIconsComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
  ],
  exports: [
    MMIconsComponent
  ]
})
export class MMIconsModule {}
