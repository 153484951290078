import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule } from "@angular/forms";

import { NavbarComponent } from "./navbar.component";
import { SidebarToggleDirective } from "./navbar.directive";
import { NavbarService } from "./navbar.service";
import { MMIconsModule } from "../mm-icons/mm-icons.module";
import { DropboxComponent } from "./dropbox/dropbox.component";

@NgModule({
    declarations:[
        NavbarComponent,
        DropboxComponent,
        SidebarToggleDirective
    ],
    exports: [
        NavbarComponent,
        DropboxComponent
    ],
    imports:[
        RouterModule,
        CommonModule,
        FormsModule,
        NgScrollbarModule,
        MatTooltipModule,
        MMIconsModule
    ],
    providers: [
        NavbarService
    ]
})

export class NavbarComponentModule {}
