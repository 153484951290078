<div class="image-gallery__type-gallery">
    <div class="galery-control" (mouseenter)="galleryControl = true" (mouseleave)="galleryControl = false">
        <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="carouselItems" (onMove)="setTileCarouselPosition($event)" *ngIf="medias.length > 0">
            <div *nguCarouselDef="let item;" [ngClass]="['item', position || 'CENTER']">
                <div [ngClass]="['img-carrousel-gallery', position || 'CENTER']" *ngIf="medias[item-1] as media">
                    <div (click)="click(item)" class="position-relative ctn">
                        <img [ngClass]="['img-fluid', size || '']" [src]="media?.mediaUrl | authImage:'image':iTk:mdSrvc | async" [alt]="media?.mediaTitle || 'imagem'" />
                        <div *ngIf="(showTitle && media?.mediaTitle) || (showDesc && media?.mediaDescription)" class="overlay-title">
                            <h5 *ngIf="showTitle" class="mb-0">{{media?.mediaTitle}}</h5>
                            <p  *ngIf="showDesc">{{media?.mediaDescription}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="galleryControl || showControls" NguCarouselNext class="cover-controls rightRs">
                <mmp5-mm-icons [icon]="['icon','icon-banner-proximo']"></mmp5-mm-icons>
            </button>
            <button *ngIf="galleryControl || showControls" NguCarouselPrev class="cover-controls leftRs">
                <mmp5-mm-icons [icon]="['icon','icon-banner-anterior']"></mmp5-mm-icons>
            </button>
        </ngu-carousel>
    </div>

    <div (mouseenter)="tilesControl = true" (mouseleave)="tilesControl = false">
        <ngu-carousel #myCarouselTile [inputs]="carouselTileConfig" class="tiles-ngu" [dataSource]="carouselItems" *ngIf="medias?.length > 0">
            <ngu-tile *nguCarouselDef="let item; let j = index">
                <div class="tile-gallery" (click)="setImageOnCarousel(item)" *ngIf="medias[item-1] as media" [style.background]="media?.mediaUrl | authImage:'background':iTk:mdSrvc | async">
                <!-- <h1>{{j}}</h1> -->
                </div>
            </ngu-tile>
            <button *ngIf="tilesControl || showControls" NguCarouselPrev class="leftRs" [style.opacity]="myCarouselTile.isFirst ? 0.5:1">
                <mmp5-mm-icons [icon]="['icon','icon-banner-anterior']"></mmp5-mm-icons>
            </button>
            <button *ngIf="tilesControl || showControls" NguCarouselNext class="rightRs" [style.opacity]="myCarouselTile.isLast ? 0.5:1">
                <mmp5-mm-icons [icon]="['icon','icon-banner-proximo']"></mmp5-mm-icons>
            </button>
            <!-- <ul class="myPoint" NguCarouselPoint>
                <li *ngFor="let j of myCarousel.pointNumbers; let j = index" [class.active]="j==myCarousel.activePoint" (click)="myCarousel.moveTo(j)"
                [style.background]="'url(' + carouselTileItems[j] + ')'"></li>
            </ul> -->
        </ngu-carousel>
    </div>
</div>
