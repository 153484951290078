import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PageListPage } from 'projects/f-cms/src/app/interfaces/featuresConfig';
import { Page } from 'projects/f-cms/src/app/models/page';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'app-page-picker',
  templateUrl: './page-picker.component.html',
  styleUrls: ['./page-picker.component.scss']
})
export class PagePickerComponent implements OnInit {

  @Input('picked$') picked$: BehaviorSubject<PageListPage[]>
  @Input('pages') pages: PageListPage[]
  @Input('pages$') pages$: BehaviorSubject<Page[]>

  pagesLength: number

  constructor() { }

  ngOnInit() {

    if(this.pages && !this.picked$){
      this.picked$ = new BehaviorSubject(this.pages)
    }

    this.picked$.subscribe(res => this.pagesLength = res.length)
  }

  remove(page: PageListPage){
    let picked = this.picked$.value.filter(pick => pick.id != page.id)
    this.picked$.next(picked)
  }

}
