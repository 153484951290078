import { Component, Input, OnInit, SimpleChanges, OnDestroy } from "@angular/core";
import { MediaService } from "../../../components/media/media.service";
import { LoggedUser } from "../../../models/loggedUser.service";
import { ChatService } from "../../../components/chat/chat.service";
import { UserInterface } from "../../../interfaces/userInterface";
import { Subscription } from "rxjs";
import { Utils } from "../../../utils/utils";

@Component({
  selector: 'mm-chat-users-images',
  templateUrl: './chat-users-images.component.html',
  styleUrls: ['./chat-users-images.component.scss']
})

export class ChatUsersImagesComponent implements OnInit, OnDestroy {

  @Input('users') users: Array<any> = [];

  photos: Array<string> = [];
  loggedUser: UserInterface;
  loggedUserId: string;
  media;
  getPhotoSub: Subscription;
  mdSrvc: string;
  iTk: any;

  constructor(
    private mediaService: MediaService,
    private chatService: ChatService,
    private utils: Utils
  ) { }

  ngOnInit(): void {
    if (this.utils.hasStorage('mdService')) {
      this.mdSrvc = this.utils.getStorage('mdService')
    }

    this.mediaService.getIamToken().subscribe(itk => this.iTk = itk);

    // this.chatService.loggedUserReturned
    // .subscribe(user => {
    //   this.loggedUser = user;
    //   this.loggedUserId = user.id;
    // })
    this.getUsersPhotos();
  }

  getUsersPhotos() {
    this.photos = [];
    this.users
      .forEach(result => {
        if (result.photo && result.photo.mediaId) {
          this.getPhotoSub = this.mediaService
            .getMedia(result.photo.mediaId)
            .subscribe((media: any) => {
              let hasPhoto = media ? media.url : '../../../../../assets/images/avatar_v2.jpg';
              this.photos.push(hasPhoto);
            }, err => {
              console.log({ 'chat-tray-media-error': err });
              return '../../../../../assets/images/avatar_v2.jpg'
            })

        } else {
          this.photos.push('../../../../../assets/images/avatar_v2.jpg');
        }
      }, err => console.log(err))
  }

  onError(e) {
    e.target.src = '../../../../../assets/images/avatar_v2.jpg';
  }

  ngOnDestroy(): void {
    if (this.getPhotoSub)
      this.getPhotoSub.unsubscribe();
  }

}
