import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mmp5-iconbox',
  templateUrl: './icon-box.component.html',
  styleUrls: ['./icon-box.component.scss']
})
export class IconBoxComponent implements OnInit{
  
  user: any;
  // @Input() icon: Array<string> = ['']  
  @Input() imageclass: string=''
  @Input() imgurl: Array<string> = ['']
  @Input() message: Array<string> = ['']
  
  
  constructor(){    
  }
  
  
  ngOnInit(){ 

  }
}
