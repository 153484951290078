export const environment = {
  production: false,
  recaptcha: {
    key: "6Le2qBEpAAAAAPbFDfubdAHprBnAWcYUMab9BTlQ",
    disabled: false,
    url: "https://www.google.com/recaptcha/enterprise.js"
  },
  endpoint: 'statement',
  chatWS: 'https://chat.ws.tst.mmcafe.corp',
  firebase: {
    apiKey: "AIzaSyCrMdfJ31nYg3I161w7ympxihPT2OEK4wY",
    authDomain: "mmp5-gcdev-push.firebaseapp.com",
    projectId: "mmp5-gcdev-push",
    storageBucket: "mmp5-gcdev-push.appspot.com",
    messagingSenderId: "131502114715",
    appId: "1:131502114715:web:7c55711d33d4bfefff433e",
    measurementId: "G-HSHGG7FGBM"
  }
};
