import { NgModule, ModuleWithProviders } from "@angular/core";
import { LoggedUser } from "./loggedUser.service";

@NgModule({})
export class LoggedUserModule{
    static forRoot(): ModuleWithProviders<LoggedUserModule>{
        return {
            ngModule: LoggedUserModule,
            providers: [LoggedUser]
        }
    }
}
