import { Page } from "../models/page";
import * as PageActions from '../actions/page.actions'

export function reducerPage (state: Page, action: PageActions.Actions){
    switch(action.type){
        case PageActions.SET_PAGE:
            return {
                ...state,
                ...action.payload
            }
        case PageActions.RESET_PAGE:
            return {}
        case PageActions.LOAD_PAGE:
            return {
                ...state
            }
        case PageActions.SET_ERROR:
            return {
                ...state,
                error: {...action.payload}
            }
        default: 
            return state;
    }
}