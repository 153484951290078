import { NgModule } from "@angular/core";
import { CustomAlertComponent } from "./custom-alert.component";
import { CommonModule } from "@angular/common";
import { MMIconsModule, SafePipeModule } from "mm-ui";
import { CustomAlertService } from "./custom-alert.service";
import { PipesModules } from "projects/f-cms/src/app/pipes/pipes.modules";
import { MediaService } from "../../../media/media.service";

@NgModule({
  declarations: [
    CustomAlertComponent
  ],
  exports: [
    CustomAlertComponent
  ],
  imports: [
    CommonModule,
    MMIconsModule,
    SafePipeModule
  ],
  providers: [
    CustomAlertService,
    MediaService
  ]
})
export class CustomAlertModule { }
