import { Directive, HostBinding, HostListener } from "@angular/core";

@Directive({
    selector: '[sidebartoggle]'
})


export class SidebarToggleDirective {

    @HostBinding('class.open')
    menu_toggle:boolean = false;

    @HostListener('click', ['$event'])
    toggleMenu(event:any){
        
        event.stopPropagation();       
        this.menu_toggle = !this.menu_toggle;
        
    }
}