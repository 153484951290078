import { Component, OnInit, Input } from '@angular/core';

import { FormSubmitStatusEntry } from './form-submit-status-entry';

@Component({
  selector: 'mmp5-form-submit-status',
  templateUrl: './form-submit-status.component.html',
  styleUrls: ['./form-submit-status.component.scss']
})
export class FormSubmitStatusComponent implements OnInit {

  @Input() data: FormSubmitStatusEntry;

  constructor() { }

  ngOnInit() {
  }


}
