import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { Term, GlossaryList, AllTerms, SimpleTerm } from '../../interfaces/glossary';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlossaryService {

  private apiVersion = `v${AppConfig.data['apiVersion']}`;
  private service = AppConfig.data['services']['glossary'];
  private endpoint = `${this.service}/${this.apiVersion}`

  constructor(
    private http: HttpClient
  ) { }

  getGlossaryList(name: string, page?: any, size?: any){
    let params = {
      name,
      page,
      size
    };
    //                                                                    remove undefined values
    return this.http.get<GlossaryList>(`${this.endpoint}/terms`, {params: JSON.parse(JSON.stringify(params))})
  }

  getTermById(id: string){
    return this.http.get<Term>(`${this.endpoint}/term/${id}`)
  }

  createTerm(glossary: Term){
    return this.http.post(`${this.endpoint}/terms`, glossary)
  }

  editTerm(glossary: Term){
    return this.http.put(`${this.endpoint}/terms/${glossary.id}`, glossary)
  }

  deleteTerm(glossary: Term){
    return this.http.delete(`${this.endpoint}/terms/${glossary.id}`)
  }

  getGlossaryLetters(){
    return this.http.get<string[]>(`${this.endpoint}/letters`)
  }

  getTermByLetters(letter: string){
    return this.http.get<Term[]>(`${this.endpoint}/${letter}`)
  }

  getAllTermsHighlight(){
    return this.http.get<AllTerms>(`${this.endpoint}/allHighlightable`)
  }

  getAllTerms(){
    return this.http.get<SimpleTerm[]>(`${this.endpoint}/all`).pipe(map(terms => terms.reduce((prev, curr) => ({...prev, [curr.name]: curr}), {}) as AllTerms))
  }
}
