import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotifyDialogManagementComponent } from './notify-dialog-management/notify-dialog-management.component';
import { NotifyDialogComponent } from './notify-dialog/notify-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicHTMLModule } from '../../components/view/dynamic-html';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldsetHarukiModule, MMIconsModule } from 'mm-ui';
import { components, directives } from "../../components/view/dynamic-html/components";
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HtmlPlusModule } from '../../components/view/page/html-plus/html-plus.module';



@NgModule({
    declarations: [NotifyDialogManagementComponent, NotifyDialogComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        MatDialogModule,
        MMIconsModule,
        DynamicHTMLModule,
        FieldsetHarukiModule,
        NgScrollbarModule,
        RouterModule,
        DynamicHTMLModule.forRoot({ components }, { directives }),
        HtmlPlusModule
    ],
    exports: [NotifyDialogManagementComponent, NotifyDialogComponent]
})
export class NotifyDialogModule { }
