import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CropperData } from './cropper-data';

import Cropper from 'cropperjs/dist/cropper.esm.js';
import { CropperService } from './cropper.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'mmp5-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit, AfterViewInit {

  @Input() file = null;
  @Input() options;
  @Input() showThisButtons: any;
  @Input() cropBoxSize;
  
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('image', {static: true}) image: ElementRef;
  
  cropper: Cropper = null;
  
  hasScaleX: boolean = false;
  hasScaleY: boolean = false;
  
  cancelCropper:boolean = false;
  aspectRatio: number;
  mimeType: string;

  constructor(
    private CropperService: CropperService
  ) { }

  ngOnInit() {
      
  }


  ngAfterViewInit() {
  
    if (this.file) {
      if(typeof this.file == 'string'){
        this.mimeType = this.file.split(';')[0].replace('data:', '')
      }
      if (this.cropBoxSize.width == null || this.cropBoxSize.height == null) {
        this.aspectRatio = (750 / 223);
      } else {
        this.aspectRatio = (this.cropBoxSize.width / this.cropBoxSize.height);
      }

      this.cropper = new Cropper(this.image.nativeElement, {
        ...this.options,
        checkCrossOrigin: false,
        checkOrientation: false,
        ready: () => {
          
          this.cropper.setCropBoxData({...this.cropBoxSize});
          this.cropper.setAspectRatio(this.aspectRatio.toFixed(2));
          
        },
      })
      this.CropperService.getData().pipe(filter(data => Object.keys(data).length > 0)).subscribe(data => {
        this.cropper.setData(data)
      })
    }
  }

  onCancel(event) {
    event.preventDefault();
    this.cancel.emit(this.cancelCropper);
  }

  onCrop(event) {
    
    event.preventDefault();


    //changed to disable JPG to PNG conversion

    /* const data = this.cropper.getData();
    this.cropper.getCroppedCanvas({
      width: this.cropBoxSize.width ? this.cropBoxSize.width : 500,
      height: this.cropBoxSize.height ? this.cropBoxSize.height : 500
    }).toBlob( blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const toEmit: CropperData = { data, base64: reader.result };
        this.save.emit(toEmit);
      };
    }); */

    const data = this.cropper.getData();
    let image = this.cropper.getCroppedCanvas({
      width: this.cropBoxSize.width ? this.cropBoxSize.width : 500,
      height: this.cropBoxSize.height ? this.cropBoxSize.height : 500,
      fillColor: '#fff'
    }).toDataURL(this.mimeType)

    this.save.emit({
      data,
      base64: image
    })
  }

  zoomPlus(event){
    event.preventDefault();
    this.cropper.zoom(0.1);
    
  }

  zoomMinus(event){
    event.preventDefault();
    this.cropper.zoom(-0.1);
    
  }

  scaleX(event){

    this.hasScaleX = !this.hasScaleX
    let x = this.hasScaleX ? -1 : 1 ;
    event.preventDefault();
    this.cropper.scaleX(x);
  }

  scaleY(event){

    this.hasScaleY = !this.hasScaleY
    let y = this.hasScaleY ? -1 : 1 ;
    event.preventDefault();
    this.cropper.scaleY(y);
    
  }

  rotateLeft(event){
    event.preventDefault();
    this.cropper.rotate(-45);
  }

  rotateRight(event){
    this.cropper.rotate(45);
  }

  flipRight(event){
    this.cropper.rotate(-90);
  }

  flipLeft(event){
    this.cropper.rotate(90);
  }

  reset(event){
    this.cropper.reset()
    this.cropper.setCropBoxData(this.cropBoxSize)
  }


}
