import { NgModule } from "@angular/core";
import { TooltipComponent } from "./tooltip.component";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MMIconsModule } from "../mm-icons/mm-icons.module";

@NgModule({
    declarations: [
        TooltipComponent
    ],
    imports: [
        CommonModule,
        MatTooltipModule,
        MMIconsModule
    ],
    exports: [
        TooltipComponent
    ]

})

export class TooltipModule {}
