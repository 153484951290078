import { NgModule } from "@angular/core";
import { AuthImagePipe } from "./authImagePipe.pipe";
import { SafePipe } from "./safePipe.pipe";

@NgModule({
    declarations: [
        SafePipe,
        AuthImagePipe
    ],
    exports: [
        SafePipe,
        AuthImagePipe
    ]
})

export class SafePipeModule{}
