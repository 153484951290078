    <section class="mm-box-container dark key-value-config">
      <div class="add-new">
        <button class="btn btn-as-link" (click)="addNew()">
          Adicionar novo parâmetro
        </button>
      </div>
      <div class="content">
        <ng-scrollbar>
          <div class="form-item" *ngFor="let form of forms?.controls; let i = index">
            <mmp5-fieldset-haruki
              [formControl]="form.get('type')"
              [label]="'Tipo de Parâmetro'"
              [selectOptions]="typeOptions">
            </mmp5-fieldset-haruki>
            <mmp5-fieldset-haruki
              [formControl]="form.get('name')"
              [label]="'Chave'">
            </mmp5-fieldset-haruki>
            <button title="Excluir Configuração" class="btn btn-danger" (click)="removeForm(i)">
              <i class="fa fa-trash"></i>
            </button>
            <mmp5-fieldset-haruki
              class="value"
              [formControl]="form.get('value')"
              [label]="'Valor'"
              [type]="'textarea'"
              [height]="'150px'">
            </mmp5-fieldset-haruki>
          </div>
        </ng-scrollbar>
      </div>
    </section>

