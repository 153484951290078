import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map, startWith, tap} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {BreadCrumb} from './breadcrumb';

@Component({
  selector: 'mmp5-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit{

  breadcrumbs$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    distinctUntilChanged(),
    map(event => this.buildBreadCrumb(this.activatedRoute.root)),
    startWith(this.buildBreadCrumb(this.activatedRoute.root))
  );

    // Build your breadcrumb starting with the root route of your current activated route

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate : TranslateService
  ) {}

  ngOnInit() {}

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    // If no routeConfig is avalailable we are on the root path
    const label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : 'Home';
    const path = route.routeConfig ? route.routeConfig.path : '';
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = `${url}${path}/`;
    const breadcrumb = {

      label: label,
      url: nextUrl,
    };

    let regex = /[\/\/]{2}$/;
    let newBreadcrumb = breadcrumbs.filter(b => {
        let nb = regex.test(b.url.trim());
        return nb == false
    })

    const newBreadcrumbs = [...newBreadcrumb, breadcrumb]
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    document.title = "";
    newBreadcrumbs.forEach(element => {
      if (document.title == "") {
        document.title = `${element.label}`
      } else if (element.label != "") {
        document.title = `${document.title} - ${this.translate.instant(element.label)}`
      }
    });
    return newBreadcrumbs;
  }
}
