import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HTMLPlusContent, HTMLPlusContentGridTypeColumnSize, ImageGalleryConfig } from 'projects/f-cms/src/app/interfaces/featuresConfig';
import { ViewService } from '../../view.service';

@Component({
  selector: 'html-plus',
  templateUrl: './html-plus.component.html',
  styleUrls: ['./html-plus.component.scss']
})
export class HtmlPlusComponent implements OnInit {

  @Input('contents') contents: HTMLPlusContent[];
  @Input('html-id') htmlId: string;
  @Input('child') child = false;
  @Input('not-a-feature') notAFeature: boolean;
  openTabs = {};
  loading = this._translate.instant('general.loading')
  downloading = false
  blockCopy: any;

  constructor(
    private viewService: ViewService,
    private _translate: TranslateService
  ) { }

  ngOnInit() {
    this.viewService.getBlockCopyOnView().subscribe({
      next: resp => this.blockCopy = resp.blockCopy,
      error: err => console.log({ blockCopyError: err })
    });

    if (!this.notAFeature) {
      this.contents.forEach((element, index) => {
        if (element.contentType != 'COLLAPSE' && element.contentType != 'TAB' && element.contentType != 'TABS') {
          if (element?.id === undefined) return;

          //this.viewService.htmlContent(element.id).subscribe(resp => element.htmlContent = resp);
          if (element.contents.length) {
            this.checkMoreContentsHtml(element);
          }
        } else if (element.contentType == 'TAB' || element.contentType == 'TABS') {
          this.getTabsContent(element, element.contents[0]);
        }
      });
    }
  }

  getCollapseContent(content, id): void {
    if (!this.notAFeature) {
      // this.downloading = true;

      if (id === undefined) return;

      // this.viewService.htmlContent(id).subscribe({
      //   next: resp => {
      //     this.downloading = false;
      //     content.htmlContent = (resp) ? resp : " ";
      //     if (content.contents.length > 0) {
      //       content.contents.forEach(element => {
      //         if (element.contentType == 'TAB' || element.contentType == 'TABS') {
      //           this.getTabsContent(element, element.contents[0]);
      //         } else if (element.contentType == 'HTML') {
      //           this.getHtmlContent(element, element.id);
      //         }
      //       });
      //     }
      //   },
      //   error: err => {
      //     console.log(err);
      //     this.downloading = false;
      //   }
      // });
    }
  }

  getTabsContent(content, tabs): void {
    if (!this.notAFeature) {
      content.contents.forEach(tab => {
        if (tab.id == tabs.id) {
          // tab.htmlContentDynamic = this.loading
          //this.downloading = true;

          if (tab?.id === undefined) return;

          //  this.viewService.htmlContent(tab.id).subscribe(resp => {
          //    this.downloading = false;
          //    tab.htmlContent = resp;
          //    content.contents.forEach(element => {

          //      if (element.contentType == 'TAB' || element.contentType == 'TABS') {
          //        this.getTabsContent(element, element.contents[0]);
          //      } else if (element.contentType == 'HTML') {
          //        this.getHtmlContent(element, element.id);
          //      }

          //    });
          //  });
        }
      });
    }
  }

  checkMoreContentsHtml(moreContent): void {
    if (moreContent.contents.length && !this.notAFeature) {
      moreContent.contents.forEach(content => {
        this.getHtmlContent(content, content.id);
        this.checkMoreContentsHtml(content);
      });
    }
  }

  getHtmlContent(content, id): void {
    // this.viewService.htmlContent(id).subscribe(resp => {
    //   content.htmlContent = (resp) ? resp : "";
    // });
  }

  setBackgroundColor(content: HTMLPlusContent) {
    return content.contents.map(c => ({ ...c, backgroundColor: content.backgroundColor }));
  }

  getGridColumn(columnSpan: number, i: number, parent: HTMLPlusContent) {
    return `span ${columnSpan}`
  }

  getBorderLeftNone(i: number, parent: HTMLPlusContent) {
    if (parent?.gridType !== 'CUSTOM' || i === 0) {
      return undefined
    }

    const relativeIndex = parent?.contents.reduce((acc, c, index) => {
      if (index < i) {
        acc += c.columnSpan || 1;
      } else if (index === i) {
        acc += 1;
      }

      return acc;
    }, 0);

    return relativeIndex % parent?.columnAmount === 0 ? 'true' : undefined;
  }

  getBorderRightNone(i: number, parent: HTMLPlusContent) {
    if (parent?.gridType !== 'CUSTOM' || i === 0) {
      return undefined
    }

    const relativeIndex = parent?.contents.reduce((acc, c, index) => {
      if (index < i) {
        acc += c.columnSpan || 1;
      } else if (index === i) {
        acc += 1;
      }

      return acc;
    }, 0);

    return relativeIndex % parent?.columnAmount === 1 ? 'true' : undefined;
  }
}
