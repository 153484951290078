<div (mouseenter)="galleryControl = true" (mouseleave)="galleryControl = false" [ngClass]="[featureItem ? 'feature-item' : '', 'image-gallery']">
    <h2>{{title}}</h2>
    <div class="media-gallery" *ngIf="images?.length > 0">
        <mm-gallery
          [showControls]="galleryControl"
          [size]="size"
          [showTitle]="feature?.displayTitle"
          [showDesc]="feature?.showDescription"
          (clickedImage)="log($event-1)"
          [medias]="images"
          [position]="position"
          [iamTk]="iTk"
          *ngIf="feature?.galleryType == 'GALLERY'">
        </mm-gallery>
    </div>
    <div [ngClass]="[size || '', 'media-mural']" *ngIf="(images?.length > 0) && feature?.galleryType == 'MURAL'">
        <!-- <ng-scrollbar  [visibility]="'hover'" [autoHeightDisabled]="false"  track="horizontal"> -->
            <div [ngClass]="['mural__images']">
                <div (click)="log(i)" *ngFor="let image of images; let i = index" class="mural__image-item" [style.backgroundImage]="image?.mediaUrl | authImage:'background':iTk:mdSrvc | async"></div>
            </div>
        <!-- </ng-scrollbar> -->
    </div>
    <div *ngIf="feature?.galleryType == 'ALBUM' && images?.length > 0" class="mural-album">
        <div class="mural-placeholder-content">
            <img alt="imagem do album" [src]="images[0].mediaUrl | authImage:'image':iTk:mdSrvc | async" class="img-fluid">
            <div class="zoom-image">
                <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-6']" (click)="log(0)"></mmp5-mm-icons>
            </div>
       </div>
    </div>
    <div class="mural-masonry" *ngIf="feature?.galleryType == 'MASONRY'">
        <ngx-masonry>
            <div ngxMasonryItem class="masonry-item" *ngFor="let image of images; let i = index">
                <img *ngIf="image && image.mediaUrl" alt="imagem do masonry" (click)="log(i)" [src]="image?.mediaUrl | authImage:'image':iTk:mdSrvc | async" class="img-fluid" />
            </div>
        </ngx-masonry>
    </div>
    <div class="zoom" *ngIf="openPopUp">
        <mm-zoom-image (closed)="openPopUp = false" [content]="true" [active]="true">
            <div class="popUp__view">
                <mm-gallery
                  (closed)="openPopUp = false"
                  [showControls]="true"
                  [changeImageUsingArrows]="true"
                  [showTitle]="feature?.displayTitle || htmlPlus"
                  [showDesc]="feature?.showDescription || htmlPlus"
                  *ngIf="startSlide"
                  [startSlide]="startSlide"
                  (click)="$event.stopPropagation()"
                  [medias]="images"
                  [carouselTileConfig]="configTileZoom"
                  [position]="position"
                  [iamTk]="iTk">
                </mm-gallery>
            </div>
        </mm-zoom-image>
    </div>
    <div #light id="lightgallery" class="light-gallery"></div>
</div>
