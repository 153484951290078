import { GlossaryHighlightService } from "../components/view/dynamic-html/glossary-highlight.service";
import { GlossaryService } from "../components/glossary/glossary.service";
import { ModuleWithProviders, NgModule } from "@angular/core";

@NgModule({})
export class GlossaryHighlightServiceModule{
    static forRoot(): ModuleWithProviders<GlossaryHighlightServiceModule>{
        return {
            ngModule: GlossaryHighlightServiceModule,
            providers: [
                GlossaryHighlightService,
                GlossaryService
            ]
        }
    }
}