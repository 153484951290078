import { CoverImage } from "../models/page";
import { Pageable } from "./pageable";

export interface CustomAlert {
  id?: string;
  title: string;
  showTitle: boolean;
  lineAndIcoColor: string;
  backgroundColor: string;
  ico: string | any[];
  icoArray: any[];
  mediaType: mediaType;
  customAlertImage: CoverImage;
  base64: string;
  active?: boolean;
}

export interface CustomAlertPageable extends Pageable {
  content: CustomAlert[];
}

export enum mediaType {
  empty = 'EMPTY',
  ico = 'ICO',
  image = 'IMAGE'
}
