import { DOCUMENT } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private renderer: Renderer2;

  constructor(
    private _translate: TranslateService,    
    rendererFactory: RendererFactory2
  ) { 
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  getLanguage(language): void {

    if (language === "ES") {      
      this._translate.setDefaultLang('es');
      this._translate.use('es');
      moment.locale('es');
      this.renderer.setAttribute(document.querySelector('html'), 'lang', 'es')
      
    } else if (language === "EN") {
      this._translate.setDefaultLang('en-US');
      this._translate.use('en-US');
      moment.locale('en-US');     
      this.renderer.setAttribute(document.querySelector('html'), 'lang', 'en')      

    } else {
      this._translate.setDefaultLang('pt-Br');
      this._translate.use('pt-Br');
      moment.locale('pt-Br');            
      this.renderer.setAttribute(document.querySelector('html'), 'lang', 'pt-Br')      
    }

 }

}
