import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mm-highlight-data',
  templateUrl: './highlight-data.component.html',
  styleUrls: ['./highlight-data.component.scss']
})
export class HighlightDataComponent implements OnInit {

  loadMoreText: string;
  definition: string;
  id: any;
  @Output('leave') leave = new EventEmitter()

  constructor(
    private _translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadMoreText = this._translate.instant("button.loadMore");
  }

}
