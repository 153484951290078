import { Injectable, Injector, ElementRef, ComponentFactoryResolver, ComponentFactory, ComponentRef, TemplateRef, Type, Inject } from '@angular/core';
import { DynamicHTMLOptions, DynamicDirectOptions } from './options';
import { OnMount } from './interfaces';
import { DOCUMENT } from '@angular/common';
import { MediaLinkDirective } from '../../../directives/media-link/media-link.directive';
import { MediaService } from '../../media/media.service';
import { GlossaryHighlightService } from './glossary-highlight.service';
import { toDataURL } from 'mm-ui';
import { isUndefined } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from '../../../shared/dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { PlatformConfigService } from '../../platform-config/platform-config.service';

export interface DynamicHTMLRef {
  check: () => void;
  destroy: () => void;
}

function isBrowserPlatform() {
  return window != null && window.document != null;
}

export type Content<T> = string | TemplateRef<T> | Type<T>;

@Injectable()
export class DynamicHTMLRenderer {

  private componentFactories = new Map<string, ComponentFactory<any>>();
  private componentRefs = new Map<any, Array<ComponentRef<any>>>();
  iTk: any;
  canDownloadPdf: any;
  blockCopy: any;

  constructor(
    private options: DynamicHTMLOptions,
    private directives: DynamicDirectOptions,
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document,
    private _mediaService: MediaService,
    private _glossaryHighlightService: GlossaryHighlightService,
    private matDialog: MatDialog,
    private _platformService: PlatformConfigService,
  ) {
    this.options.components.forEach(({ selector, component }) => {
      let cf: ComponentFactory<any>;
      cf = this.cfr.resolveComponentFactory(component);
      this.componentFactories.set(selector, cf);
    });
    this._mediaService.getIamToken().subscribe(itk => this.iTk = itk);

    this._platformService.getCanDownloadPdf().subscribe(
      resp => this.canDownloadPdf = resp.canDownloadPdf
    )

    this._platformService.getBlockCopy().subscribe({
      next: resp => this.blockCopy = resp.blockCopy,
      error: err => console.log({ blockCopyError: err })
    });
  }

  renderInnerHTML(elementRef: ElementRef, html: string): DynamicHTMLRef {

    if (!isBrowserPlatform()) {
      return {
        check: () => { },
        destroy: () => { },
      };
    }
    let newHtml = html;
    const componentRefs: Array<ComponentRef<any>> = [];
    this._glossaryHighlightService.getAllTerms().subscribe(terms => {
      //add highlight terms

      var decodeEntities = (function() {
        // this prevents any overhead from creating the object each time
        var element = document.createElement('div');

        function decodeHTMLEntities(str) {
          if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
          }

          return str;
        }

        return decodeHTMLEntities;
      })();


      Object.keys(terms).forEach(term => {
        if (terms[term].definition) {
          let textoNormal = newHtml;
          let textoMinusculo = newHtml.toLowerCase();
          let meutermo = term;
          let tamanhoDaString = meutermo.length;
          let isDot: boolean = false;

          if (/\./.test(meutermo)) {
            isDot = true;
            meutermo = meutermo.replace(/\./g, ";");
            textoMinusculo = textoMinusculo.replace(/\./g, ";");
          }

          if (textoMinusculo.search(meutermo) > -1 || textoMinusculo.normalize("NFD").replace(/[\u0300-\u036f]/g, "").search(meutermo.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1) {

            let termo = textoNormal.slice(textoMinusculo.search(meutermo), textoMinusculo.search(meutermo) + tamanhoDaString);

            if (isDot) {
              textoNormal = textoNormal.replace(/\./g, ";");
              var regexp = new RegExp(`\\b(${termo})\\b(?![^<]*>|[^<>]*<\/mm-highlight)`, 'gi');

              let termoImpressao = termo.replace(/;/g, ".");
              newHtml = newHtml.replace(termo, `<mm-highlight style="display: inline-block" data-id="${terms[term].id}" data-definition="${escape(terms[term].definition)}">${termoImpressao}</mm-highlight>`);
            } else if (checkCharacterIsAccent(termo.toLowerCase(), tamanhoDaString)) {
              var semAcentoTermo = termo.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              var semAcentoTexto = textoNormal.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              var regexp = new RegExp(`\\b(${semAcentoTermo})\\b(?![^<]*>|[^<>]*<\/mm-highlight)`, 'gi');
              var matches_array = semAcentoTexto.match(regexp);

              if (matches_array && matches_array.length > 0) {
                matches_array.forEach(element => {
                  if (element !== "" && isNumber(element) == false) {
                    if (semAcentoTexto.search(regexp) > -1) {
                      let localdoTexto = semAcentoTexto.search(regexp);
                      newHtml = newHtml.substring(0, localdoTexto) + `<mm-highlight style="display: inline-block" data-id="${terms[term].id}" data-definition="${escape(terms[term].definition)}">${termo}</mm-highlight>` + newHtml.substring(localdoTexto + termo.length);
                      semAcentoTexto = semAcentoTexto.substring(0, localdoTexto) + `<mm-highlight style="display: inline-block" data-id="${terms[term].id}" data-definition="${escape(terms[term].definition)}">${termo}</mm-highlight>` + semAcentoTexto.substring(localdoTexto + termo.length);
                    }
                  }
                });
              }
            } else {
              var regexp = new RegExp(`\\b(${termo})\\b(?![^<]*>|[^<>]*<\/mm-highlight)`, 'gi');
              var matches_array = textoNormal.match(regexp);

              if (matches_array && matches_array.length > 0) {
                matches_array.forEach(element => {
                  if (element !== "" && isNumber(element) == false) {
                    newHtml = newHtml.replace(new RegExp(`\\b(${element})\\b(?![^<]*>|[^<>]*<\/mm-highlight)`, 'g'), `<mm-highlight style="display: inline-block" data-id="${terms[term].id}" data-definition="${escape(terms[term].definition)}">${element}</mm-highlight>`);
                  }
                });
              }

            }

          }

        }
      });

      function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

      function checkCharacterIsAccent(termo: string, sizeString: number): boolean | void {
        let character = ['á', 'é', 'í', 'ó', 'ú', 'â', 'ê', 'î', 'ô', 'û', 'à', 'è', 'ì', 'ò', 'ù'];
        let isAccent: boolean;
        character.forEach(element => {
          if (termo.slice(sizeString - 1, sizeString) === element || termo.slice(0, 1) === element) {
            isAccent = true
          }
        });

        if (isAccent) {
          return true;
        }
      }

      function checkAccentCode(html) {
        let character = ['&aacute;', '&Aacute;', '&iacute;', '&Iacute;', '&atilde;', '&Atilde;', '&oacute;', '&Oacute;', '&acirc;', '&Acirc;', '&otilde;', '&Otilde;', '&agrave;', '&Agrave;', '&ocirc;', '&Ocirc;', '&eacute;', '&Eacute;', '&uacute;', '&Uacute;', '&ecirc;', '&Ecirc;', '&ccedil;', '&Ccedil;']
        let isAccent: boolean;

        character.forEach(element => {
          if (html.search(element) > -1) {
            isAccent = true
          }
        });

        if (isAccent) {
          return true;
        }
      }

      elementRef.nativeElement.innerHTML = newHtml;

    }, () => {


    });

    this.options.components.forEach(({ selector }) => {
      //if (selector != 'custom-alert') {

      const elements = (elementRef.nativeElement as Element).querySelectorAll(selector);

      let a = [Array.prototype.slice.call(elements)][0];
      // console.log({a})
      a.forEach.call(elements, (el: Element) => {
        const content = el.innerHTML;
        const ngContent = this.resolveNgContent(content);
        const cmpRef = this.componentFactories.get(selector).create(this.injector, ngContent, el);

        el.removeAttribute('ng-version');

        // console.log({cmpRef})

        if (cmpRef.instance.dynamicOnMount) {

          const attrsMap = new Map<string, string>();
          if (el.hasAttributes()) {
            Array.prototype.forEach.call(el.attributes, (attr: Attr) => {
              attrsMap.set(attr.name, attr.value);
            });
          }
          (cmpRef.instance as OnMount).dynamicOnMount(attrsMap, content, el);
        }

        componentRefs.push(cmpRef);
      });
      //}
    });

    //added mediaLink
    if (elementRef.nativeElement.children.length) {

      const elements = (elementRef.nativeElement as Element).querySelectorAll('a');
      Array.prototype.forEach.call(elements, (el: Element) => {

        if (el.nodeName == 'A') {
          if (el.getAttribute('medialink') && el.getAttribute('medialink') != 'undefined') {
            let mediaId = el.getAttribute('medialink');
            el.id = mediaId;
            el.setAttribute('clickou', 'false');
            el.removeAttribute('href');
            el.addEventListener('click', (event) => this.getMediaOnClickLink(event, mediaId));
          }

          if (el.getAttribute('data-linktype') == 'external') {
            el.setAttribute('target', '_blank')
          }
        }
      });
    }


    this.componentRefs.set(elementRef, componentRefs);

    return {
      check: () => {
        componentRefs.forEach(ref => ref.changeDetectorRef.detectChanges())
      },
      destroy: () => {
        componentRefs.forEach(ref => ref.destroy());
        this.componentRefs.delete(elementRef);
      },
    };
  }

  resolveNgContent<T>(content: Content<T>) {
    // console.log(typeof content)
    if (typeof content === 'string') {
      const element = this.document.createElement('div');
      element.innerHTML = content;
      return [[element]];
    }
  }

  getMediaOnClickLink(event, id) {


    let mediaId = event?.srcElement?.attributes?.medialink?.nodeValue;

    if (!mediaId) {
      mediaId = id;
    }

    let el = event.srcElement;
    this._mediaService.getMediaById(mediaId).subscribe(media => {
      // if (media.is_image || media.extension === 'pdf') {

      let anchorInnerText = el.innerHTML;
      el.innerHTML = '...carregando';

      if (media) {

        this._mediaService.getCDNImage(media.url, this.iTk).subscribe((binarie: any) => {

          let file = toDataURL(binarie);

          if (file) {

            file.then((data: any) => {

              const blob = convertBase64ToBlob(data.result);
              const blobUrl = URL.createObjectURL(blob);

              function convertBase64ToBlob(base64Image: string) {
                const parts = base64Image.split(';base64,');
                const imageType = parts[0].split(':')[1];
                const decodedData = window.atob(parts[1]);
                // so deus sabe pq tava quebrando quando baixa esse mimetype
                const uInt8Array = new Uint8Array(imageType === 'application/vnd.ms-excel.sheet.macroenabled.12' ? decodedData.length - 1 : decodedData.length);

                for (let i = 0; i < decodedData.length; ++i) {
                  uInt8Array[i] = decodedData.charCodeAt(i);
                };

                return new Blob([uInt8Array], { type: imageType });
              };

              el.removeAttribute('href');

              if (media.is_image) {
                window.open(blobUrl, '_blank');
                el.innerHTML = anchorInnerText;
                return;
              }

              if (media.extension === 'pdf') {

                const dialog = this.matDialog.open(PdfViewerDialogComponent, {
                  data: {
                    url: blobUrl,
                    canDownload: this.canDownloadPdf,
                    blockCopy: this.blockCopy ? 0 : 1,
                    fileName: media?.filename
                  },
                  maxHeight: '99vh',
                  minWidth: '80vw',
                })

                el.innerHTML = anchorInnerText;

                if (el.getAttribute('download')) {
                  el.removeAttribute('download')
                }

                return;
              } else {
                el['download'] = media.filename;
                el['href'] = blobUrl;
                el.innerHTML = anchorInnerText;
              }

              if (el.getAttribute('target') != '_blank') {
                el['target'] = '_blank'
              };

              if (el.attributes.clickou.value == "false") {
                el.setAttribute('clickou', 'true');
                document.getElementById(mediaId).click();
              }
            });
          };
        });
      } else {
        console.log('No rendered resource file')
      }
    });
  }

  htmlToElements(html) {
    let template = document.createElement('template');
    template.innerHTML = html;
    return template.content.childNodes;
  }
}
