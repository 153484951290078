import { Component, Input } from "@angular/core";

@Component({
    selector: 'mm-ngx-pagination',
    templateUrl: './ngx-pagination.component.html',
    styleUrls: ['./ngx-pagination.component.scss']
})

export class MMngxPaginationComponent {

    @Input() p: any;
    @Input() items: [];
    @Input() config: {};
    @Input() id: string;

    isMobile = (window.innerWidth < 450) ? true : false;
}