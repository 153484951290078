<div class="dualist-content">
    <div class="row">
        <div class="col-12 col-lg-6">
            <button class="btn btn-primary" [disabled]="!selectedItem" (click)="addItem()">Adicionar Item</button>
            <ul class="list">
                <li (click)="selectItem(item, i)" [ngClass]="[selectedItemIndex == i ? 'selected' : '', 'list-item']" *ngFor="let item of source; let i = index"><p class="mb-0">{{item.label}}</p></li>
            </ul>
        </div>
        <div class="col-12 col-lg-6">
            <button class="btn btn-danger" [disabled]="addedSelectedItemIndex === undefined" (click)="removeItem()">Remover Item</button>
            <ul class="list">
                <li (click)="selectAddedItem(item, i)" [ngClass]="[addedSelectedItemIndex == i ? 'selected' : '', 'list-item']" *ngFor="let item of destination; let i = index"><p class="mb-0">{{item.label}}</p></li>
            </ul>
        </div>
    </div>
</div>