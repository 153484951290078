import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccessDeniedComponent } from "./accessdenied.component";
import { SearchSuggestModule } from "../search-suggest/search-suggest.module";


@NgModule({
    declarations: [
        AccessDeniedComponent
    ],
    imports: [ 
        CommonModule,       
        SearchSuggestModule         
    ],
    providers: [],
    exports: [
        AccessDeniedComponent
      ]
})

export class AccessDeniedModule{}