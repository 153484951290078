import { Component, OnInit, OnChanges, Input } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { BottomOverlapBannerComponent } from '../bottom-overlap-banner/bottom-overlap-banner.component';
import { Utils } from '../../utils/utils';
import { MediaService } from '../../components/media/media.service';

@Component({
  selector: 'app-right-overlap-banner',
  templateUrl: './right-overlap-banner.component.html',
  styleUrls: ['./right-overlap-banner.component.scss']
})
export class RightOverlapBannerComponent extends BottomOverlapBannerComponent {
  mobile: boolean = false;

  constructor(
    public router: Router,
    public utils: Utils,
    public _mediaService: MediaService
    ) {
    super(router, _mediaService, utils);

    if (window.screen.width < 768)
      this.mobile = true
  }

}
