import { Component, OnInit, Optional, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentService } from '../../content.service';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { Page } from 'projects/f-cms/src/app/models/page';
import { skip, take } from 'rxjs/operators';
import { CreatePageService } from '../../create-page/create-page.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ContentMenu } from 'projects/f-cms/src/app/interfaces/contentMenu';

@Component({
  selector: 'app-page-actions-dialog',
  templateUrl: './page-actions-dialog.component.html',
  styleUrls: ['./page-actions-dialog.component.scss']
})
export class PageActionsDialogComponent implements OnInit {

  @Input('picked$') picked$: BehaviorSubject<Page[]> = new BehaviorSubject([])

  type: 'copy' | 'move' | 'import'
  copyHomepage = new UntypedFormControl(null, [Validators.required]);
  itemSelected: ContentMenu;
  maxPages = 1;
  fromPageId: any;
  itemSelectedType: 'HOMEPAGE' | 'PAGE';
  messageStatus: string;
  messageText: string;
  fromPage: Page = {} as Page;
  itemType: 'HOMEPAGE' | 'PAGE';
  isPageSolo: boolean;
  workflowOnGoingPages: any[];
  itemSelectedId: string;


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PageActionsDialogComponent>,
    private contentService: ContentService,
    private pageService: CreatePageService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.type = this.data.type;
    this.fromPageId = this.data.id;
    this.itemType = this.data.itemType;
    this.isPageSolo = !this.data.hasInternalPages;
  }

  subscribeToSelectedPage() {
    this.picked$.pipe(skip(1)).subscribe(pages => this.selectWhereTo(pages))
  }

  selectWhereTo(pages: Page[]): void {
    let item = pages[0];

    if (item) {
      this.itemSelected = item;
      if (item.homePageParent) {
        this.itemSelectedType = 'PAGE';
        this.itemSelectedId = this.itemSelected.pageId;
      } else {
        this.itemSelectedType = 'HOMEPAGE';
        this.itemSelectedId = this.itemSelected.homePageId;
      }
    }
  }

  send() {
    let pageAction$: Observable<any>
    this.messageStatus = undefined;
    this.workflowOnGoingPages = [];

    switch (this.type) {
      case 'move':
        pageAction$ = this.contentService.movePage(this.fromPageId, this.itemSelectedId, this.itemSelectedType)
        break;
      case 'copy':
        pageAction$ = this.itemType == 'HOMEPAGE' ? this.contentService.copyHomepage(this.fromPageId, this.copyHomepage.value) : this.contentService.copyPage(this.fromPageId, this.itemSelectedId, this.itemSelectedType)
        break;
      case 'import':
        pageAction$ = this.contentService.importPage(this.fromPageId, this.itemSelectedId, this.itemSelectedType);
        break;
      default:
        pageAction$ = of(false)
        break;
    }

    pageAction$.subscribe(resp => this.validateResponse(resp), err => this.valError(err))
  }

  validateDisabled() {
    if (this.type === 'copy') {
      return this.itemType == 'HOMEPAGE' ? this.copyHomepage.invalid : false;
    }
  }

  valError(err: any): void {
    if (err.status == 422) {
      if (err.error == "string") {
        this.setMessage('danger', err.error)
      } else if (err.error && err.error.message == 'duplicate title') {
        this.setMessage('danger', `dialogs.pageActions.${this.type}.duplicateError${this.itemType}`)
      } else if (err.error && err.error.workflowOnGoing) {
        this.setMessage('danger', `dialogs.pageActions.${this.type}.workflowOnGoing${this.isPageSolo ? 'Solo' : ''}`);
        this.workflowOnGoingPages = err.error.workflowOnGoing;
      } else if (err.error && err.error.target) {


        if (err.error.target == "this page is already in this homepage")
          this.setMessage('danger', `dialogs.pageActions.pageAlreadyInHomepage`)
        else if (err.error.target == "this page is already in this page")
          this.setMessage('danger', `dialogs.pageActions.pageAlreadyInThisPage`)
        else if (err.error.target == "target cannot be subPage of page")
          this.setMessage('danger', `dialogs.pageActions.pageCannotBeSubpage`)
        else if (err.error.target == "target homepage not found")
          this.setMessage('danger', `dialogs.pageActions.homePageNotFound`)
        else if (err.error.target == "target page not found")
          this.setMessage('danger', `dialogs.pageActions.pageNotFound`)
        else if (err.error.target == "this page is already in the target page's hierarchy")
          this.setMessage('danger', `dialogs.pageActions.pageAlreadyInHierarchy`)
        else
          this.setMessage('danger', `dialogs.pageActions.pageAlreadyInHomepage`)

      }
    } else if (err.status == 400 && !err.error) {
      this.setMessage('danger', `dialogs.pageActions.${this.type}.workflowOnGoing${this.isPageSolo ? 'Solo' : ''}`);
    } else {
      this.setMessage('danger', `dialogs.pageActions.${this.type}.errorSended`)
    }
  }

  showPageList() {
    switch (this.type) {
      case 'move':
        return true
      case 'copy':
        return this.itemType == 'PAGE'
      case 'import':
        return true
      default:
        break;
    }
  }

  validateResponse(resp: any) {
    let summary;

    switch (this.type) {
      case 'move':
        summary = this.translateService.instant(`dialogs.pageActions.${this.type}.actionExecuted`);
        break;
      case 'copy':
        summary = this.translateService.instant(`dialogs.pageActions.${this.type}.${this.itemType}.actionExecuted`);
        break;
      case 'import':
        summary = this.translateService.instant(`dialogs.pageActions.${this.type}.${this.itemType}.actionExecuted`)
      default:
        break;
    }

    let detail = this.itemSelected ? this.itemSelected.title : '';

    this.messageService.add({ severity: 'success', summary, detail })
    this.close();
  }

  setMessage(type: string, message: string): void {
    this.messageStatus = type;
    this.messageText = message;
  }

  close() {
    this.dialogRef.close();
  }
}
