import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSubmitStatusComponent } from './form-submit-status.component';

import { MMIconsModule } from "../mm-icons/mm-icons.module";

@NgModule({
  declarations: [
    FormSubmitStatusComponent
  ],
  imports: [
    CommonModule,
    MMIconsModule
  ],
  providers: [],
  exports: [
    FormSubmitStatusComponent
  ]
})
export class FormSubmitStatusModule { }
