import { Component, Input } from "@angular/core";

@Component({
    selector: 'mm-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

    @Input('class')
    show: boolean = true;    
    @Input('type') type: string;
    @Input('text') text: string;
    @Input('strongtext') strongtext: string;

    toggleAlert(){

        this.show = !this.show
    }

}