import { Component, OnInit, Input, SimpleChanges, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { trigger, transition, state, animate, style } from '@angular/animations';
import * as uuid from 'uuid';
import { MediaService } from '../../components/media/media.service';
import { toDataURL } from 'mm-ui';
import { first } from 'rxjs';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'mm-poster',
  templateUrl: './mm-poster.component.html',
  styleUrls: ['./mm-poster.component.scss'],
  animations: [
    trigger('closeThisPoster', [
      state('open', style({
        width: '100%',
        opacity: 1
      })),
      state('closed', style({
        display: 'none'
      })),
      transition('open => closed', [
        animate('0.3s ease-in-out')
      ]),
      transition('closed => open', [
        animate('0.3s ease-in')
      ]),
    ]),

    trigger('minimizeThisPost', [
      state('maximize', style({
        width: '100%',
        opacity: 1
      })),
      state('minimize', style({
        height: '50px',
        borderRadius: '5px'
      })),
      transition('maximize => minimize', [
        animate('0.3s ease-in-out')
      ]),
      transition('minimize => maximize', [
        animate('0.3s ease-in')
      ]),
    ]),
  ],
})
export class MmPosterComponent implements OnInit, AfterViewInit {

  @Input('posters') posters: any
  @Input('widgetSetting') widgetSetting: any

  isOpen = true
  isMinimize = false
  uniqId = uuid.v4();
  active = 0;
  canClose: any = true
  canMinimize: any = true

  carouselBanner: any;
  slideNo = 0;
  withAnim = true;
  resetAnim = true;
  forms: UntypedFormArray;

  @ViewChild('bannerView', { static: true }) bannerView: any;
  @ViewChild('myCarousel') myCarousel: any;
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    interval: { timing: 2000, initialDelay: 5000 },
    loop: true,
    touch: true,
    velocity: 0.2
  }
  carouselItems = [];

  tratedPosters: any[] = [];
  iTk: any;

  constructor(private _mediaService: MediaService, private cdr: ChangeDetectorRef, private fb: UntypedFormBuilder) { }

  ngOnInit() {

    this._mediaService.getIamToken().subscribe(itk => this.iTk = itk);

    this.posters.forEach(poster => {
      if (poster.posterImage.mediaUrl) {
        this._mediaService.getCDNImage(poster?.posterImage.mediaUrl, this.iTk).subscribe({
          next: (binarie: any) => {
            let file = toDataURL(binarie)
            if (file) {
              file.then((value: any) => {
                poster.posterImage.mediaUrl = value.result
                this.tratedPosters.push(poster)
                this.getPosters()
              })
            }
          },
          error: (err) => console.log({ midia_error: err })
        })
      } else {
        this.tratedPosters.push(poster)
        this.getPosters()
      }
    })

    // console.log(this.tratedPosters)

    this.updateCanClose();
    // console.log(this.posters)

  }
  getPosters() {
    if (this.carouselItems.length > 0) {
      this.carouselConfig.interval.timing = this.carouselItems[0].timeToRead * 1000;
    }
    this.carouselItems = this.tratedPosters.filter(banner => banner.active).map((banner, i) => i + 1)
    this.forms = this.fb.array(this.tratedPosters.filter(banner => banner.active).sort((bannerA, bannerB) => bannerA.listOrder - bannerB.listOrder).map(banner => this.fb.group(banner)))
  }
  updateCanClose() {
    if (this.widgetSetting && this.widgetSetting.length) {
      this.canClose = this.widgetSetting[0].canClose;
      this.canMinimize = this.widgetSetting[0].canMinimize;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.widgetSetting) {
      this.updateCanClose();
    }
  }

  closePoster() {
    this.isOpen = !this.isOpen
  }

  goPrev() {

    if (this.active == 0) {
      this.active = this.posters.length - 1;
    } else {
      this.active--;
    }
  }

  getUrl(mediaId) {
    return mediaId
    //   // if(typeof url == 'string'){
    //   //   return url
    //   // } else {
    //   //   return url.mediaUrl
    //   // }
    // }
  }

  goNext() {

    if (this.active == this.posters.length - 1) {
      this.active = 0;
    } else {
      this.active++;
    }

  }

  minimizePoster() {
    this.isMinimize = !this.isMinimize
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  /* It will be triggered on every slide*/
  onmoveFn(data: any) {
    console.log(data);
  }

  trackCarousel(_, item) {
    return item;
  }

  getPosterImage(i): UntypedFormArray {
    return this.forms.get([i]) as UntypedFormArray
  }

}
