<div class="content-page-list">
  <div class="row">
    <div [ngClass]="['col-12', showSelected ? 'col-lg-6' : '']">
      <p> {{ 'content.createPage.sPageBelow' | translate }}:</p>
      <div class="contents-select">
        <app-page-list
          [pickedItemAttribute]="pickedItemAttribute"
          [pickedCheckedAttribute]="pickedCheckedAttribute"
          [maxPages]="maxPages"
          [showCheck]="showCheck"
          [picked$]="picked$"
          [selectHomepage]="true"
          [canSearch]="true"
          [onlyHomePage]="onlyHomePage">
        </app-page-list>
      </div>
    </div>
    <div class="col-12 col-lg-6 selects" *ngIf="showSelected">
      <p *ngIf="maxPages > 1; else onePage"><span>{{ 'content.createPage.sPages' | translate }}:</span></p>
      <ng-template #onePage>
        <p><span>{{ 'content.createPage.sPage' | translate }}:</span></p>
      </ng-template>
      <div class="contents-select">
        <app-page-picker [picked$]="picked$"></app-page-picker>
      </div>
    </div>
  </div>
</div>
