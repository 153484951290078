import { Directive, Input, ElementRef } from '@angular/core';
import { MediaService } from '../../components/media/media.service';

@Directive({
  selector: '[medialink]'
})
export class MediaLinkDirective {

  @Input('medialink') mediaId: string

  constructor(
    private _mediaService: MediaService,
    private _el: ElementRef
  ) { }

  ngOnInit(): void {
    if(this.mediaId){
      this._mediaService.getMediaById(this.mediaId).subscribe(media => {
        this._el.nativeElement.href = media.url_download
      })
    }
  }

}
