import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CropperComponent } from './cropper.component';
import { MMIconsModule } from '../mm-icons/mm-icons.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MMIconsModule
    ],
    declarations: [
        CropperComponent
    ],
    exports: [
        CropperComponent
    ],
    providers: []
})

export class CropperModule {}
