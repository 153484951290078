import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mm-toast',
  templateUrl: './mm-toast.component.html',
  styleUrls: ['./mm-toast.component.scss']
})
export class MmToastComponent implements OnInit {

  @Input('config') config: {
    title: string,
    position: 'top' | 'bottom'
    side: 'left' | 'right',
    noMargin: false,
    buttons: {
      title: string;
      type: string;
      handler: any;
    }[]
  }

  constructor() { }

  ngOnInit() {
  }

}
