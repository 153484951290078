import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mm-menu-templates',
  templateUrl: './menu-templates.component.html',
  styleUrls: ['./menu-templates.component.scss']
})
export class MenuTemplatesComponent implements OnInit {

  currentTemplate: string
  currentTemplateType: string
  dimension = {
    width: null,
    height: null
  }

  constructor() { }

  ngOnInit() {
  }

  displaysCurrentTemplates(event, item) {
    this.currentTemplate = item
  }

  selectTemplateType(value: string) {
    this.currentTemplateType = value
    this.setDimension(value)
    // this.form.patchValue({template: value})
  }
  
  setDimension(templateType: string) {
   
  }

}
