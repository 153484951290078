import { Component, Input } from "@angular/core";

@Component({
    selector: 'mm-spinner',
    templateUrl: 'spinner.component.html',
    styleUrls: ['spinner.component.scss']
})

export class SpinnerComponent {
    constructor(){
        
    }
}