import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'mmp-dropbox',
  templateUrl: './dropbox.component.html',
  styleUrls: ['./dropbox.component.scss']
})

export class DropboxComponent {

  @Input('colors') colors: any[];
  @Input('editProfile') userId: string;
  @Input('selectedId') selectedId: string;

  @Output('selected') selected = new EventEmitter();
  @Output('logout') logout = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  signout() {
    this.selected.emit('');
    this.logout.emit(true)
    localStorage.removeItem('accessToken');
  }

  select(id: string) {
    this.selectedId = id;
    this.selected.emit(id);
  }

  getValidColors() {
    let colors = this.colors.filter(color => {
      if (color.expirationTime === 'SET_COLOR_RANGE_VALIDITY') {
        const initialDateTime = new Date(`${color.initialDate} ${color.initialHour}`);
        const finalDateTime = new Date(`${color.finalDate} ${color.finalHour}`);
        const now = new Date();

        if (now > initialDateTime && now < finalDateTime) {
          return true;
        } else {
          return false;
        }
      }
      return true
    })
    return colors
  }

  gotoEdit() {
    this.router.navigate(['/hp', 'edit-profile', 'me'])
  }
}
