import { ErrorHandler, Injectable, Injector } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }

  handleError(error: any): void {
   const chunkFailedMessage = /Loading chunk .+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      caches.keys().then(
          async function(names){
              await Promise.all(names.map(name => caches.delete(name)))
              window.location.reload();
          }
      )
    } else if (error.stack) {
      console.error(error.stack);
    } else {
      console.error(error)
    }
  }
}
