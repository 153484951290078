import { Component, Input, OnInit } from "@angular/core";
import { ViewService } from "../../../view.service";


@Component({
  selector: "html-plus-tabs",
  templateUrl: "./html-plus-tabs.component.html",
  styleUrls: ["./html-plus-tabs.component.scss"]
})
export class HtmlPlusTabsComponent implements OnInit {

  @Input("content") content: any;
  @Input("child") child = false;
  @Input("not-a-feature") notAFeature = false;

  openTabs = {};
  downloading = false;
  blockCopy: any;

  constructor(
    private viewService: ViewService,
  ) { }

  ngOnInit() {
    this.viewService.getBlockCopyOnView().subscribe({
      next: resp => this.blockCopy = resp.blockCopy,
      error: err => console.log({ blockCopyError: err })
    });

    if (!this.notAFeature) {
      this.getTabsContent(this.content, this.content.contents[0]);
    }
  }

  getTabsContent(content, tabs): void {

    //  content.contents.forEach(tab => {
    //    if (tab.id == tabs.id) {
    //      // tab.htmlContentDynamic = this.loading
    //      this.downloading = true;
    //      this.viewService.htmlContent(tab.id).subscribe(resp => {
    //        this.downloading = false;
    //        tab.htmlContentDynamic = resp;
    //        tab.htmlContent = resp;
    //        content.contents.forEach(element => {

    //          if (element.contentType == 'TAB' || element.contentType == 'TABS') {
    //            this.getTabsContent(element, element.contents[0]);
    //          } else if (element.contentType == 'HTML') {
    //            this.getHtmlContent(element, element.id);
    //          }

    //        });
    //      }, err => {
    //        this.downloading = false;
    //      });
    //    }
    //  });

  }

  getHtmlContent(content, id): void {
    // this.viewService.htmlContent(id).subscribe(resp => {
    //   content.htmlContent = (resp) ? resp : "";
    // });
  }

}
