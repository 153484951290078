import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
// import { MediaShortView } from 'projects/f-cms/src/app/models/Media';
import { NguCarousel, NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
import { fromEvent, Subscription } from 'rxjs';
import { tap, takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'mm-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('medias') medias: any[]
  @Input('position') position: string = 'CENTER';
  @Input('size') size: string = 'BIG'
  @Input('startSlide') startSlide: number;
  @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
  @ViewChild('myCarouselTile') myCarouselTile: NguCarousel<any>;
  @Output('clickedImage') clickedImage = new EventEmitter<number>();
  @Output('closed') closed = new EventEmitter();
  @Input('showTitle') showTitle: boolean = false;
  @Input('showDesc') showDesc: boolean = false;
  @Input('changeImageUsingArrows') changeImageUsingArrows: boolean = false;
  @Input('showControls') showControls: boolean = false;
  @Input('iamTk') iTk:string

  @Input('carouselConfig') carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    interval: {timing: 10000, initialDelay: 5000},
    loop: true,
    load: 1,
    touch: true,
    velocity: 0.2
  }

  @Input ('carouselTileConfig') carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 2, sm: 3, md: 4, lg: 4, all: 0 },
    interval: {timing: 500000, initialDelay: 500000},
    loop: false,
    touch: true,
    velocity: 0.2
  }

  carouselItems = [];
  carouselToken;
  galleryControl;
  tilesControl;
  arrowsKeys$: Subscription;
  mdSrvc: string;

  constructor() { }

  ngOnInit() {
    if(!!localStorage.getItem('mdService')){
      this.mdSrvc = localStorage.getItem('mdService')
    }

    this.configureCarrousel();
    this.keyboardListinerChangeArrows()
  }
  keyboardListinerChangeArrows() {
    if(this.changeImageUsingArrows && !this.arrowsKeys$) {
      this.arrowsKeys$ =
        fromEvent(document, 'keydown')
        .pipe(filter((e: KeyboardEvent) => e.code === 'ArrowLeft' || e.code === 'ArrowRight' || e.code === 'Escape'))
        .subscribe(e => {
          let currentSlide = this.myCarousel.currentSlide;
          if(e.code === 'ArrowLeft'){
            if(currentSlide == 0){
              this.setImageOnCarousel(this.carouselItems.length);
            }else {
              this.setImageOnCarousel(currentSlide);
            }
          }
          if(e.code === 'ArrowRight'){
            if(currentSlide + 1 == this.carouselItems.length){
              this.myCarousel.moveTo(0);
            }else {
              this.myCarousel.moveTo(currentSlide +1);
            }
          }
          if(e.code === 'Escape'){
            this.arrowsKeys$.unsubscribe();
            this.closed.emit(true)
          }
        })
    }
  }

  initDataFn(key: NguCarouselStore){
    this.carouselToken = key.token;
  }

  click(item){
    this.clickedImage.emit(item)
  }

  setTileCarouselPosition(e: NguCarousel<any>){
    let item = this.myCarousel.currentSlide + 1;
    let items = this.myCarouselTile.items;

    let position = Math.ceil(item / items);

    this.myCarouselTile.moveTo(position - 1);
  }

  public carouselTiles: any = {};

  public carouselTileLoad(j) {

    let url = this.medias[j-1] ? this.medias[j-1].mediaUrl : '';
    this.carouselTiles[j].push(url);
  }

  configureCarrousel() {
    this.carouselItems = this.medias.map((media, i) => i+1);
    this.carouselItems.forEach(item => {
      this.carouselTiles[item] = [];
      this.carouselTileLoad(item)
    })
  }

  ngAfterViewInit(){
    this.initWithSlide();
  }

  initWithSlide(){
    if(this.startSlide){
      this.setImageOnCarousel(this.startSlide)
    }
  }

  setImageOnCarousel(item) {
    this.myCarousel.moveTo(item-1);
  }

  ngOnDestroy(): void {
    if(this.arrowsKeys$ && !this.arrowsKeys$.closed){
      this.arrowsKeys$.unsubscribe();
    }
  }
}
