import { Inject, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { combineLatestWith } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

// @dynamic
@Injectable({ providedIn: 'root' })
export class AppConfig {
  public static data;
  public static git;
  constructor(private http: HttpClient) {
  }
  public load() {
    let headers = new HttpHeaders().set('skipToken', 'true');

    let versionate = lastValueFrom(
      this.http.get('assets/git-version.json', { headers })
        .pipe(combineLatestWith(this.http.get('assets/config.' + environment.endpoint + '.json', { headers })))
    ).then((version) => { console.log({ version }); AppConfig.data = Object.assign({}, version[0], version[1]) })

    return versionate

  }
}
