import { NgModule, ModuleWithProviders } from "@angular/core";
import { MessageService } from "primeng/api";

@NgModule({})
export class AppToastModule{
    static forRoot(): ModuleWithProviders<AppToastModule>{
        return {
            ngModule: AppToastModule,
            providers: [MessageService]
        }
    }
}