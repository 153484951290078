import { Component, Input } from "@angular/core";

@Component({
    selector: 'mm-loading-spinner',
    templateUrl: 'loading-spinner.component.html',
    styleUrls: ['loading-spinner.component.scss']
})

export class LoadingSpinnerComponent {
    @Input('loading') loading: boolean;

    constructor(){
        
    }
}