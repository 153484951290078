<div class="ibox-style" >           
    <img src="{{imgurl}}" alt="image box" class="mediasize img-fluid img-thumbnail" />             
</div>      
<div class="iconbox-text mx-2">
    <p> {{message}} </p>          
</div>







