import { Component, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MediaService } from "../../../components/media/media.service";
import { Utils } from "../../../utils/utils";


@Component({
  selector: 'mmp-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss']
})
export class PdfViewerDialogComponent {

  url: string;
  canDownload: boolean;
  blockCopy:any;
  iTk: string;
  mdSrvc: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    private mediarService: MediaService,
    private utils: Utils
  ) { }


  ngOnInit() {

    this.url = this.data.url;
    this.canDownload = this.data.canDownload;
    this.blockCopy = this.data.blockCopy;

    if (this.utils.hasStorage('mdService')) {
      this.mdSrvc = this.utils.getStorage('mdService')
    }

    this.mediarService.getIamToken().subscribe(itk => {
      if (itk) {
        this.iTk = itk;
      }
    });
  }


  close() {
    this.dialogRef.close();
  }
}
