<div class="pdf-viewer">
  <mmp5-mm-pdf-viewer
    *ngIf="url"
    [show-footer]="true"
    [can-download]="canDownload"
    [block-copy]="blockCopy"
    [file-name]="data?.fileName"
    [url]="url">
  </mmp5-mm-pdf-viewer>
</div>
