import { Component, OnInit, ElementRef, ViewChild, InjectionToken, Injector, ViewContainerRef, Renderer2, HostListener } from '@angular/core';
import { Overlay, OverlayContainer, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { DomPortal, ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { HighlightDataComponent } from './highlight-data/highlight-data.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
export const PORTAL_DATA = new InjectionToken<{}>('PortalData');
@Component({
  selector: 'mm-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss']
})
export class HighlightComponent implements OnInit {

  definition: any;
  id: any;
  show = false;

  oldX = 0;
  oldY = 0;

  @ViewChild('highlight', { static: false }) highlight: ElementRef;
  @ViewChild('content', { static: false }) content: ElementRef;
  overlayRef: OverlayRef;
  portal: ComponentPortal<HighlightDataComponent>;
  validateDestroy$ = new Subject();
  onTerm: boolean;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private _injector: Injector,
    private vcr: ViewContainerRef,
    private renderer: Renderer2
  ) {

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.overlayRef) {
        this.removeOverlay();
      }
    });

  }

  ngOnInit(): void {
    this.id = this.elementRef.nativeElement.dataset.id;
    this.definition = unescape(this.elementRef.nativeElement.dataset.definition);
    this.validateDestroy$.pipe(debounceTime(200)).subscribe(event => this.validateDestroy(event));

  }

  createOverlay() {
    this.onTerm = true;
    if (!this.overlayRef || !this.overlayRef.hasAttached()) {
      let config = new OverlayConfig({
        /* .flexibleConnectedTo(this.highlight) */
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.highlight)
          .withPositions([{
            overlayX: "center",
            overlayY: "bottom",
            originX: "center",
            originY: "top"
          }, {
            originX: "center",
            originY: "bottom",
            overlayX: "center",
            overlayY: "top"
          }])
          .withDefaultOffsetY(this.getOffset())
      });

      this.overlayRef = this.overlay.create(config);
      this.portal = new ComponentPortal(HighlightDataComponent, this.vcr, this.createInjector({ id: this.id, definition: this.definition }));
      let componentRef = this.overlayRef.attach(this.portal);
      if (componentRef) {
        componentRef.instance.definition = this.definition;
        componentRef.instance.id = this.id;
        componentRef.instance.leave.subscribe(() => this.removeOverlay());
      }
    }
  }

  getOffset(): number {
    if (this.content && this.content.nativeElement && this.content.nativeElement.clientHeight) {
      return this.content.nativeElement.clientHeight * -1;
    } else {
      return -30;
    }
  }

  validateDestroy(directions) {
    //only remove if mouse don't go to highlight
    if (directions[1] != 'up' && !this.onTerm) {
      this.removeOverlay();
    }
  }

  updateSub(event) {
    let direction = this.getMouseDirection(event);
    this.validateDestroy$.next(direction);
  }

  getMouseDirection(e) {
    let xDirection = "";
    let yDirection = "";

    //deal with the horizontal case
    if (this.oldX == e.pageX) {
      xDirection = 'middle';
    } else if (this.oldX < e.pageX) {
      xDirection = "right";
    } else {
      xDirection = "left";
    }

    //deal with the vertical case
    if (this.oldY == e.pageY) {
      yDirection = 'middle';
    } else if (this.oldY < e.pageY) {
      yDirection = "down";
    } else {
      yDirection = "up";
    }

    this.oldX = e.pageX;
    this.oldY = e.pageY;

    return [xDirection, yDirection];
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (this.overlayRef) {
      this.removeOverlay();
    }
  }

  removeOverlay() {
    this.overlayRef.detach();
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap<any, any>([
      [PORTAL_DATA, dataToPass]
    ]);

    return new PortalInjector(this._injector, injectorTokens);
  }
}
