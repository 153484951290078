<div class="mmp-nav-error-margin">
  <div *ngIf="status == '404'">
    <div class="error">
      <div class="container">
        <mm-alert *ngIf="status != '404'" [type]="messageStatus" [text]="messageText | translate">
        </mm-alert>
        <div class="col-12 ms-3 justify-content-center text-center page-text">
          <p class="col-lg-8 text-spacement--interjection mt-1 ms-3">{{'errorpage.interjection' | translate}} </p>
          <p class="col-lg-8 text-spacement--advice ms-3"> {{'errorpage.desc' | translate}} </p>
          <p class="col-lg-8 text-spacement--question ms-3"> {{'errorpage.question' | translate}} </p>
          <p class="col-lg-8 text-spacement--tip ms-3"> {{'errorpage.tip' | translate}} </p>
        </div>
        <div class="row justify-content-end">
          <div class="col-12 ">
            <img src="../assets/images/404garotaballon.png" alt="404 Error!"
              class="img-404--girlatbalon me-3 float-right col-lg-4 col-md-6" />
          </div>
          <div class="col-12 text-center">
            <img src="../assets/images/404nuvensMaiores_montanhas.png" alt="404 Error!"
              class="fixed-bottom img-404--mountain col-lg-12" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="status == '403'">
    <div class="container page403">
      <mmp5-accessdenied [title]="'accessdenied.interjection' | translate" [message]="'accessdenied.desc' | translate"
        [imgurl]="imgurl[0]" [imgclass]="imgclass[0]">
      </mmp5-accessdenied>
    </div>
  </div>
  <div *ngIf="status == 'outrange'">
    <div class="container page403">
      <mmp5-accessdenied [title]="'accessdenied.interjection' | translate" [subMessage]="'accessdenied.outrange' | translate" [message]="'accessdenied.ip' | translate"
        [imgurl]="imgurl[0]" [imgclass]="imgclass[0]">
      </mmp5-accessdenied>
    </div>
  </div>
  <div *ngIf="status == '500'">
    <mm-alert [type]="'danger mt-5 ms-5 me-2'" [text]="messageText | translate"></mm-alert>
  </div>
</div>
