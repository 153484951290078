import { Action, ActionReducer, INIT, MetaReducer, UPDATE } from '@ngrx/store'
import { merge } from 'lodash';
import { UserInterface } from "../../../interfaces/userInterface";
import * as UsersActions from "../actions/users.actions"

export function cmsSync(reducer: ActionReducer<UserInterface>) {
  return (state, action) => {
    let reducedState: UserInterface = reducer(state, action);
    if (action.type === INIT) {
      const data = localStorage.getItem('cms');
      if (data) {
        let parsed = JSON.parse(data)
        reducedState = merge(reducedState, parsed)
      };
    } else if (action.type !== UPDATE) {
      localStorage.setItem(
        'cms',
        JSON.stringify(reducedState)
      );
    }
    return reducedState;
  };
}

export function reducerUser(state: UserInterface, action: UsersActions.Actions) {
  switch (action.type) {
    case UsersActions.SET_USER:
      return Object.assign({}, state, action.payload);
    case UsersActions.RESET_FORM:
      return {};
    default:
      return state;
  }
}
