import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges } from "@angular/core";
// import { ZoomServiceController } from "./zoom-image.service";

@Component({
    selector: 'mm-zoom-image',
    templateUrl: './zoom-image.component.html',
    styleUrls: ['./zoom-image.component.scss']
})

export class ZoomImageComponent implements OnInit {

    @Input ('active') active: boolean = false;
    @Input ('content') content: boolean = false;
    @Output('closed') closed = new EventEmitter()
    imageUrl: string
    pdfUrl: any;
    page: number = 1;
    media: any;
    mdSrvc: string;
    iTk: any;

    constructor(
        // private zoomServiceController: ZoomServiceController,
    ) { }

    ngOnInit(){
      if(!!localStorage.getItem('mdService')){
        this.mdSrvc = localStorage.getItem('mdService')
      }
    }

    downloadMedia(){
        window.location.href = this.media.base64_complete
    }

    close(){
        // this.zoomServiceController.close();
        this.closed.emit(true);
        this.active = false;
    }

}
