<div *ngIf="show" [class]="'alert alert-' +type">
  <span>
    <strong *ngIf="strongtext">{{strongtext}}</strong>
    <span>{{text}}</span>
  </span>
  <button class="btn btn-as-link" (click)="toggleAlert()">
    <mmp5-mm-icons [icon]="['fa-solid','fa-xmark']">
    </mmp5-mm-icons>
  </button>
</div>
