<div [ngClass]="{'bd-example': true, 'widget__preview': true, 'margin-buttom': posters.length > 0}" [@closeThisPoster]="isOpen ? 'open' : 'closed'" *ngIf="uniqId">

  <div id="carousel__captions-{{uniqId}}" class="carousel slide" data-bs-ride="carousel">
    <!-- widget control -->
    <div class="poster__action mt-2 d-flex flex-row justify-content-end">
      <div class="action">
        <mmp5-mm-icons *ngIf="canMinimize" (click)="minimizePoster()" class="action__icon minimize" [icon]="isMinimize ? ['icon','icon-maximizar'] : ['icon','icon-minimizar']"></mmp5-mm-icons>
        <mmp5-mm-icons *ngIf="canClose" (click)="closePoster()" class="action__icon" [icon]="['icon','icon-fechar']"></mmp5-mm-icons>
      </div>
    </div>
    <!-- poster -->

    <div #bannerView class="banner__carousel">
      <ngu-carousel #myCarousel [inputs]="carouselConfig" (carouselLoad)="onLoad()" [dataSource]="carouselItems" *ngIf="forms && carouselItems.length > 1" [@minimizeThisPost]="isMinimize ? 'minimize' : 'maximize'">

        <div *nguCarouselDef="let item;" class="item">
          <div *ngIf="forms.get([item-1]) as form">

            <img
              [src]="form.controls.base64.value ? form.controls.base64.value : form.controls.posterImage.value.mediaUrl"
              [ngClass]="['d-block', 'w-100', form.controls.displayDescription.value || form.controls.displayTitle.value || form.controls.clickArea.value == 'BUTTON' ? 'filter' : '']"
              alt="..."
            >

            <a [href]="form.controls.targeting.value" target="{{form.controls.targetingType.value === 'EXTERNAL_LINK' ? '_blank' : '_self'}}" *ngIf="form.controls.clickArea.value === 'FULL_IMAGE' && form.controls.targetingType.value !== 'INTERNAL_LINK'">
              <div class="carousel-caption" *ngIf="!isMinimize">
                <h4 *ngIf="form.controls.displayTitle.value">{{form.controls.title.value}}</h4>
                <p *ngIf="form.controls.displayDescription.value" [ngClass]="{'handle_cap': (form.controls.clickArea.value == 'FULL_IMAGE' || form.controls.clickArea.value == 'NO_CLICK_AREA' || form.controls.clickArea.value == null)}">{{form.controls.description.value}}</p>
                <div class="d-flex justify-content-center">
                  <a class="btn btn-primary mt-1" target="{{form.controls.targetingType.value === 'EXTERNAL_LINK' ? '_blank' : '_self'}}" [href]="form.controls.targeting.value" *ngIf="form.controls.clickArea.value == 'BUTTON'">
                    <span style="text-decoration: none; color: #ffffff">{{form.controls.labelButton.value}}</span>
                  </a>
                </div>
              </div>
            </a>

            <a [routerLink]="form.controls.targeting.value" *ngIf="form.controls.clickArea.value === 'FULL_IMAGE' && form.controls.targetingType.value === 'INTERNAL_LINK'">
              <div class="carousel-caption" *ngIf="!isMinimize">
                <h4 *ngIf="form.controls.displayTitle.value">{{form.controls.title.value}}</h4>
                <p *ngIf="form.controls.displayDescription.value" [ngClass]="{'handle_cap': (form.controls.clickArea.value == 'FULL_IMAGE' || form.controls.clickArea.value == 'NO_CLICK_AREA' || form.controls.clickArea.value == null)}">{{form.controls.description.value}}</p>
                <div class="d-flex justify-content-center">
                  <a [routerLink]="form.controls.targeting.value" *ngIf="form.controls.clickArea.value == 'BUTTON'" class="btn btn-primary mt-1">
                    <span style="text-decoration: none; color: #ffffff">{{form.controls.labelButton.value}}</span>
                  </a>
                </div>
              </div>
            </a>

            <div class="carousel-caption" *ngIf="!isMinimize && (form.controls.clickArea.value === 'BUTTON' || form.controls.targetingType.value === 'POSTER_WITHOUT_LINK')">
              <h4 *ngIf="form.controls.displayTitle.value">{{form.controls.title.value}}</h4>
              <p *ngIf="form.controls.displayDescription.value" [ngClass]="{'handle_cap': (form.controls.clickArea.value == 'FULL_IMAGE' || form.controls.clickArea.value == 'NO_CLICK_AREA' || form.controls.clickArea.value == null)}">{{form.controls.description.value}}</p>
              <div class="d-flex justify-content-center">

                <a *ngIf="form.controls.targetingType.value === 'EXTERNAL_LINK' && form.controls.clickArea.value == 'BUTTON'" class="btn btn-primary mt-1" target="{{form.controls.targetingType.value === 'EXTERNAL_LINK' ? '_blank' : '_self'}}" [href]="form.controls.targeting.value">
                  <span style="text-decoration: none; color: #ffffff">{{form.controls.labelButton.value}}</span>
                </a>

                <a *ngIf="form.controls.targetingType.value === 'INTERNAL_LINK' && form.controls.clickArea.value == 'BUTTON'" class="btn btn-primary mt-1" [routerLink]="form.controls.targeting.value">
                  <span style="text-decoration: none; color: #ffffff">{{form.controls.labelButton.value}}</span>
                </a>
              </div>
            </div>

          </div>
        </div>

        <button NguCarouselNext class="btn btn-secondary rightRs" *ngIf="carouselItems.length > 1">
          <mmp5-mm-icons [icon]="['icon','icon-banner-proximo']"></mmp5-mm-icons>
        </button>
        <button NguCarouselPrev class="btn btn-secondary leftRs" *ngIf="carouselItems.length > 1">
          <mmp5-mm-icons [icon]="['icon','icon-banner-anterior']" style="float: right"></mmp5-mm-icons>
        </button>

      </ngu-carousel>

      <div *ngIf="(carouselItems.length == 1) && forms.get([0]) as form">
        <div class="item item-unico-cartaz" [@minimizeThisPost]="isMinimize ? 'minimize' : 'maximize'">
          <div>

            <img
              [src]="form.controls.base64?.value ? form.controls.base64?.value : form.controls.posterImage.value?.mediaUrl"
              [ngClass]="['d-block', 'w-100', form.controls.displayDescription.value || form.controls.displayTitle.value || form.controls.clickArea.value == 'BUTTON' ? 'filter' : '']"
              alt="..."
            >

            <a [href]="form.controls.targeting.value" target="{{form.controls.targetingType.value === 'EXTERNAL_LINK' ? '_blank' : '_self'}}" *ngIf="form.controls.clickArea.value === 'FULL_IMAGE' && form.controls.targetingType.value !== 'INTERNAL_LINK'">
              <div class="carousel-caption" *ngIf="!isMinimize">
                <h4 *ngIf="form.controls.displayTitle.value">{{form.controls.title.value}}</h4>
                <p *ngIf="form.controls.displayDescription.value" [ngClass]="{'handle_cap': (form.controls.clickArea.value == 'FULL_IMAGE' || form.controls.clickArea.value == 'NO_CLICK_AREA' || form.controls.clickArea.value == null)}">{{form.controls.description.value}}</p>
                <div class="d-flex justify-content-center">
                  <a class="btn btn-primary mt-1" target="{{form.controls.targetingType.value === 'EXTERNAL_LINK' ? '_blank' : '_self'}}" [href]="form.controls.targeting.value" *ngIf="form.controls.clickArea.value == 'BUTTON'">
                    <span style="text-decoration: none; color: #ffffff">{{form.controls.labelButton.value}}</span>
                  </a>
                </div>
              </div>
            </a>

            <a [routerLink]="form.controls.targeting.value" *ngIf="form.controls.clickArea.value === 'FULL_IMAGE' && form.controls.targetingType.value === 'INTERNAL_LINK'">
              <div class="carousel-caption" *ngIf="!isMinimize">
                <h4 *ngIf="form.controls.displayTitle.value">{{form.controls.title.value}}</h4>
                <p *ngIf="form.controls.displayDescription.value" [ngClass]="{'handle_cap': (form.controls.clickArea.value == 'FULL_IMAGE' || form.controls.clickArea.value == 'NO_CLICK_AREA' || form.controls.clickArea.value == null)}">{{form.controls.description.value}}</p>
                <div class="d-flex justify-content-center">
                  <a [routerLink]="form.controls.targeting.value" *ngIf="form.controls.clickArea.value == 'BUTTON'" class="btn btn-primary mt-1">
                    <span style="text-decoration: none; color: #ffffff">{{form.controls.labelButton.value}}</span>
                  </a>
                </div>
              </div>
            </a>

            <div class="carousel-caption" *ngIf="!isMinimize && (form.controls.clickArea.value === 'BUTTON' || form.controls.targetingType.value === 'POSTER_WITHOUT_LINK')">
              <h4 *ngIf="form.controls.displayTitle.value">{{form.controls.title.value}}</h4>
              <p *ngIf="form.controls.displayDescription.value" [ngClass]="{'handle_cap': (form.controls.clickArea.value == 'FULL_IMAGE' || form.controls.clickArea.value == 'NO_CLICK_AREA' || form.controls.clickArea.value == null)}">{{form.controls.description.value}}</p>
              <div class="d-flex justify-content-center">

                <a *ngIf="form.controls.targetingType.value === 'EXTERNAL_LINK' && form.controls.clickArea.value == 'BUTTON'" class="btn btn-primary mt-1" target="{{form.controls.targetingType.value === 'EXTERNAL_LINK' ? '_blank' : '_self'}}" [href]="form.controls.targeting.value">
                  <span style="text-decoration: none; color: #ffffff">{{form.controls.labelButton.value}}</span>
                </a>

                <a *ngIf="form.controls.targetingType.value === 'INTERNAL_LINK' && form.controls.clickArea.value == 'BUTTON'" class="btn btn-primary mt-1" [routerLink]="form.controls.targeting.value">
                  <span style="text-decoration: none; color: #ffffff">{{form.controls.labelButton.value}}</span>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- <div class="carousel-inner" [@minimizeThisPost]="isMinimize ? 'minimize' : 'maximize'">
      <div  *ngFor="let poster of tratedPosters; index as i" [ngClass]="{'carousel-item':true, 'active' : (active == i)}">

        <img
          [src]="poster?.base64 ? poster.base64 : poster?.posterImage.mediaUrl"
          [ngClass]="['d-block', 'w-100', poster?.displayDescription || poster?.displayTitle || poster.clickArea == 'BUTTON' ? 'filter' : '']"
          alt="..."
        >
        <a [href]="poster.targeting" target="{{poster.targetingType === 'EXTERNAL_LINK' ? '_blank' : '_self'}}" *ngIf="poster.clickArea === 'FULL_IMAGE' && poster.targetingType !== 'INTERNAL_LINK'">
          <div class="carousel-caption" *ngIf="!isMinimize">
            <h4 *ngIf="poster.displayTitle">{{poster.title}}</h4>
            <p *ngIf="poster.displayDescription" [ngClass]="{'handle_cap': (poster.clickArea == 'FULL_IMAGE' || poster.clickArea == 'NO_CLICK_AREA' || poster.clickArea == null)}">{{poster.description}}</p>
            <div class="d-flex justify-content-center">
              <a class="btn btn-primary mt-1" target="{{poster.targetingType === 'EXTERNAL_LINK' ? '_blank' : '_self'}}" [href]="poster.targeting" *ngIf="poster.clickArea == 'BUTTON'">
                <span style="text-decoration: none; color: #ffffff">{{poster.labelButton}}</span>
              </a>
            </div>
          </div>
        </a>

        <a [routerLink]="poster.targeting" *ngIf="poster.clickArea === 'FULL_IMAGE' && poster.targetingType === 'INTERNAL_LINK'">
          <div class="carousel-caption" *ngIf="!isMinimize">
            <h4 *ngIf="poster.displayTitle">{{poster.title}}</h4>
            <p *ngIf="poster.displayDescription" [ngClass]="{'handle_cap': (poster.clickArea == 'FULL_IMAGE' || poster.clickArea == 'NO_CLICK_AREA' || poster.clickArea == null)}">{{poster.description}}</p>
            <div class="d-flex justify-content-center">
              <a [routerLink]="poster.targeting" *ngIf="poster.clickArea == 'BUTTON'" class="btn btn-primary mt-1">
                <span style="text-decoration: none; color: #ffffff">{{poster.labelButton}}</span>
              </a>
            </div>
          </div>
        </a>

        <div class="carousel-caption" *ngIf="!isMinimize && (poster.clickArea === 'BUTTON' || poster.targetingType === 'POSTER_WITHOUT_LINK')">
          <h4 *ngIf="poster.displayTitle">{{poster.title}}</h4>
          <p *ngIf="poster.displayDescription" [ngClass]="{'handle_cap': (poster.clickArea == 'FULL_IMAGE' || poster.clickArea == 'NO_CLICK_AREA' || poster.clickArea == null)}">{{poster.description}}</p>
          <div class="d-flex justify-content-center">

            <a *ngIf="poster.targetingType === 'EXTERNAL_LINK' && poster.clickArea == 'BUTTON'" class="btn btn-primary mt-1" target="{{poster.targetingType === 'EXTERNAL_LINK' ? '_blank' : '_self'}}" [href]="poster.targeting">
              <span style="text-decoration: none; color: #ffffff">{{poster.labelButton}}</span>
            </a>

            <a *ngIf="poster.targetingType === 'INTERNAL_LINK' && poster.clickArea == 'BUTTON'" class="btn btn-primary mt-1" [routerLink]="poster.targeting">
              <span style="text-decoration: none; color: #ffffff">{{poster.labelButton}}</span>
            </a>
          </div>
        </div>

      </div>
    </div>


    <a class="poster__control carousel-control-prev" (click)="goPrev()" *ngIf="(posters.length > 1) && (!isMinimize)" role="button" attr.data-bs-target="#carousel__captions-{{uniqId}}" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only visually-hidden">Anterior</span>
    </a>
    <a class="poster__control carousel-control-next" (click)="goNext()" *ngIf="(posters.length > 1) && (!isMinimize)" role="button" attr.data-bs-target="#carousel__captions-{{uniqId}}" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only visually-hidden">Próximo</span>
    </a> -->
  </div>
</div>
