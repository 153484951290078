import { Component, Input, Output, EventEmitter, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'mmp5-wizard-steps',
  templateUrl: './wizard-steps.component.html',
  styleUrls: ['./wizard-steps.component.scss']
})
export class WizardStepsComponent implements OnInit {

  @Input() steps = [];
  @Input() currentStep: number = null;
  @Input() icon: Array<string> = ['icon icon-busca']

  @Output() click = new EventEmitter();

  ngOnInit() {}

  constructor() { }

  navigate(event, index) {
    this.currentStep = index;

  }
}
