import { Component, OnInit, Input, HostListener, ViewEncapsulation, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import {JwtHelperService} from '@auth0/angular-jwt'
import { LoggedUserInterface } from "./loggeduser-interface";
import { Router, ActivatedRoute } from "@angular/router";
import { trigger, transition, animate, style, query, stagger, state } from '@angular/animations';
import { hexToHSL } from "./colors-utils";

@Component({
    selector:'mmp5-navbar',
    templateUrl:'./navbar.component.html',
    styleUrls:['./navbar.component.scss'],
    animations: [
        trigger('pageAnimations', [
            state('open', style({
                opacity:1
            })),
            state('close', style({
                opacity:1
            })),
            transition(':enter', [
                query('.sub-menu, div',  [
                    style({opacity: 0, transform: 'translateX(-100px)'}),
                    stagger(-30, [
                    animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
                    ])
                ])
            ]),
            transition(':leave', [
                query('.sub-menu, div',  [
                    style({opacity: 1, transform: 'none'}),
                    stagger(-10, [
                    animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0, transform: 'none' }))
                    ])
                ])
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None
})


export class NavbarComponent implements OnInit, OnChanges {
    @Input() show:boolean;
    @Input() menu_items:Array<string>;
    @Input() navbar_items:Array<string>;
    @Input() menu_toggle:boolean = false;
    @Input() mediaUrl: string;
    @Input() colors: any[];
    @Input('selectedColorId') selectedColorId: string
    @Input() contentMenuStatus: boolean;
    @Input() hasPermissionToConfig: boolean;
    @Input() loggedUser: any;
    @Input() configUrl: any;

    @Output('showContentMenu') showContentMenu =  new EventEmitter();
    @Output('selectedColor') selectedColor = new EventEmitter();
    @Output('signout') signout = new EventEmitter();


    private themeWrapper = document.querySelector('body');

    token: string;
    helper = new JwtHelperService();
    show_sub_menu:boolean = false;
    show_sub_item:boolean = false;
    route_parent:any;
    route_local:any;
    my_state = '';
    contentMenu: boolean = false;
    active: boolean = false;
    search: string = '';
    dropbox: boolean;

    @HostListener('document:click', ['$event'])
    onClick(event) {
        this.verify_parent();
        if(!event.target.classList.contains('fixed-sidebar-left') && !event.target.classList.contains('mouse-toggle')){
            this.menu_toggle = false;
        }
        if(!event.target.classList.contains('dont-hide') && !event.target.classList.contains('mat-tree-node') && !event.target.classList.contains('title-content') && !event.target.classList.contains('header-content-menu') && !event.target.classList.contains('noChild') && !event.target.classList.contains('ng-scrollbar-layout') && !event.target.classList.contains('ng-scrollbar') && !event.target.classList.contains('ng-scrollbar-thumb') && !event.target.classList.contains('ng-scrollbar-visible') && !event.target.classList.contains('dont-hide') ){
            this.showContentMenu.emit(false);
        }
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {

    }
    ngOnInit(){
        this.token = this.getToken();
        !this.loggedUser && (this.loggedUser = this.decode())
        this.verify_parent();
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.colors || changes.selectedColorId){
            this.verifyColorLightness()
        }
    }

    verifyColorLightness() {
        let selectedColor = this.colors.filter(color => color.id === this.selectedColorId)

        if(selectedColor.length > 0){
            let { second } = selectedColor[0]
            let hsl = hexToHSL(second)

            localStorage.setItem('colorHSL', JSON.stringify(hsl))

            let h = hsl['h'] * 360;

            if(hsl['l'] > 0.75){
                this.themeWrapper.style.setProperty('--navbar-subtitle-color', '#232327')
            } else {
                this.themeWrapper.style.setProperty('--navbar-subtitle-color', '#ffffff')
                if(h >= 50 && h <= 95 && hsl['l'] > 0.35){
                    this.themeWrapper.style.setProperty('--navbar-subtitle-color', '#232327')
                } else if (((h >= 45 && h < 50) || (h > 95 && h <= 185)) && hsl['l'] > 0.35) {
                    if(hsl['s'] >= 0.8){
                        this.themeWrapper.style.setProperty('--navbar-subtitle-color', '#232327')
                    }
                }
            }
        }
    }

    indexOf(array: Array<string>,item: string ){
        return array.indexOf(item)
    }

    getToken(): string {
        return localStorage.getItem('accessToken');
    }

    log(){

    }

    isSelected(data, exact?){
        let routes = this.router.url;

        if(exact){
            if (routes === data) {
                return 'selected'
            } else {
                return ''
            }
        } else {

            if (routes.startsWith(data)) {
                return 'selected'
            } else {
                return ''
            }
        }
    }

    decode(): any {
        return this.helper.decodeToken(this.token).user;
    }

    getRole(): Array<string> {
        return this.loggedUser ? this.loggedUser.user.roles : []
    }

    getUserPhoto(){
        let mediaId = this.loggedUser.user.photo ? this.loggedUser.user.photo.mediaId : null
    }

    get statemant(){
        return this.show_sub_menu ? 'open' : 'close';
    }

    settings() {
        this.router.navigate(this.configUrl);
    }

    toggleMenu(event: Event){
        event.stopPropagation();
        this.menu_toggle = !this.menu_toggle;
    }

    toggleSubMenu(event: Event, data, requireFunction: boolean){

        event.stopPropagation();
        if(requireFunction){
            this.showCM();
            this.menu_toggle = false;
            this.my_state = data;
        }else{

            this.show_sub_menu = !this.show_sub_menu;
            this.verify_data(data)
        }

    }

    toggleSubItem(event: Event){
        event.stopPropagation();
        this.show_sub_item = !this.show_sub_item;
    }

    openItemMenu(event: Event, data: string, requireFunction: boolean, sub_items, url: string){

        event.stopPropagation();

        if(requireFunction){
            this.contentMenuStatus = true;
            this.showCM();
        }else if(!requireFunction && !!sub_items==false && url){
            this.showContentMenu.emit(false)
            this.contentMenuStatus = false;
            this.toRoute(event, data, url, requireFunction)
        }else{
            this.showContentMenu.emit(false)
            this.contentMenuStatus = false;
            this.menu_toggle = true;
            this.my_state = data;
        }

    }

    verify_data(data){

        if(this.my_state == data){
            this.my_state = '';
        }else{
            this.my_state = data;
        }
    }

    verify_parent(){

        this.route.data.subscribe(data => this.route_local = data.local)
        if(this.show == true && this.route.parent != null && this.route.parent.snapshot.url.length > 0){
            if(this.route.parent.snapshot.url[0].path != undefined){

                this.route_parent = this.route.parent.snapshot.url[0].path;
            }else{

                this.route_parent = '';
            }
        }

    }

    selectColor(e){
        this.selectedColorId = e;
        this.selectedColor.emit(e)
    }

    canSee(items:Array<string>){

        let can_see = false;

        let c = this.getRole().filter(elem => {

            return items.indexOf(elem) >= 0

        })

        return can_see = c.length > 0 ? true : false;
    }

    showCM(){
        this.showContentMenu.emit(true);
    }

    toRoute(event: Event, data, url,  requireFunction: boolean ){
        event.stopPropagation();

        if(requireFunction){
            this.showCM();
            this.menu_toggle = false;
            this.my_state = data;
        }else{
            this.router.navigate([url])
            this.menu_toggle = false;
        }
    }


}
