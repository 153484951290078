import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mmp5-mm-pdf-viewer',
  templateUrl: './mm-pdf-viewer.component.html',
  styleUrls: ['./mm-pdf-viewer.component.scss']
})
export class MMPdfViewerComponent implements OnInit, OnChanges {

  pdf: PDFDocumentProxy = null;
  printPdfUrl = null;
  currentScale = 1.5;
  isPrinting = false;
  zoomField: UntypedFormControl = new UntypedFormControl(1);
  search = new FormControl(null);
  zoomPattern = new RegExp('-?[0-9]*(\.[0-9]+)?');

  @Input() url = null;
  @Input('show-footer') showFooter: boolean
  @Input('can-download') canDownload: boolean = true;
  @Input('block-copy') blockCopy = 1
  @Input('file-name') fileName = 'file.pdf'

  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;

  currentPage = 1;

  pdfQuery = '';

  constructor(
  ) { }

  ngOnInit() {

    this.search.valueChanges.pipe(debounceTime(500)).subscribe(res => {
      this.pdfQuery = res;
      this.pdfComponent.eventBus.dispatch('find', {
        query: this.pdfQuery, type: 'again', caseSensitive: false, findPrevious: undefined, highlightAll: true, phraseSearch: true
      });
    })
  }


  applyZoom(param) {
    console.log(this.search.value)
    let val = this.zoomField.value
    if (param == 'more') {
      val = (val + 0.1).toFixed(1);
    } else {
      val = (val - 0.1).toFixed(1);
    }

    this.zoomField.setValue(parseFloat(val))
  }

  callBackFn(event: PDFDocumentProxy) {
    this.pdf = event;
    this.isPrinting = true;
  }

  ngOnChanges() {
    this.currentPage = 1;
  }

  navigateTo(step) {
    this.currentPage += step;
  }

  print() {
    this.pdf.getData().then((u8) => {
      let blob = new Blob([u8.buffer], {
        type: 'application/pdf'
      });

      const blobUrl = window.URL.createObjectURL((blob));

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      a.download = this.fileName || 'file.pdf';
      document.body.appendChild(a);
      a.click();

      // const iframe = document.createElement('iframe');
      // iframe.style.display = 'none';
      // iframe.src = blobUrl;
      // document.body.appendChild(iframe);
      // iframe.contentWindow.print();
    });
  }
}
