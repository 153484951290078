import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Homepage } from '../../../models/homepage';

@Component({
  selector: 'mm-navbar-homepage-list',
  templateUrl: './navbar-homepage-list.component.html',
  styleUrls: ['./navbar-homepage-list.component.scss']
})
export class NavbarHomepageListComponent implements OnInit {

  @Input('homepages') homepages: any[] = [];
  @Output() fecharMenu: EventEmitter<boolean> = new EventEmitter()

  constructor(
    private _router: Router,
  ) { }

  ngOnInit() {
  
  }

  onClickHandler(id: string): void {
    this._router.navigate(['/hp', id])
    this.fecharMenu.emit();
  }

}
