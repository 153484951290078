import { Component, OnInit } from '@angular/core';
import { NotifyService } from '../../../services/notify.service';
import { PageableNotify, Notify } from '../../../interfaces/Notify';
import { MatDialog } from '@angular/material/dialog';
import { NotifyDialogComponent } from '../notify-dialog/notify-dialog.component';
import { Subscription } from 'rxjs';
import { LoggedUser } from '../../../models/loggedUser.service';
import { take, skip, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { isOnLogin } from '../../../utils/utils';

@Component({
  selector: 'mm-notify-dialog-management',
  templateUrl: './notify-dialog-management.component.html',
  styleUrls: ['./notify-dialog-management.component.scss']
})
export class NotifyDialogManagementComponent implements OnInit {
  dialogRef$: Subscription;
  socketNotify$: Subscription;
  dialogRef: any;
  isDialogOpen = false;
  allNotifications$: Subscription;

  constructor(
    private _notifyService: NotifyService,
    public dialog: MatDialog,
    private _router: Router,
    private _user: LoggedUser
  ) { }

  ngOnInit() {
    this.validateClientEnabledModules();
  }

  validateClientEnabledModules() {
    this._user.subscribeUserChange$().subscribe(user => {
      let modules: any[] = this._user.getModules().split(',');

      if(modules.indexOf('notify') >= 0){
        this.subscribeNotifyWebsocket();
        this.subscribeOpenAllNotifications();
      } else {
        this.unsubNotifications();
      }
    })
  }

  subscribeOpenAllNotifications() {
    this.allNotifications$ = this._notifyService.getOpenAllNotifications().subscribe(notifications => this.openDialogs(notifications))
  }

  unsubNotifications(){
    this.socketNotify$ && !this.socketNotify$.closed ? this.socketNotify$.unsubscribe() : false
    this.allNotifications$ && !this.allNotifications$.closed ? this.allNotifications$.unsubscribe() : false
  }

  getBellNotifications() {
    this._notifyService.getUnviewedRequiredNotifications().pipe(map(notifications => notifications ? notifications : [])).subscribe(notifications => this.openDialogs(notifications));
  }

  openDialogs(notifications: Notify[]): void {
    if(notifications.length && !this.isDialogOpen){
      console.log({notifications})
      this.dialogRef = this.dialog.open(NotifyDialogComponent, {width: '700px', maxHeight: '700vh', data: {notifications: notifications}, disableClose: true})
      this.isDialogOpen = true;

      this.dialogRef$ = this.dialogRef.afterClosed().subscribe(() => {
        this.isDialogOpen = false;
        this.subscribeNotifyWebsocket()
      })
    } else {
      this.subscribeNotifyWebsocket();
    }
  }

  subscribeNotifyWebsocket(): void {
    if(typeof this.socketNotify$ == "undefined") {
      this.socketNotify$ = this._notifyService.getSocketNotifys().subscribe(notification => this.openDialogs([notification]))
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dialogRef$ && this.dialogRef$.unsubscribe();
    this.socketNotify$ && this.socketNotify$.unsubscribe();
  }
}
