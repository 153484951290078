import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot } from "@angular/router";

import {  AlertController } from "mm-ui";

import { LoggedUser } from "../models/loggedUser.service";
import { AppService } from "../services/app.service";


@Injectable()

export class LoginGuard {

    constructor(
        private alertController: AlertController,
        public router:Router,
        public user: LoggedUser,
        private appService: AppService
    ){}

    canActivate(
        route: ActivatedRouteSnapshot
    ): boolean{
        if (this.user.isAuthenticated()) {
            let user = this.user.getUser();
            this.appService.setUser(user.user)
            return true
        } else {
            this.appService.setUser({})
            if(this.user.getToken()){
                this.alertController.configure({
                    title: 'Oops!',
                    error: 'login',
                    top: '19%',
                    width: '400px',
                    left: '57%',
                    buttons: [
                        {
                            type: 'primary',
                            text: 'Ok!',
                            handler: () => {
                                let auth ='/login';
                                let clientid;
                                if(this.user.getDecodedToken()){
                                    let modeAuth = this.user.getDecodedToken().modeAuth;
                                    clientid = this.user.getClientId()
                                    localStorage.removeItem('accessToken')
                                    if(modeAuth == 'ACTIVE_DIRECTORY'){
                                        auth = '/login/ad'
                                    } else if (modeAuth == 'GOOGLE'){
                                        auth = '/login/google'
                                    } else if (modeAuth == 'OFFICE_365'){
                                        auth = '/login/office'
                                    } else if (modeAuth == 'ADFS') {
                                        auth = 'login/adfs'
                                    }
                                    this.router.navigate([auth], {queryParams: {clientid}})
                                }

                            }
                        }
                    ]
                })
                this.alertController.open();
                return false;
            } else {

                let routeState = route['_routerState']
                if(routeState){
                    localStorage.setItem('redirectTo', routeState.url);
                }
                this.router.navigate(['login'])
                return false
            }
        }
    }
}
