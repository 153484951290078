<input type="text" *ngIf="!fieldset" (blur)="blur($event)" [class]="cssclasses" [(ngModel)]="inputValue" [placeholder]="placeholder" />
<mmp5-fieldset-haruki
  [formControl]="formControl"
  (blur)="blur($event)"
  [key]="'search_'+key"
  [class]="'hide-search-input-x'" 
  [showError]="false"
  [label]="placeholder"
  (keyup)="validateKeyUp($event)"
  [type]="type"
  *ngIf="fieldset">
</mmp5-fieldset-haruki>
<div *ngIf="!hiddenSearchButton">
  <div *ngIf="type != 'search' && !addCustomSelection" class="icon">
    <a (click)="onClick($event)" class="btn btn-primary btn-mmicon"> 
      <mmp5-mm-icons [icon]="icon[0]" class="mmicon mb-2"></mmp5-mm-icons>
    </a>
  </div>
  <div *ngIf="addCustomSelection" class="icon">
    <button class="btn btn-primary" (click)="addCustom()">{{addCustomSelectionText}}</button>
  </div>
</div>
<div class="container-suggestions" *ngIf="suggestions && suggestions.length > 0">
  <div *ngFor="let s of suggestions; let i = index;" class="suggestion-item">
    <span  (click)="select($event, s)"  [ngClass]="{ 'active': selected == i, 'has-button': showButton == true}">
      {{ s.name }}
    </span>
    <button *ngIf="showButton" class=" suggestion-button btn" (click)="changed($event, s)">
        <!-- <i [ngClass]="buttonClass"></i> -->
        <mmp5-mm-icons [icon]="icon[1]"></mmp5-mm-icons> 
    </button>
  </div>
</div>
