import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { of, Subject, BehaviorSubject } from 'rxjs';
import { Notify, NotifyLinkType, NotifyInteraction, PageableNotify } from '../interfaces/Notify';
import { HttpClient } from '@angular/common/http';
import { LoggedUser } from '../models/loggedUser.service';
import { map, tap, take, skip } from 'rxjs/operators';
import { isOnLogin } from '../utils/utils';

@Injectable({ providedIn: 'root' })
export class NotifyService {
  notifySocket: any;

  private apiVersion = `v${AppConfig.data['apiVersion']}`;
  private service = AppConfig.data['services']['notify'];
  private endpoint = `${this.service}/${this.apiVersion}`
  private endpointDownload = `${AppConfig.data['services']['gateway']}/download/notify/${this.apiVersion}`
  private endpointWebsocket = AppConfig.data['endpointNotifyWebsocket'];
  private _emptyNotify: Notify
  private _notifyConfig$: BehaviorSubject<Notify> = new BehaviorSubject({} as Notify);
  private _valid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _notify$: Subject<any> = new Subject();
  private _readBellNotify$ = new Subject<string>();
  private _notifyOpenAll$ = new Subject<Notify[]>();
  private alreadyOpenAll: boolean;

  notifySubscription: any;
  ws: WebSocket;
  bellNotifications: number;
  bellActivitiesNotifications: number = 0
  bellDefaultNotifications: number = 0

  constructor(
    private http: HttpClient,
    private user: LoggedUser,
    //private _oneSignalService: OneSignalService
  ) { }

  emitNotification(notification: Notify) {
    this._notify$.next(notification)
  }

  getOpenAllNotifications() {
    return this._notifyOpenAll$.asObservable();
  }

  openAllNotifications() {
    if (!this.alreadyOpenAll) {
      this.getUnviewedRequiredNotifications().pipe(map(notifications => notifications ? notifications : [])).subscribe(notifications => this._notifyOpenAll$.next(notifications));
      this.alreadyOpenAll = true;
    }
  }

  cancelAlreadyOpenAll() {
    this.alreadyOpenAll = false;
  }

  downloadNotifyStats(pushId: string) {
    return this.http.get(`${this.endpointDownload}/stats/pushNotifications/${pushId}`, { observe: 'response', responseType: 'blob' })
  }

  disconnectWebsocket() {
    this.ws ? this.ws.close() : false;
  }

  getNotifyObj() {
    return this._notifyConfig$.asObservable();
  }

  getReadBellNotification() {
    return this._readBellNotify$.asObservable();
  }

  getSocketNotifys() {
    return this._notify$.asObservable();
  }

  emitSocketNotify(notify: Notify) {
    let userId = this.user.getUserId();
  }

  setNotifyObj(notify: Notify) {
    this._notifyConfig$.next(notify)
  }

  setValidForm(valid) {
    this._valid$.next(valid)
  }

  getUnviewedRequiredNotifications() {
    return this.http.get<Notify[]>(`${this.endpoint}/notifications/unviewed/required`, { headers: { ignoreInterceptorRedirect: 'true' } })
  }

  registerInteraction(id: string) {
    return this.http.post(`${this.endpoint}/notifications/mark/interacted/${id}`, {})
  }

  viewNotify(id: string, type?: string) {
    return this.http.post(`${this.endpoint}/notifications/mark/viewed/${id}`, {}).pipe(tap(() => {

      this._readBellNotify$.next(id);
      if (type == 'default') {
        this.bellDefaultNotifications--
      } else if (type == 'activities') {
        this.bellActivitiesNotifications--
      }
    }))
  }

  viewAllNotify() {
    return this.http.post(`${this.endpoint}/notifications/mark/all/viewed`, {}).pipe(tap(() => {
      this._readBellNotify$.next('all');
      this.bellNotifications = 0;
      this.bellActivitiesNotifications = 0
      this.bellDefaultNotifications = 0
    }))
  }

  getNotifySends(id: string) {
    return this.http.get(`${this.endpoint}/notify/config/${id}/pushNotifications`)
  }

  getValidForm() {
    return this._valid$.asObservable();
  }

  updateNotifyObj(notify: any, valid?: boolean): void {

    this._notifyConfig$.next({ ...this._notifyConfig$.value, ...notify });
    this._valid$.next(!!valid)
  }

  validateNotifyObject() {
    let notifyConfig = this._notifyConfig$.getValue();

    if (!notifyConfig.name || !notifyConfig.title) {
      this.setValidForm(false);
      return;
    }

    if (notifyConfig.notifyInteraction.type == NotifyLinkType.page) {
      (!notifyConfig.notifyInteraction.pageId || !notifyConfig.notifyInteraction.requiredInteractionPageBtnTxt) ? this.setValidForm(false) : false
      return;
    } else if (notifyConfig.notifyInteraction.type == NotifyLinkType.link) {
      (!notifyConfig.notifyInteraction.url || !notifyConfig.notifyInteraction.urlTitle) ? this.setValidForm(false) : false
      return;
    }

    if (notifyConfig.notifyInteraction.requiredInteractionChk) {
      (!notifyConfig.notifyInteraction.requiredInteractionBtnChkTxt || !notifyConfig.notifyInteraction.requiredInteractionChkTxt) ? this.setValidForm(false) : false
      return;
    }
  }

  saveNotify() {
    let notify = this._notifyConfig$.value;

    let { content, ico, message, name, title, homePage, htmlPlus } = notify;

    return this.createNotify({ content, ico, message, name, title, homePage, htmlPlus } as Notify);
  }

  saveNotifyInteractions() {
    let notifyInteraction = this._notifyConfig$.value;
    notifyInteraction.notifyInteraction.domain = window.location.hostname;
    return this.editNotifyInteractions(notifyInteraction)
  }

  saveNotifySchedule() {

    let notify = this._notifyConfig$.value;
    return this.editNotifySchedule(notify)
  }

  sendNotify(notifyId) {
    return this.http.post(`${this.endpoint}/notifications/send/${notifyId}`, {});
  }

  saveNotifyRecipients() {
    let notify = this._notifyConfig$.value;
    return this.http.put(`${this.endpoint}/notify/config/${notify.id}/change/receiver/${notify.receiver}`, notify.receiver)
  }

  editNotifySchedule(notify: Notify) {
    if (!notify.schedule) {
      this.sendNotify(notify.id).subscribe(() => { });
    }

    return this.http.put(`${this.endpoint}/notify/config/saveShipingSchedule`, notify);
    // mock for development
    // return of(notify);
  }


  //deprecated
  getTrayNotifys(size, page?) {
    return this.http.get<PageableNotify>(`${this.endpoint}/notifications/`, { params: { size, page } })
  }

  getTrayDefaultNotifys(size, page?) {
    return this.http.get<PageableNotify>(`${this.endpoint}/notifications/`, { params: { size, page } })
  }

  getTrayActivitiesNotifys(size, page?) {
    return this.http.get<PageableNotify>(`${this.endpoint}/notifications/activity`, { params: { size, page } })
  }

  getBellNotify() {
    return this.http.get(`${this.endpoint}/notifications/unviewed/number`).pipe(tap((total: number) => this.bellNotifications = total));
  }

  getBellActivitiesNotify() {
    return this.http.get(`${this.endpoint}/notifications/unviewed/activity/number`).pipe(tap((total: number) => this.bellActivitiesNotifications = total));
  }

  getBellDefaultNotify() {
    return this.http.get(`${this.endpoint}/notifications/unviewed/default/number`).pipe(tap((total: number) => this.bellDefaultNotifications = total));
  }

  editNotifyInteractions(notify: Notify) {
    return this.http.put(`${this.endpoint}/notify/config/saveInteraction`, notify)
    // mock for development
    // return of({...this._notify$.value});
  }

  createNotify(notify: Notify) {
    let clientId = this.user.getClientId();

    return this.http.post<Notify>(`${this.endpoint}/notify/config/save/data`, { ...notify, clientId }).pipe(tap(notify => this._notifyConfig$.next(notify)))
    // mock for development
    // return of({...notify, id: uuid()});
  }

  createEmptyNotify() {
    this._emptyNotify = {
      content: null,
      ico: null,
      message: null,
      name: null,
      id: null,
      notifyInteraction: {
        pageId: null,
        requiredInteractionChk: null,
        requiredInteractionBtnChkTxt: null,
        requiredInteractionPageBtnTxt: null,
        requiredInteractionChkTxt: null,
        type: NotifyLinkType.none,
        url: null,
        urlTitle: null,
        domain: window.location.hostname
      },
      publishingDate: null,
      schedule: null,
      title: null,
      receiver: null,
      groups: [],
      groupsInfo: [],
      users: [],
      usersInfo: [],
      homePage: [],
      htmlPlus:[]
    }

    this._notifyConfig$.next(this._emptyNotify);
  }

  getAllNotifications(title?: string) {
    return this.http.get<Notify[]>(`${this.endpoint}/notify/show/all`, { params: { title } });
  }

  getPagedNotifications(page: any, size: any) {
    // return this.http.get<PageableNotify>(`${this.endpoint}/notify/show`, {params: {page, size}}).pipe(map(Notifications => this.validatePagedNotifications(Notifications)));
    return this.http.get<PageableNotify>(`${this.endpoint}/notify/show`, { params: { page, size } })
  }

  validatePagedNotifications(notify: any): PageableNotify {
    if (notify['length']) {
      let pageableNotify = { content: notify, pageable: { pageNumber: 0, }, totalPages: 2, totalElements: 3 } as PageableNotify
      return pageableNotify
    } else {
      return notify;
    }
  }

  notifyInfo(notifyId) {
    return this.http.get(`${this.endpoint}/notify/config/${notifyId}`)
  }

  addUser(params) {
    return this.http.post(`${this.endpoint}/notify/config/${params.notifyId}/add/user/${params.id}`, params);
  }

  deleteUser(params) {
    return this.http.delete(`${this.endpoint}/notify/config/${params.notifyId}/delete/user/${params.id}`, params);
  }

  getUsers(notifyId) {
    return this.http.get(`${this.endpoint}/notify/config/${notifyId}/users`);
  }

  addGroup(params) {
    return this.http.post(`${this.endpoint}/notify/config/${params.notifyId}/add/group/${params.id}`, params);
  }

  deleteGroup(params) {
    return this.http.delete(`${this.endpoint}/notify/config/${params.notifyId}/delete/group/${params.id}`, params);
  }

  getGroups(notifyId) {
    return this.http.get(`${this.endpoint}/notify/config/${notifyId}/groups`);
  }

  editNotify(notifyId, params) {
    return this.http.put(`${this.endpoint}/notify/config/${notifyId}/change`, params);
  }

  deleteNotify(notifyId) {
    return this.http.delete(`${this.endpoint}/notify/config/${notifyId}/delete`);
  }

  editAndSend(notifyId, params) {
    return this.http.put(`${this.endpoint}/notify/config/${notifyId}/changeAndSend`, params)
  }

  getStats(pushNotificationId) {
    return this.http.get(`${this.endpoint}/stats/pushNotifications/${pushNotificationId}`)
  }

  getDashboardAllUsers(data) {
    return this.http.get(`${this.endpoint}/stats/searchUsersNotifications`, { params: data })
  }

  addSaveDevice(params) {
    return this.http.post(`${this.endpoint}/notify/config/push/device/save`, params);
  }

  sharePage(userIds, pageId, menuId) {
    const domain = window.location.hostname;

    const payload = {
      userIds,
      domain,
      pageId,
      menuId
    }

    return this.http.post(`${this.endpoint}/notifications/sendPage`, payload);
  }
}
