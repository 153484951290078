import { Routes, RouterModule } from "@angular/router";

import { ErrorPageComponent } from "./components/error-page/error-page.component";
import { LoginGuard } from "./guards/login-guard";
import { ContentMenuResolver } from "./components/cms/content/content.resolver.service";
import { NotificationGuard } from "./guards/notification-guard";

const appRouter: Routes = [

  {
    path: 'users',
    loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'breadcrumb.users.users' }
  },
  {
    path: 'accessprofiles',
    loadChildren: () => import('./components/access-profile/access-profile.module').then(m => m.AccessProfileModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'breadcrumb.accessprofiles.accessprofiles' }
  },
  {
    path: 'cms',
    loadChildren: () => import('./components/cms/cms.module').then(m => m.CmsModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'breadcrumb.cms.cms' }
  },
  {
    path: 'hp',
    loadChildren: () => import('./components/view/view.module').then(m => m.ViewModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: '' }
  },
  {
    path: 'templates',
    loadChildren: () => import('./components/templates/templates.module').then(m => m.TemplateModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: '' }
  },
  {
    path: 'features',
    loadChildren: () => import('./components/features/features.module').then(m => m.FeaturesModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'breadcrumb.features.features' }
  },
  {
    path: 'workflow',
    loadChildren: () => import('./components/workflow/workflow.module').then(m => m.WorkflowModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'breadcrumb.workflow.workflow' }
  },
  /* {
      path: 'login',
      loadChildren: () => import('./components/login/login.component-routing.module').then(m => m.LoginRoutingModule),
  }, */
  {
    path: 'login',
    loadChildren: () => import('./components/new-login/new-login.module').then(m => m.NewLoginModule)
  },
  {
    path: 'platform',
    loadChildren: () => import('./components/platform-config/platform-config.module').then(m => m.PlatformConfigModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'Configuração da Plataforma' }
  },
  {
    path: 'media',
    loadChildren: () => import('./components/media/media.module').then(m => m.MediaModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'Gestão de Mídias' }
  },
  {
    path: 'modules',
    loadChildren: () => import('./components/modules-config/modules-config.module').then(m => m.ModulesConfigModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'Módulos' }
  },
  {
    path: 'calendar',
    loadChildren: () => import('./components/calendar/calendar.module').then(m => m.CalendarModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'Calendários' }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'breadcrumb.dashboard.dashboard' }
  },
  {
    path: 'chats',
    loadChildren: () => import('./components/chat/chat.module').then(m => m.ChatModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'breadcrumb.chat.chat' }
  },
  {
    path: 'alice',
    loadChildren: () => import('./components/alice/alice.module').then(m => m.AliceModule),
    canActivate: [NotificationGuard],
    data: { breadcrumb: 'breadcrumb.alice.alice' }
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
    data: { breadcrumb: 'login' }
  },
  {
    path: 'error/:status',
    component: ErrorPageComponent,
    canActivate: [LoginGuard]
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: { status: 404 },
    canActivate: [LoginGuard],
    resolve: { menu: ContentMenuResolver }
  }

]

export const routing = RouterModule.forRoot(appRouter, {})
