<div class="list-pages">
  <div class="no-pages" *ngIf="pagesLength <= 0">
    {{ 'cms.content.menu.haventSelected' | translate }}.
  </div>
  <div class="card {{page?.added ? 'added' : ''}} {{page?.removed ? 'removed' : ''}}" *ngFor="let page of (picked$ | async)">
    <div class="header">
        <h5>{{page?.title}}</h5>
        <!-- <em class="pointer fa fa-times" *ngIf="!pages" (click)="remove(page)"></em> -->
        <mmp5-mm-icons class="pointer" [icon]="['fa-solid','fa-xmark']" *ngIf="!pages" (click)="remove(page)"></mmp5-mm-icons>
    </div>
    <p>{{page?.breadcrumb}}</p>
  </div>
</div>
