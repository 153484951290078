
<ul class="actions">
  <li *ngIf="canEdit" (click)="goTo('edit')" @fadeOpacity (mouseenter)="hover[0] = !hover[0]" (mouseleave)="hover[0] = !hover[0]">
      <div class="fab-icon d-flex">
        <mmp5-mm-icons [icon]="['icon','icon-grupos-publicacao']" class="icon"></mmp5-mm-icons>
        {{ 'button.edit' | translate }}
      </div>
  </li>
  <li (click)="goTo('page')" @fadeOpacity (mouseenter)="hover[2] = !hover[2]" (mouseleave)="hover[2] = !hover[2]">
      <div class="fab-icon d-flex">
        <mmp5-mm-icons [icon]="['icon','icon-gestao-de-conteudos-2']" class="icon"></mmp5-mm-icons>
        {{ 'button.newPage' | translate }}
      </div>
  </li>
  <!-- ::TODO implement copy pages homepages -->
  <!-- <li (click)="openDialog('copy')" @fadeOpacity (mouseenter)="hover[3] = !hover[3]" (mouseleave)="hover[3] = !hover[3]">
      <div class="fab-icon d-flex">
        <mmp5-mm-icons [icon]="['icon icon-gestao-de-conteudos-3']" class="icon"></mmp5-mm-icons>
        Copiar
      </div>
  </li> -->
  <li *ngIf="homepage && canEdit" (click)="openDialog('import')" @fadeOpacity (mouseenter)="hover[4] = !hover[4]" (mouseleave)="hover[4] = !hover[4]">
      <div class="fab-icon d-flex">
        <mmp5-mm-icons [icon]="['icon','icon-gestao-de-conteudos-4']" class="icon"></mmp5-mm-icons>
       {{ 'button.import' | translate }}
      </div>
  </li>
  <li *ngIf="homepage && canEdit" (click)="openDialog('move')" @fadeOpacity (mouseenter)="hover[5] = !hover[5]" (mouseleave)="hover[5] = !hover[5]">
      <div class="fab-icon d-flex">
        <mmp5-mm-icons [icon]="['icon','icon-gestao-de-conteudos-5']" class="icon"></mmp5-mm-icons>
        {{ 'button.move' | translate }}
      </div>
  </li>
  <li *ngIf="canEdit" (click)="delete()"  @fadeOpacity (mouseenter)="hover[6] = !hover[6]" (mouseleave)="hover[6] = !hover[6]">
      <div class="fab-icon d-flex">
        <mmp5-mm-icons [icon]="['icon', 'icon-toolbar-apagar']" class="icon"></mmp5-mm-icons>
        {{ 'button.delete' | translate }}
      </div>
  </li>
</ul>
