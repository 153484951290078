import { Injectable, ComponentFactoryResolver, ComponentRef, ChangeDetectorRef, ComponentFactory } from '@angular/core';
import { LoadingSpinnerComponent } from './loading-spinner.component';

@Injectable()
export class LoadingSpinner {

  ref: ComponentRef<LoadingSpinnerComponent> = null;
  factory: ComponentFactory<LoadingSpinnerComponent>;
  vcf: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    ) {
  }

  setViewContainerRef(vcf) {
   this.vcf = vcf;
   if(this.factory){
     this.factory = undefined;
   }
   this.factory = this.componentFactoryResolver.resolveComponentFactory(LoadingSpinnerComponent);
  }

  open() {
    if (!this.ref) {
      if(this.vcf){
        this.ref = this.vcf.createComponent(this.factory);
        this.ref.changeDetectorRef.detectChanges();
        this.ref.instance.loading = true;
      }else {
        console.log('No ViewContainerRef set');
      }
    } else {
      this.ref.instance.loading = true;
    }
  }

  getStatus(){
    if(this.ref){
      return this.ref.instance.loading;
    } else {
      return false;
    }
  }

  clearVcr() {
    this.close();
    this.vcf = undefined;
    this.factory = undefined;
    this.ref = undefined;
  }

  close() {
    if (this.ref) {
      this.ref.instance.loading = false;
    }
  }

}

