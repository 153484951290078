import * as pendentAcions from '../actions/pendentItems.actions'
import { PendentItem } from '../models/pendentItem';

export function reducerPendent (state: PendentItem, action: pendentAcions.Actions) {
    switch(action.type) {
        case pendentAcions.SET_CREATEUSER:
            return {
                ...state,
                createUser: true
            }
        case pendentAcions.RESET_CREATEUSER: 
            return {
                ...state,
                createUser: false
            }
        case pendentAcions.SET_DEFINEHOMEPAGE:
            return {
                ...state,
                defineHomepage: action.payload.id || true,
                clientId: action.payload.clientId
            }
        
        case pendentAcions.RESET_DEFINEHOMEPAGE: 
            return {
                ...state,
                defineHomepage: false,
                clientId: false
            }
        case pendentAcions.SET_CREATEPAGE:
            return {
                ...state,
                createPage: true
            }
        case pendentAcions.RESET_CREATEPAGE:
            return {
                ...state,
                createPage: false
            }
        default:
            return state;
    }
}