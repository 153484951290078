import { Injectable, ComponentFactoryResolver, ComponentRef } from '@angular/core';

import { ZoomImageComponent } from './zoom-image.component';
import { Router } from '@angular/router';

@Injectable()
export class ZoomServiceController {

  ref: ComponentRef<ZoomImageComponent> = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router
    ) {
  }

  setViewContainerRef(vcf) {

   const factory = this.componentFactoryResolver.resolveComponentFactory(ZoomImageComponent);
   this.ref = vcf.createComponent(factory);
   this.ref.changeDetectorRef.detectChanges();
  }

  configureMedia(media){
    this.ref.instance.media = media
  }

  configure(imageUrl, iTk?: string) {
    if (!this.ref) {
        throw new Error('No ViewContainerRef set');
      } else {
        this.ref.instance.imageUrl = imageUrl;
        this.ref.instance.iTk = iTk;
      }
  }

  configurePdf(pdf){
    this.ref.instance.pdfUrl = pdf;
  }

  open() {
    if (!this.ref) {
      throw new Error('No ViewContainerRef set');
    } else {
      this.ref.instance.active = true;
      this.ref.changeDetectorRef.detectChanges();
    }
  }

  clearVcr() {
    this.close();
    this.ref = undefined;
  }

  close() {
    if (!this.ref) {
       throw new Error('No ViewContainerRef set');
    } else {
      this.ref.instance.active = false;
    }

  }

}