import { Component, Input } from "@angular/core";

@Component({
    selector: 'mmp-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['tooltip.component.scss']
})

export class TooltipComponent {
   @Input('text') text:string
   @Input('tooltipClass') tooltipClass:any = 'tooltip-default'
   @Input('ariaLabel') ariaLabel:string
   @Input('tooltipPosition') tooltipPosition:string = 'above'
   @Input('class') class:string
   @Input('icon') icon: Array<string> = ['icon', 'icon-tooltip']
}
