import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AlertComponent } from "./alert.component";
import { MMIconsModule } from "../mm-icons/mm-icons.module";

@NgModule({
    declarations: [
        AlertComponent
    ],
    exports: [
        AlertComponent
    ],
    imports: [
        CommonModule,
        MMIconsModule
    ]
})
export class AlertModule {

}
