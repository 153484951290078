<div class="content-page-search" *ngIf="canSearch">
  <app-page-search (search)="search($event)"></app-page-search>
</div>
<div class="content-page-list" [id]="id" #componentContainer>

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-list">
    <mat-tree-node *matTreeNodeDef="let node" [ngClass]="{'mat-tree-node': true, 'homepage': !node?.homePageParent, 'page': !!node?.homePageParent}" matTreeNodeToggle>
      <div [ngClass]="{'mat-tree-node': true, 'homepage': !node?.homePageParent, 'page': !!node?.homePageParent}" (click)="selectPage($event,node)">
        <button mat-icon-button disabled></button>
        <div class="d-flex justify-content-between flex-grow-1 pe-2">
          <span>
            {{node.title || node?.page?.menuTitle}}
          </span>
          <mmp5-mm-icons [icon]="['fa-solid','fa-square-check']" *ngIf="showCheck && checkSelected(node)" class="text-success"></mmp5-mm-icons>
        </div>
      </div>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div (click)="selectPage($event,node)" [ngClass]="{'mat-tree-node': true, 'homepage': !node.pageId, 'page': !node?.homePageParent, 'onlyHomePage': onlyHomePage}">
        <button *ngIf="!onlyHomePage" (click)="loadChildPages($event, node)" mat-icon-button matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">

            <mmp5-mm-icons [icon]="['fa-solid', treeControl.isExpanded(node) == false ? 'fa-angle-right' : 'fa-angle-down']"></mmp5-mm-icons>
          </mat-icon>
          <!-- <em [ngClass]="{'fa-solid': true, 'fa-angle-right': treeControl.isExpanded(node) == false, 'fa-angle-down': treeControl.isExpanded(node) == true}"></em> -->
        </button>
        <mmp5-mm-icons *ngIf="!node.homePageParent" [icon]="['icon','icon-homepages']" class="hasChild"></mmp5-mm-icons>
        <div class="d-flex justify-content-between flex-grow-1 pe-2">
          <span>{{node.title || node?.page?.menuTitle}}</span>
          <mmp5-mm-icons [icon]="['fa-solid','fa-square-check']" *ngIf="showCheck && checkSelected(node)" class="text-success"></mmp5-mm-icons>
        </div>
      </div>
      <ul *ngIf="treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </mat-nested-tree-node>
  </mat-tree>
</div>
