import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FeatureContent, Page } from 'projects/f-cms/src/app/models/page';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { ContentService } from '../../../content.service';
import { Homepage } from 'projects/f-cms/src/app/models/homepage';
import { BehaviorSubject } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PageListContent, PageListPage, ListPageConfig } from 'projects/f-cms/src/app/interfaces/featuresConfig';
import { skip } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'mm-define-list-page',
  templateUrl: './define-list-page.component.html',
  styleUrls: ['./define-list-page.component.scss']
})
export class DefineListPageComponent implements OnInit, OnChanges {

  @Input('featureContent') feature: ListPageConfig = {} as ListPageConfig;
  @Input('homepage') homepage: string
  @Input('maxPages') maxPages: number;
  @Input('showSelected') showSelected: boolean = true;
  @Input('showCheck') showCheck: boolean = false;
  @Input('selectHomepage') selectHomepage: boolean = false;
  @Input('onlyHomePage') onlyHomePage: boolean = false;
  @Input('selectedPages') selectedPages: string[] = []
  @Input('pages') pages: Page[] = []
  @Input('picked$') picked$: BehaviorSubject<Page[]> = new BehaviorSubject([])
  @Input('pickedItemAttribute') pickedItemAttribute: string = 'id';
  @Input('pickedCheckedAttribute') pickedCheckedAttribute: string = 'id';
  @Input('creating-item') creatingItem: boolean = false

  @Output('value') value = new EventEmitter()

  treeControl = new NestedTreeControl<Page>(node => node.pages)
  dataSource = new MatTreeNestedDataSource<Page>();

  pagesId = new UntypedFormControl('')
  pages$: BehaviorSubject<Page[]> = new BehaviorSubject([])
  options: any;

  constructor(
    private _router: Router,
    private contentService: ContentService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.pages && changes.pages.currentValue && changes.pages.currentValue.length > 0) {
      if ((changes.creatingItem && !changes.creatingItem.currentValue) || !changes.creatingItem) {
        this.picked$.next(changes.pages.currentValue)
      } else {
        this.pages = null
      }
    }
  }

  ngOnInit() {
    if (this.pages) {
      this.picked$.next(this.pages)
    }
    this.getFeature()
  }

  // ngOnChanges(changes: SimpleChanges){
  //   if(this.feature){
  //     if(this.feature.content){
  //       if(this.picked$.value != this.feature.content.pages){
  //         this.getFeature()
  //       }
  //     }
  //   }
  // }

  getFeature() {
    if (this.feature.content && this.feature.content.pages) {
      this.picked$.next(this.feature.content.pages)
    } else {
      this.feature = { ...this.feature, content: { pages: [] } as PageListContent }
      // this.picked$.next([])
    }
    this.getUpdates()
  }

  getUpdates() {
    this.picked$.subscribe(picked => {
      this.value.emit(picked)
    })
  }
}

