import { Injectable } from "@angular/core";

import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ContentMenuService {

  treeControl: any[]
  actionsMenuObservable = new BehaviorSubject(null)
  actionMenuIsOpen = new BehaviorSubject(null)
  private updateMenu$ = new Subject<Record<string, string>>();

  constructor() {
    let tree = localStorage.getItem('contentMenu');
    localStorage.removeItem('contentMenu')
    if (tree) {
      this.treeControl = JSON.parse(tree);
    }
  }

  closeActionsMenu(id: string) {
    this.actionsMenuObservable.next(id ? id : '');
  }

  getActionsMenu() {
    return this.actionsMenuObservable.asObservable();
  }

  setActionMenuIsOpen(action: boolean) {
    this.actionMenuIsOpen.next(action)
  }

  // verifyActionMenuIsOpen(){
  //     return this.actionMenuIsOpen.value
  // }

  persistTreeControl(tree: any[]) {
    this.treeControl = tree
    localStorage.setItem('contentMenu', JSON.stringify(tree))
  }
  getTreeControl() {
    return this.treeControl;
  }

  listenToUpdateMenu() {
    return this.updateMenu$.asObservable();
  }

  updateMenu(id: string, type: 'HOMEPAGE' | 'MENUID') {
    this.updateMenu$.next({ id, type })
  }
}
