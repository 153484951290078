import { Component, OnInit, ViewContainerRef, OnDestroy, ViewChild, ElementRef, Input, InjectionToken, Injector, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, OverlayContainer } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ViewPageActionComponent } from './view/view.component';
import { BehaviorSubject } from 'rxjs';
import { ContentMenuService } from '../content-menu.service';
import { ContentMenu } from 'projects/f-cms/src/app/interfaces/contentMenu';
import { LoggedUser } from 'projects/f-cms/src/app/models/loggedUser.service';

export const PORTAL_DATA = new InjectionToken<{}>('PortalData');

@Component({
  selector: 'page-actions',
  templateUrl: './page-actions.component.html',
  styleUrls: ['./page-actions.component.scss']
})

export class PageActionsComponent implements OnInit, OnDestroy, OnChanges {

  overlayRef: OverlayRef
  @ViewChild('action', {static: true}) action: ElementRef
  @Input ('page') page: ContentMenu
  @Output('deleted') deleted = new EventEmitter()
  @Input ('delete') delete: BehaviorSubject<boolean>
  @Input('active') active: boolean
  @Input('hasInternalPages') hasInternalPages: boolean = false;

  id:string
  actualpage: string

  isOpen: boolean = false
  userHasCI: boolean;

  constructor(
    public vcr: ViewContainerRef,
    public overlay: Overlay,
    public overlayContainer: OverlayContainer,
    private _injector: Injector,
    private contentMenuService: ContentMenuService,
    private _user: LoggedUser
  ) { }

  ngOnInit() {
    this.userHasCI = this._user.checkRole("CI");
    this.contentMenuService.getActionsMenu().subscribe((uniqueIdentifier) => {
      this.actualpage = this.page.id;
      this.id = uniqueIdentifier;
      if(uniqueIdentifier != this.page.uniqueIdentifier){
        if(this.overlayRef){
          this.destroyActions();
        }
      }
    })
  }

  ngOnChanges(simple: SimpleChanges){

    if(simple.page.currentValue.clicked){
      this.open();
    }
  }

  getActions() {

    let hasInternalPages = this.hasInternalPages

    let config = new OverlayConfig({
      positionStrategy: this.overlay
      .position()
      .flexibleConnectedTo(this.action)
      .withPositions(this.getPositions())
      .withPush(false)
      // connectedTo(this.action, {originX: 'end', originY: 'center'}, {overlayX: 'start', overlayY: 'top'}).withOffsetX(19).withOffsetY(-25)
    });

    this.overlayRef = this.overlay.create(config);
    const pageAction = new ComponentPortal(ViewPageActionComponent, this.vcr, this.createInjector({
      id: this.page.id
    }))
    const componentRef = this.overlayRef.attach(pageAction)
    // refactor content-menu
    if(this.page.homePageParent){
      componentRef.instance.id = this.page.pageId;
      componentRef.instance.menuId = this.page.id;
      componentRef.instance.type = 'PAGE'
    } else {
      componentRef.instance.id = this.page.homePageId;
      componentRef.instance.menuId = this.page.id;
      componentRef.instance.type = 'HOMEPAGE';
    }
    componentRef.instance.userRole = this._user.checkRole('CI') ? 'CI' : (this._user.checkRole('PS') ? 'PS' : null)
    componentRef.instance.itemTitle = this.page.title;
    componentRef.instance.homepage = this.page.homePageParent;
    componentRef.instance.deleted = this.delete;
    componentRef.instance.hasInternalPages = hasInternalPages;
    componentRef.instance.imported = this.page.importation
    this.isOpen = true
  }

  private getPositions(): any {
    return [
      {
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'bottom'
      },
      {
        originX : 'end',
        originY : 'center',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX : 'end',
        originY : 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      },
      {
        originX : 'end',
        originY : 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      }

    ]
   }

  destroyActions(){

    this.overlayRef.detach()
    this.overlayRef.dispose()
    this.isOpen = false
  }

  open(){
    !this.isOpen ? this.getActions() : this.destroyActions();
    this.contentMenuService.setActionMenuIsOpen(this.isOpen)
  }

  ngOnDestroy(){

    this.overlayRef && this.overlayRef.detach() && this.overlayRef.dispose();
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap<any,any>([
      [PORTAL_DATA, dataToPass]
    ]);

    return new PortalInjector(this._injector, injectorTokens);
  }
}
