import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mm-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() pages : number
  @Input() actived: number;
  @Output() selected = new EventEmitter();

  numbers = []
  hasMore = false;
  hasLess = false;
  min = 0;
  max = 2;

  constructor(
    private translate: TranslateModule
  ) { }

  ngOnInit() {
    this.numbers = Array(this.pages).fill(this.pages).map((x,i) => i+1);
    this.max=this.pages-1;
  }

  upRange(){
    this.min = this.min+3;
    this.max = this.max+3;
    if(this.max>=this.pages || this.max>=this.pages-3 ){
      this.hasMore = false
    } else {
      this.hasMore = true
    }

    if(this.min>0){
      this.hasLess = true
    } else {
      this.hasLess = false
    }
  }

  downRange(){
    this.min = this.min-3;
    this.max = this.max-3;

    if(this.max>=this.pages || this.max>=this.pages-3 ){
      this.hasMore = false
    } else {
      this.hasMore = true
    }

    if(this.min>0){
      this.hasLess = true
    } else {
      this.hasLess = false
    }
  }

  select(e){
    this.actived = e
    this.selected.emit(e)
  }

}
