import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { CropperData } from "./cropper-data";

@Injectable({
    providedIn: 'root'
})
export class CropperService{

    private data$: BehaviorSubject<any>

    constructor(){
        this.data$ = new BehaviorSubject({})
    }

    getData(){
        return this.data$.asObservable();
    }

    setData(data){
        this.data$.next(data);
    }
}
