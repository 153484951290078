<div class="dropbox">
    <a class="item" (click)="gotoEdit()">
        <!-- <i class="fa fa-power-off"></i> -->
        <mmp5-mm-icons [icon]="['icon','icon-drop-perfil-1']"></mmp5-mm-icons>
        <span class="item-dropbox"> Editar meu perfil</span>
    </a>
    <!-- :TODO pendent implementation -->
    <!-- <a class="item">
        <mmp5-mm-icons [icon]="['icon','icon-drop-perfil-2']"></mmp5-mm-icons>
        <span class="item-dropbox"> Meus contatos</span>
    </a> -->
    <!-- <a class="item">
        <mmp5-mm-icons [icon]="['icon','icon-drop-perfil-3']"></mmp5-mm-icons>
        <span class="item-dropbox"> Lista de tarefas
        </span>
    </a> -->
    <div *ngIf="colors?.length > 0" class="color-select">
        <!-- <i class="fa fa-paint-brush"></i> -->
        <mmp5-mm-icons [icon]="['icon','icon-drop-perfil-4']"></mmp5-mm-icons>
        <div class="color-selector">
            <span class="item-dropbox">
                Selecionar minha cor
            </span>
            <div class="colors">
                <div class="color-item" (click)="select(color?.id)" *ngFor="let color of getValidColors()" [ngStyle]="{'background-color': color?.first}">
                    <i class="fa fa-check" *ngIf="color?.id == selectedId"></i>
                </div>
            </div>
        </div>
    </div>
    <a class="item" (click)="signout()">
        <!-- <i class="fa fa-power-off"></i> -->
        <mmp5-mm-icons [icon]="['icon','icon-drop-perfil-5']"></mmp5-mm-icons>
        <span class="item-dropbox"> Sair</span>
    </a>
</div>
