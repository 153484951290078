import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ErrorValidationComponent } from './error-validation.component';

@NgModule({
  declarations: [ ErrorValidationComponent ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  providers: [],
  exports: [
    ErrorValidationComponent
  ]
})
export class ErrorValidationModule { }
