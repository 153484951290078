<footer [ngClass]="canDownload ? 'download-footer' : 'pagination-footer'" *ngIf="pdf && showFooter">

  <div class="zoom-button-container">
    <button (click)="applyZoom('less')">
      <mmp5-mm-icons class="z-button" [icon]="['icon', 'icon-zoom-out-foto']"></mmp5-mm-icons>
    </button>
    <mmp5-fieldset-haruki
    [formControl]="zoomField"
    [type]="'number'"
    [small]="true"
    [label]="''"
    [key]="'zoomField'">
    </mmp5-fieldset-haruki>
    <button (click)="applyZoom('more')">
      <mmp5-mm-icons class="z-button" [icon]="['icon', 'icon-zoom-in-foto']"></mmp5-mm-icons>
    </button>

    <div *ngIf="search">
      <mmp5-fieldset-haruki
      class="search"
      [formControl]="search"
      [label]="'view.search.search' | translate"
      [type]="'search'">
      </mmp5-fieldset-haruki>
    </div>
  </div>


  <div class="pagination-buttons" *ngIf="pdf._pdfInfo.numPages > 1">
    <button class="btn btn-primary" [disabled]="currentPage === 1" (click)="navigateTo(-1)">
      <mmp5-mm-icons [icon]="['fa-solid', 'fa-chevron-left']"></mmp5-mm-icons>
    </button>
    <span>
      {{ currentPage }}/{{ pdf._pdfInfo.numPages }}
    </span>
    <button class="btn btn-primary" [disabled]="currentPage === pdf._pdfInfo.numPages" (click)="navigateTo(1)">
      <mmp5-mm-icons [icon]="['fa-solid', 'fa-chevron-right']"></mmp5-mm-icons>
    </button>
  </div>

  <div class="download-button">
    <button *ngIf="isPrinting && canDownload" class="btn btn-secondary" (click)="print()">
      <mmp5-mm-icons [icon]="['fa-solid', 'fa-download']"></mmp5-mm-icons>
    </button>
  </div>
</footer>

<pdf-viewer [src]="url"
#PdfViewerComponent
    [(page)]="currentPage"
    [render-text]="true"
    [render-text-mode]="blockCopy"
    [show-all]="true"
    [original-size]="false"
    [zoom]="zoomField.value"
    [zoom-scale]="'page-width'"
    style="width: auto;height: 88vh;"
    (after-load-complete)="callBackFn($event)"
></pdf-viewer>

