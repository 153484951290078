import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgScrollbarModule } from "ngx-scrollbar";
import { AliceKeysConfigComponent } from "./alice-keys-config.component";

import { FieldsetHarukiModule } from '../fieldset-haruki/fieldset-haruki.module';

@NgModule({
  declarations: [
    AliceKeysConfigComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FieldsetHarukiModule,
    NgScrollbarModule,
  ],
  exports: [AliceKeysConfigComponent]
})
export class AliceKeysConfigModule { }
