import { OnInit, Input, HostListener, ViewEncapsulation, Output, EventEmitter, OnChanges, SimpleChanges, Component, OnDestroy } from "@angular/core";
import { JwtHelperService } from '@auth0/angular-jwt'
// import { LoggedUserInterface } from "./loggeduser-interface";
import { Router, ActivatedRoute } from "@angular/router";
import { trigger, transition, animate, style, query, stagger, state } from '@angular/animations';
import { hexToHSL } from "../../utils/colors-utils";
import { NotifyService } from "../../services/notify.service";
import { take, debounceTime, throttleTime, skipUntil, skipWhile } from "rxjs/operators";
import { ContentService } from "../cms/content/content.service";
import { Homepage } from "../../models/homepage";
import { UntypedFormControl } from "@angular/forms";
import { merge, Observable, Subscription, Subject } from "rxjs";
import { ViewService } from "../view/view.service";
import { Page } from "../../models/page";
import { UserInterface } from "../../interfaces/userInterface";
import { LoggedUser } from "../../models/loggedUser.service";
import * as moment from 'moment';
import { PlatformConfigService } from "../platform-config/platform-config.service";
import { MessageService } from "primeng/api";
import { platformConfigSearch } from "../../interfaces/platformConfig";
import { TranslateService } from "@ngx-translate/core";
import { ChatTrayComponent } from "../../shared/chat-tray/chat-tray.component";
import { MediaService } from "../media/media.service";
import { Utils } from "../../utils/utils";
// import { hexToHSL } from "mm-ui/lib/navba/colors-utils.d.ts";

@Component({
  selector: 'mmp5-f-cms-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('pageAnimations', [
      state('open', style({
        opacity: 1
      })),
      state('close', style({
        opacity: 1
      })),
      transition(':enter', [
        query('.sub-menu, div', [
          style({ opacity: 0, transform: 'translateX(-100px)' }),
          stagger(-30, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
          ])
        ])
      ]),
      transition(':leave', [
        query('.sub-menu, div', [
          style({ opacity: 1, transform: 'none' }),
          stagger(-10, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0, transform: 'none' }))
          ])
        ])
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})


export class NavbarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() show: boolean;
  @Input() menu_items: Array<any>;
  @Input() navbar_items: Array<string>;
  @Input() menu_toggle: boolean = false;
  @Input() mediaUrl: string;
  @Input() colors: any[];
  @Input('selectedColorId') selectedColorId: string
  @Input() contentMenuStatus: boolean;
  @Input() hasPermissionToConfig: boolean;
  @Input() loggedUser: any;
  @Input() unreadNotifys: any;
  @Input() token: string;
  @Input() chatBadge;
  @Input() showHomepagesOnNavbar = false;

  @Output('showContentMenu') showContentMenu = new EventEmitter();
  @Output('selectedColor') selectedColor = new EventEmitter();
  @Output('signout') signout = new EventEmitter();


  private themeWrapper = document.querySelector('body');

  // loggedUser: LoggedUserInterface;
  helper = new JwtHelperService();
  show_sub_menu: boolean = false;
  show_sub_item: boolean = false;
  route_parent: any;
  route_local: any;
  my_state = '';
  contentMenu: boolean = false;
  active: boolean = false;
  search: UntypedFormControl = new UntypedFormControl('')
  dropbox: boolean;
  homepagesUserHasAccess: any[];
  showHomepageList: boolean;
  searchKeyPressed$: Subject<any> = new Subject();
  showSuggestList: boolean;
  user: UserInterface;
  decodedToken: any;
  getAccessibleHomepages$ = new Subject();
  hasChat = false;
  hasNotify = false;
  showChatTray = false;
  showNotify;
  searchConfig: platformConfigSearch;
  totalNotifications$: Subscription;
  mdSrvc: string;
  iTk: any;
  iamSub$: Subscription;
  currentHome = '';
  profileBase64: string = null;

  @HostListener('document:click', ['$event'])
  onClick(event) {
    this.verify_parent();
    if (!event.target.classList.contains('fixed-sidebar-left') && !event.target.classList.contains('mouse-toggle')) {
      this.menu_toggle = false;
    }
    if (!event.target.classList.contains('dont-hide') && !event.target.classList.contains('mat-tree-node') && !event.target.classList.contains('title-content') && !event.target.classList.contains('header-content-menu') && !event.target.classList.contains('noChild') && !event.target.classList.contains('ng-scrollbar-layout') && !event.target.classList.contains('ng-scrollbar') && !event.target.classList.contains('ng-scrollbar-thumb') && !event.target.classList.contains('ng-scrollbar-visible') && !event.target.classList.contains('dont-hide')) {
      this.showContentMenu.emit(false);
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private contentService: ContentService,
    private viewService: ViewService,
    private _user: LoggedUser,
    private platformService: PlatformConfigService,
    private messageService: MessageService,
    private _translate: TranslateService,
    private mediaService: MediaService,
    private utils: Utils
  ) {

  }

  ngOnInit() {
    if (this.utils.hasStorage('mdService')) {
      this.mdSrvc = this.utils.getStorage('mdService')
    }
    this.iamToken()

    !this.loggedUser && (this.loggedUser = this.decode());
    this.verify_parent();
    this.getHomepagesUserHasAccess();
    this.subscribeForSearchChanges();
    this.subscribeToUserChange();
    this.getAccessibleHomepages$.pipe(throttleTime(3000), skipWhile(() => this.showHomepageList == false)).subscribe(() => this.getHomepagesUserHasAccess())
    this.hasChat = this._user.clientHasModule('chat');
    this.hasNotify = this._user.clientHasModule('notify');
    this.getConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    const homeId = localStorage.getItem('homeId');

    this.currentHome = homeId;

    if (changes.colors || changes.selectedColorId) {
      this.verifyColorLightness()
    }

    if (changes.mediaUrl) {
      if (changes.mediaUrl.previousValue != changes.mediaUrl.currentValue) {
        this.mediaService.getCDNImage(changes.mediaUrl.currentValue, this.iTk)
          .subscribe(async (data: Blob) => {
            try {
              const photo: any = await this.mediaService.toDataURL(data);
              this.profileBase64 = photo.result;
            } catch (e) {
              this.profileBase64 = null;
              console.log('Error converting to base64', e)
            }
          })
      }
    }
  }

  iamToken() {
    this.iamSub$ = this.mediaService.getIamToken().subscribe(token => this.iTk = token);
  }

  getConfig() {
    this.platformService.getSearchConfig()
      .subscribe(config => {
        this.searchConfig = config;
      }, () => this.setMessage('error', this._translate.instant('search.erroSearchConfig'), this._translate.instant('errors.occurred')))
  }

  subscribeToUserChange() {
    this._user.subscribeUserChange$().subscribe(user => {
      this.user = user
      this.decodedToken = this._user.getDecodedToken();
    })
  }

  clearChatNots() {
    this.showChatTray = !this.showChatTray;

    this.chatBadge.default = 0;
  }

  subscribeForSearchChanges() {
    // emit everytime value change and when some key is pressed
    let searchChanges$ = merge(
      this.search.valueChanges,
      this.searchKeyPressed$
    );

    searchChanges$.pipe(debounceTime(300)).subscribe(value => this.searchItem(value))
  }

  gotoSiteMap() {
    let homeId = localStorage.getItem('homeId');

    this.router.navigate(['/hp', 'map', homeId]);
  }

  emitKeyPress(event: KeyboardEvent) {
    if (event.key == "Enter" || event.key == "ArrowUp" || event.key == "ArrowDown" || event.key == "ArrowLeft" || event.key == "ArrowRight") {
      this.searchKeyPressed$.next(event);
    }
  }

  gotoHomepage() {
    if (window.innerWidth < 425) {
      this.showHomepages();
      return;
    }
    this.platformService.getPrincipalHomepage().subscribe({
      next: (data) => {
        if (data.principalHomepage != null) {
          this.router.navigate(['/hp', data.principalHomepage])
        } else {
          this.validateHomepagesUserHasAccess();
        }
      },
      error: () => {
        this.validateHomepagesUserHasAccess();
      }
    })
  }

  validateHomepagesUserHasAccess() {
    if (this.homepagesUserHasAccess) {
      this.showHomepageList = false;
      let homepageId = localStorage.getItem('homeId');
      if (homepageId) {
        this.router.navigate(['/hp', homepageId])
      } else {
        this.router.navigate(['/hp', this.homepagesUserHasAccess[0].id])
      }
    } else {
      let homepageId = localStorage.getItem('homeId');
      this.router.navigate(['/hp', homepageId]);
    }
  }

  showHomepages() {
    let homepage = this.contentService.homepageDefinition$.value;
    this.getAccessibleHomepages$.next(true);
    if (homepage.showNavigationHomepages) {
      this.showHomepageList = true;
    } else {
      this.showHomepageList = false;
    }
  }

  onFecharMenu(): void {
    this.showHomepageList = false;
  }

  getHomepagesUserHasAccess() {
    this.contentService.getAccessibleHomepages().subscribe((homepages: any[]) => {
      this.homepagesUserHasAccess = homepages
    })
  }


  verifyColorLightness() {
    let selectedColor = this.colors.filter(color => color.id === this.selectedColorId)

    if (selectedColor.length > 0) {
      let { second } = selectedColor[0]
      let hsl = hexToHSL(second)

      localStorage.setItem('colorHSL', JSON.stringify(hsl))

      let h = hsl['h'] * 360;

      if (hsl['l'] > 0.75) {
        this.themeWrapper.style.setProperty('--navbar-subtitle-color', '#232327')
      } else {
        this.themeWrapper.style.setProperty('--navbar-subtitle-color', '#ffffff')
        if (h >= 50 && h <= 95 && hsl['l'] > 0.35) {
          this.themeWrapper.style.setProperty('--navbar-subtitle-color', '#232327')
        } else if (((h >= 45 && h < 50) || (h > 95 && h <= 185)) && hsl['l'] > 0.35) {
          if (hsl['s'] >= 0.8) {
            this.themeWrapper.style.setProperty('--navbar-subtitle-color', '#232327')
          }
        }
      }
    }
  }

  gotoChat() {
    window.open('/chats/all-conversations', '_blank');
  }

  indexOf(array: Array<string>, item: string) {
    return array.indexOf(item)
  }

  searchItem(value: string | KeyboardEvent) {
    if (typeof value != 'string') {
      // TODO: implement autosuggest selection items, goto search-page inner
      if (value.key === 'Enter') {
        if (this.search.value) {
          this.showSuggestList = false;
          this.router.navigate(['/hp', 'search', this.search.value]);
        } else {
          let input: any = document.querySelector('.input-search-bar');

          input && input.focus();
        }
      }
    } else {
      this.showSuggestList = !!value
    }
  }

  isSelected(data, exact?) {
    let routes = this.router.url;
    if (exact) {
      if (routes === data) {
        return 'selected'
      } else {
        return ''
      }
    } else {
      // if (routes.indexOf(data) != -1) {
      if (routes.startsWith(data)) {
        return 'selected'
      } else {
        return ''
      }
    }
  }

  decode(): any {
    if (this.token) {
      return this.helper.decodeToken(this.token).user;
    } else {
      return {}
    }
  }

  getRole(): Array<string> {
    return this.loggedUser ? this.loggedUser.roles : []
  }

  getUserPhoto() {
    let mediaId = this.loggedUser.photo ? this.loggedUser.photo.mediaId : null;
    return mediaId;
  }

  get statemant() {
    return this.show_sub_menu ? 'open' : 'close';
  }

  settings(url: string) {
    this.router.navigateByUrl(url);
  }

  toggleMenu(event: Event) {
    event.stopPropagation();
    this.menu_toggle = !this.menu_toggle;
  }

  toggleSubMenu(event: Event, data, requireFunction: boolean) {

    event.stopPropagation();
    if (requireFunction) {
      this.showCM();
      this.menu_toggle = false;
      this.my_state = data;
    } else {

      this.show_sub_menu = !this.show_sub_menu;
      this.verify_data(data)
    }

  }

  toggleSubItem(event: Event) {
    event.stopPropagation();
    this.show_sub_item = !this.show_sub_item;
  }

  openItemMenu(event: Event, data: string, requireFunction: boolean, sub_items, url: string) {

    event.stopPropagation();

    if (requireFunction) {
      this.contentMenuStatus = true;
      this.showCM();
    } else if (!requireFunction && !!sub_items == false && url) {
      this.showContentMenu.emit(false)
      this.contentMenuStatus = false;
      this.toRoute(event, data, url, requireFunction)
    } else {
      this.showContentMenu.emit(false)
      this.contentMenuStatus = false;
      this.menu_toggle = true;
      this.my_state = data;
    }

  }

  verify_data(data) {

    if (this.my_state == data) {
      this.my_state = '';
    } else {
      this.my_state = data;
    }
  }

  verify_parent() {

    this.route.data.subscribe(data => this.route_local = data.local)
    if (this.show == true && this.route.parent != null && this.route.parent.snapshot.url.length > 0) {
      if (this.route.parent.snapshot.url[0].path != undefined) {

        this.route_parent = this.route.parent.snapshot.url[0].path;
      } else {

        this.route_parent = '';
      }
    }

  }

  selectColor(e) {
    this.selectedColorId = e;
    this.selectedColor.emit(e)
  }

  canSee(items: any) {

    let can_see = false;

    can_see = this.getRole().filter(elem => items.roles.indexOf(elem) >= 0).length > 0;

    if (items.module) {
      let modules = this.decodedToken.modules as string;
      if (modules === "null") {
        can_see = false
      } else {
        can_see = modules.split(',').indexOf(items.module) >= 0;
      }
    }

    return can_see
  }

  getClientModules() {
    if (this.token) {
      let modules = this.decodedToken && this.decodedToken.modules ? this.decodedToken.modules : null;
      return modules ? modules : ''
    } else {
      return 'null'
    }
  }

  showCM() {
    this.showContentMenu.emit(true);
  }

  toRoute(event: Event, data, url, requireFunction: boolean) {
    event.stopPropagation();
    if (requireFunction) {
      this.showCM();
      this.menu_toggle = false;
      this.my_state = data;
    } else {
      this.router.navigate([url])
    }
  }

  setMessage(severity: string, summary: string, detail: string) {

    this.messageService.add({ severity, summary, detail })
  }

  ngOnDestroy(): void {
    if (this.iamSub$)
      this.iamSub$.unsubscribe();
  }

  redirect(home?: string) {
    if (!home) {
      home = localStorage.getItem('homeId');
    }

    this.router.navigate(['/hp', home]);
  }
}
