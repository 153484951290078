import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from "@angular/router";
import { ResizeEvent } from "angular-resizable-element";
import deepdash from "deepdash";
import * as lodash from 'lodash';
import { NgScrollbar } from "ngx-scrollbar";
import { BehaviorSubject, fromEvent } from "rxjs";

import { Page } from "projects/f-cms/src/app/models/page";
import { debounceTime, map, merge, throttleTime } from "rxjs/operators";
import { fadeRight, fadeTop } from "../../../../animations/animations";
import { Utils } from "../../../../utils/utils";
import { ContentService } from "../content.service";
import { ContentMenuService } from "./content-menu.service";
import { PendentItemsState } from "projects/f-cms/src/app/stores/pendentItems.state";
import { Store } from "@ngrx/store";
import * as PendentActions from '../../../../actions/pendentItems.actions';

import { LoggedUser } from "projects/f-cms/src/app/models/loggedUser.service";
import * as uuid from 'uuid';
import { ContentMenu } from "projects/f-cms/src/app/interfaces/contentMenu";

import { ExampleFlatNode } from "projects/f-monit/src/app/interfaces/user-interface";


const _ = deepdash(lodash);

/** Flat node with expandable and level information */


@Component({
  selector: 'app-content-menu',
  templateUrl: './content-menu.component.html',
  styleUrls: ['./content-menu.component.scss'],
  animations: [fadeRight, fadeTop]
})

export class ContentMenuComponent implements OnInit, OnDestroy, OnChanges {

  homePages: ContentMenu[];
  allHomes: ContentMenu[];
  show: boolean = false;
  filters: boolean = false

  homes$;
  subPages$;

  search = new UntypedFormControl('')

  published = new UntypedFormControl(true)
  expired = new UntypedFormControl(true)
  validDate = new UntypedFormControl(true)
  noPublishedDate = new UntypedFormControl(true)
  filterById = new UntypedFormControl(false)

  actionsOpenId: string = null
  activatedFilter: string
  test: boolean;

  @Input() contentMenu: boolean = false;
  @Input() selectedPage: string;
  @Output('closed') closed = new EventEmitter()
  @ViewChild('menu', { static: true }) menu: ElementRef
  @ViewChild('scroll', { static: true }) scrollbarRef: NgScrollbar;

  deleted$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  private transformer = (node: ContentMenu, level: number) => {
    return {
      expandable: !!node.hasChildren || node.pages.length > 0,
      title: node.title,
      level: level,
      id: node.id,
      homePageParent: node.homePageParent,
      importation: node.importation,
      pageId: node.pageId,
      homePageId: node.homePageId,
      uniqueIdentifier: uuid.v4()
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level, node => node.expandable, node => node.pages);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  menuSize: string;

  userRole: boolean;
  isUserCI: boolean;

  //treeControl = new NestedTreeControl<any>(node => node.pages);
  //dataSource = new MatTreeNestedDataSource<ContentMenu>();

  constructor(
    private contentService: ContentService,
    public vcr: ViewContainerRef,
    private _router: Router,
    private pendentStore: Store<PendentItemsState>,
    private contentMenuService: ContentMenuService,
    private user: LoggedUser

  ) {

  }

  ngOnInit(): void {
    this.getTreeControl();

    this.searchChanges()
    this.deleted$.subscribe(item => {
      if (item) {
        this.getHomes()
      }
    })
    this.treeControl.expansionModel.changed.subscribe(changed => {
      let dataNodes = this.treeControl.dataNodes.map(data => (this.treeControl.isExpanded(data)))
      this.contentMenuService.persistTreeControl(dataNodes);
    })
    this.getScrollEvents()

    this.userRole = this.user.checkRole('CI') || this.user.checkRole('PS') ? true : false;
    this.isUserCI = this.user.checkRole('CI')

    this.listenToUpdateMenu()
  }

  ngAfterViewInit() {
    let menuSize = localStorage.getItem('menuSize');
    if (menuSize) {
      this.menuSize = menuSize;
    }

  }
  ngOnChanges(simple: SimpleChanges) {

    this.getTreeControl();
    if (simple.contentMenu && !simple.contentMenu.currentValue) {
      this.resetClicked()
      this.actionsOpenId = null;
      this.test = null;
      // this.search.setValue('', {emitEvent:false});
    }
    if (simple.contentMenu && simple.contentMenu.currentValue) {
      this.getHomes();
    }
  }

  listenToUpdateMenu() {
    this.contentMenuService.listenToUpdateMenu().subscribe({
      next: ({ id, type }) => {
        if (type === 'MENUID') {
          const page =
            _.findDeep(
              this.homePages,
              item => item?.id === id,
              { childrenPath: ['pages'] }
            );

          if (page) {
            if (page?.parent['id']) {
              this.getPagesSubPages({ id: page.parent['id'] }, true)
            } else {
              this.getHomes(true)
            }
          }
        }
      }
    })
  }

  createHomepage() {
    this.pendentStore.dispatch(new PendentActions.ResetDefineHomepage())
    this._router.navigate(['/cms', 'content', 'homepage-data'])
  }

  getScrollEvents() {
    let scrollEvents$ = fromEvent(document, 'wheel')

    scrollEvents$.pipe(throttleTime(300)).subscribe((e: any) => {
      if (e && e.path) {

        let ids = e.path.filter(ev => ev.id == 'menu-container')
        if (ids.length > 0) {
          this.contentMenuService.closeActionsMenu('aa');
        }
      }
    })


  }


  closeActionsMenu() {
    this.resetClicked();
    this.getTreeControl();
  }

  openActionsMenu(node: any) {
    this.contentMenuService.closeActionsMenu(node.uniqueIdentifier)
    node.clicked = !node.clicked;
    this.actionsOpenId = node.uniqueIdentifier;
    this.test = this.contentMenuService.actionMenuIsOpen.value;
    if (!this.test) {
      this.actionsOpenId = null;
    }
  }

  trackByFn(index, item) {
    return index; // or item.id
  }


  resetClicked() {
    let homes = this.dataSource.data
    homes = this.setShows(homes, { clicked: false })
    this.homePages = homes
    this.dataSource.data = homes
  }

  onResizeEnd(e: ResizeEvent) {
    if (e.rectangle.width < 310) {
      e.rectangle.width = 310;
    } else if (e.rectangle.width > 1000) {
      e.rectangle.width = 1000;
    }
    this.menuSize = e.rectangle.width + "px";
    localStorage.setItem('menuSize', `${e.rectangle.width}px`)
  }


  getHomes(forceUpdate?: boolean) {
    if (!this.homes$ || forceUpdate) {
      this.homes$ = this.contentService.contentMenuListByStepMain().subscribe(menuData => {
        menuData.forEach(menu => menu.pages = []);
        this.homePages = menuData;
        let homes = this.homePages;
        homes = this.setShows(homes, { show: false, clicked: false })
        this.dataSource.data = homes;
        this.homePages = homes;
        this.allHomes = homes
        this.getTreeControl();
        this.homes$.unsubscribe();
      });
    }
  }

  getSubpages(node): void {
    const home = this.homePages.find(h => h.id === node.id);

    if (!home.pages?.length) {
      this.subPages$ = this.contentService.contentMenuListChildren(node.id).subscribe(pages => {
        let indexHome = this.homePages.findIndex(homePage => homePage.id === node.id);
        this.homePages[indexHome].pages = pages;

        let homes = this.homePages;
        homes = this.setShows(homes, { show: false, clicked: false });

        this.dataSource.data = homes;
        this.homePages = homes;
        this.allHomes = homes;
        let indexHomeNode = this.treeControl.dataNodes.findIndex(homePage => homePage.id === node.id);
        this.treeControl.expand(this.treeControl.dataNodes[indexHomeNode]);

        this.getTreeControl();

        if (!pages?.length) {
          let indexInNode = this.treeControl.dataNodes.findIndex(nodeList => nodeList.id === node.id);
          this.treeControl.dataNodes[indexInNode].expandable = true;
        }

        this.subPages$.unsubscribe();
      });
    }
  }

  getPagesSubPages(node: any, forceUpdate?: boolean): void {
    const page =
      _.findDeep(
        this.homePages,
        item => item?.id === node.id,
        { childrenPath: ['pages'] }
      );

    if (page.value) {
      if (page.value?.pages?.length) {
        if (!forceUpdate) {
          return;
        }
      }
    };

    this.subPages$ = this.contentService.contentMenuListChildrenSubpages(node.id).subscribe(pages => {

      page.value.pages = pages;

      let homes = this.homePages;

      homes = this.setShows(homes, { show: false, clicked: false });

      this.dataSource.data = homes;
      this.homePages = homes;
      this.allHomes = homes;

      this.treeControl.expand(node);

      this.getTreeControl();

      this.subPages$.unsubscribe();
    });
  }

  searchContentMenu(search: string) {
    if (search) {

      this.contentService
        .contentMenuSearch(
          !this.filterById.value ? search : '',
          this.published.value,
          this.expired.value,
          this.validDate.value,
          this.noPublishedDate.value,
          this.filterById.value ? search : '',
        ).subscribe((pages: ContentMenu[]) => {
          this.homePages = pages;
          this.dataSource.data = pages;
          this.getTreeControl();
        });

      this.treeControl.expandAll();
    } else {
      this.dataSource.data = this.allHomes;
      this.homePages = this.allHomes;
      this.getTreeControl();
      this.treeControl.collapseAll();
    }
  }

  getTreeControl() {
    let tree = this.contentMenuService.getTreeControl();
    if (tree) {
      tree.forEach((data, i) => {
        if (data) {
          if (this.treeControl.dataNodes) {
            this.treeControl.expand(this.treeControl.dataNodes[i])
          }
        }
      })
    }
  }

  searchChanges() {
    this.search
      .valueChanges
      .pipe(
        debounceTime(300),
        map(() => this.search.value))
      .subscribe(search => {
        this.searchContentMenu(search);
      })
  }

  setShows(home: ContentMenu[], value: any) {
    let pages: ContentMenu[] = home.map(h => {
      let ho = { ...h, ...value }
      if (ho.pages && ho.pages.length > 0) {
        ho.pages = this.setShows(ho.pages, value)
        return ho
      } else {
        return ho
      }
    })
    return pages
  }

  nodeAction(data: ContentMenu[], action: string): ContentMenu[] {
    data.forEach((node: ContentMenu) => {
      if (node.pages.length > 0) node.pages = this.nodeAction(node.pages, action)
      this.treeControl[action](node)
    })
    return data
  }

  filtersActivated(event) {
    event.stopPropagation();
    this.filters = !this.filters
    this.activatedFilter = this.filters ? 'activatedFilter' : 'noFilter'
  }

  ngOnDestroy() { }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
}

