const ContentTypesMap = {
  'application/pdf': 'pdf',
  'image/jpeg': 'image',
  'image/jpg': 'image',
  'image/png': 'image',
  'application/zip': 'zip'
};

export const isDescendant = (parent, child) => {
  let node = child ? child.parentNode : null;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export const getControls = (group, controls = []) => {
  Object.keys(group.controls).forEach( key => {
    const control = group.controls[key];
    controls = [...controls, { ...control, key }];
    if (control.controls) {
      controls = [...controls, ...getControls(control)];
    }
  });

  return controls;
};

export const toDataURL = file => {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result as string
        let encoded = result.replace(/^data:(.*;base64,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve({result, encoded});
      };
      reader.onerror = error => reject(error);
    });
};

export const friendlyContentType = contentType => {
  return ContentTypesMap[contentType] || null;
};

export const isImage = contentType => contentType && contentType.indexOf('image/') === 0 && contentType.indexOf('image/tiff') !== 0;
