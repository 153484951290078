<ng-progress [speed]="400" [spinner]="true" [color]="progressColor" #progress>
</ng-progress>
<section>
    <mmp5-f-cms-navbar
      *ngIf="showAppBar"
      [selectedColorId]="colorSelected"
      [contentMenuStatus]="showCM"
      (showContentMenu)="showCM = $event"
      (signout)="logout()"
      [colors]="colors"
      (selectedColor)="setColor($event)"
      [mediaUrl]="mediaUrl"
      [show]="show_menu"
      [menu_items]="menu_items"
      [menu_toggle]="false"
      [navbar_items]="navbar_items"
      [hasPermissionToConfig]="userHasConfigPermission"
      [unreadNotifys]="nots"
      [chatBadge]="chatNots"
      [loggedUser]="user"
      [token]="token"
      [showHomepagesOnNavbar]="showHomepagesOnNavbar">
    </mmp5-f-cms-navbar>
  <app-content-menu [selectedPage]="selectedPageId" *ngIf="showAppBar" (closed)="showCM = $event"
    [contentMenu]="showCM"></app-content-menu>
  <router-outlet></router-outlet>
</section>
<mm-notify-dialog-management></mm-notify-dialog-management>
<p-toast position="top-right" [baseZIndex]="'9999'" [style]="{'margin-top': '5.3rem','z-index':'1003'}"></p-toast>
<app-scroll-to-top></app-scroll-to-top>
<script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
