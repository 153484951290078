import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery.component';
import { NguCarouselModule } from '@ngu/carousel';
import { MMIconsModule } from '../mm-icons/mm-icons.module';
import { ZoomImageModule } from '../zoom-image/zoom-image.module';
import { SafePipeModule } from '../safePipe/safePipe.module';

@NgModule({
    declarations: [GalleryComponent],
    imports: [
        CommonModule,
        NguCarouselModule,
        MMIconsModule,
        ZoomImageModule,
        SafePipeModule
    ],
    exports: [GalleryComponent],
    providers: []
})

export class GalleryModule {}
