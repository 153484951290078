import { NgModule, Injectable } from '@angular/core';
import { CommonModule, TranslationWidth } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RouterModule } from '@angular/router';

import { MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMaskModule } from 'ngx-mask'

import * as moment from 'moment';
import { FieldsetHarukiComponent } from './fieldset-haruki.component';
import { ErrorValidationModule } from '../error-validation/error-validation.module';
import { TranslateModule } from '@ngx-translate/core';
import { MMIconsModule } from "../mm-icons/mm-icons.module";
import { TooltipModule } from '../tooltip/tooltip.module';

import { SafePipeModule } from '../safePipe/safePipe.module';




// Define custom service providing the months and weekdays translations
@Injectable({ providedIn: 'root' })
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor() {
    super();
    moment.locale('pt-BR');
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth) {
    let weekDays = moment.weekdaysShort();
    let saturday = weekDays.shift();
    weekDays.push(saturday);
    return weekDays[weekday - 1].charAt(0);
  }

  getMonthShortName(month: number): string {
    const monthName = moment.months()[month - 1];
    return monthName[0].toUpperCase() + monthName.substring(1);
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
}


@NgModule({
  declarations: [
    FieldsetHarukiComponent
  ],
  imports: [
    TranslateModule,
    RouterModule,
    CommonModule,
    ErrorValidationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    ColorPickerModule,
    TooltipModule,
    NgxFileDropModule,
    MatCheckboxModule,
    MatRadioModule,
    MMIconsModule,
    MatSliderModule,
    SafePipeModule
  ],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions }
  ],
  exports: [
    FieldsetHarukiComponent,
    ColorPickerModule
  ]
})
export class FieldsetHarukiModule { }
