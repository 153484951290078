<mat-toolbar *ngIf="data.mobileType === 'android'" color="primary" class="android-prompt">

  <div class="row">
    <div class="col-10">
      <button (click)="installPwa()" class="btn btn-pwa-buttom">
        <img src="{{urlIcon}}" width="48"> Adicionar o app {{appName}} à tela inicial
      </button>
    </div>

    <div class="col-2 col-btn-exit">
      <button (click)="close()" class="btn btn-pwa-exit">
        <!-- <i class="fa fa-times"></i> -->
        <mmp5-mm-icons [icon]="['fa-solid','fa-xmark']"></mmp5-mm-icons>
      </button>
    </div>
  </div>

</mat-toolbar>

<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">

  <div class="row">
    <div class="col-10">
      Para instalar este app em seu dispositivo, toque no botão Menu e, em seguida, no botão "Adicionar à tela inicial"
    </div>

    <div class="col-2 col-btn-exit">
      <button (click)="close()" class="btn btn-pwa-exit">
        <!-- <i class="fa fa-times"></i> -->
        <mmp5-mm-icons [icon]="['fa-solid','fa-xmark']"></mmp5-mm-icons>
      </button>
    </div>
  </div>

</div>
