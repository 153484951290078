<section class="mm-box-container notify-container">
    <header class="mm-box-header d-flex justify-content-between align-items-center" *ngIf="notifications">
        <div class="d-flex align-items-center">
            <mmp5-mm-icons class="ico-tamanho" [icon]="['icon','icon-notificacoes']"></mmp5-mm-icons>
            <span>{{actual?.title}}</span>
        </div>
        <div>
            <span class="showing" *ngIf="notifications?.length > 1">exibindo {{index + 1}}/{{notifications?.length}}</span>
            <mmp5-mm-icons class="next" *ngIf="!actual?.notifyInteraction?.requiredInteractionChkTxt && notifications?.length != (index + 1)" (click)="next()" [icon]="['icon','icon-banner-proximo']"></mmp5-mm-icons>
            <mmp5-mm-icons class="close" (click)="close()" title="fechar" *ngIf="!actual?.notifyInteraction?.requiredInteractionChk" [icon]="['icon','icon-fechar']"></mmp5-mm-icons>
        </div>
    </header>
    <div [ngClass]="['mm-box-content', actual?.notifyInteraction?.requiredInteractionChk ? 'hasRequiredInteraction' : '']">
        <ng-scrollbar track="vertical" [autoHeightDisabled]="false">
            <div class="word-break">
                <dynamic-html [ngClass]="{'content':true, 'dynamic-html':true, 'blockCopy': this.blockCopy}" [content]="actual?.content || actual?.message"></dynamic-html>
                <pre *ngIf="customAlerts && customAlerts.length > 0">{{customAlerts.length}}</pre>
                <!-- <html-plus *ngIf="customAlerts && customAlerts?.length > 0" [contents]="customAlerts" [not-a-feature]="true"></html-plus> -->
                <html-plus [contents]="actual?.htmlPlus" [not-a-feature]="true"></html-plus>
            </div>
        </ng-scrollbar>
        <div *ngIf="actual?.notifyInteraction?.requiredInteractionChk" class="notify-interactions">
            <mmp5-fieldset-haruki
                [formControl]="check"
                [label]="actual?.notifyInteraction?.requiredInteractionChkTxt"
                [type]="'checkbox'"
                [key]="'notify-check-'+actual?.id"
                [checked]="checked"
            >
            </mmp5-fieldset-haruki>
        </div>
    </div>
    <div class="mm-box-footer d-flex justify-content-end" *ngIf="actual?.notifyInteraction?.requiredInteractionChk">
        <button [disabled]="!checked" class="btn btn-primary btn-small" (click)="buttonEvent(actual)">
            {{textButton}}
        </button>
    </div>
    <div class="mm-box-footer d-flex justify-content-end" *ngIf="!actual?.notifyInteraction?.requiredInteractionChk && (actual?.notifyInteraction?.type == 'PAGE' || actual?.notifyInteraction?.type == 'LINK')">
        <button [disabled]="!checked" class="btn btn-primary btn-small" (click)="buttonEvent(actual)">
            {{textButton}}
        </button>
    </div>
</section>
