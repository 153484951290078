import { Component, Input, OnInit, OnChanges } from "@angular/core";

@Component({
    selector: 'mmp-progressbar',
    templateUrl: './mm-progressbar.component.html',
    styleUrls: ['./mm-progressbar.component.scss']

})

export class MMProgressBarComponent implements OnInit, OnChanges{
    
    constructor(){}
    
    @Input() value: number = 0;
    @Input() max: number = 100;
    
    progressType: string
    
    ngOnInit(): void {

    }

    ngOnChanges(){
        if(this.value <= 25){
            this.progressType = 'danger';
        }else if (this.value > 25 && this.value <= 50) {
            this.progressType = 'warning'
        }else if (this.value > 50 && this.value <= 75) {
            this.progressType = 'info'
        }else{
            this.progressType = 'success'
        }
    }
}