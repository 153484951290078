import { Component, OnInit, Input, ApplicationRef, SimpleChanges, ChangeDetectorRef, HostListener } from '@angular/core';
import { fromEvent, Subject, Observable, of } from 'rxjs';
import { filter, map, debounceTime, catchError } from 'rxjs/operators';
import { toDataURL, ZoomServiceController } from 'mm-ui';
import { ViewService } from '../../view/view.service';
import { Utils } from '../../../utils/utils'
import { platformConfigSearch } from '../../../interfaces/platformConfig';
import { AppComponent } from 'projects/f-cms/src/app/app.component';
import { MediaService } from '../../media/media.service';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from '../../../shared/dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { PlatformConfigService } from '../../platform-config/platform-config.service';


@Component({
  selector: 'mm-navbar-autosuggest-list',
  templateUrl: './navbar-autosuggest-list.component.html',
  styleUrls: ['./navbar-autosuggest-list.component.scss']
})
export class NavbarAutosuggestListComponent implements OnInit {

  pages$: Observable<any>;
  users$: Observable<any>;
  medias$: Observable<any>;
  search$ = new Subject();
  // atribuido valor 1 pra sempre exibir o header na primeira vez que exibe a lista
  pageLength: number = 1;
  userLength: number = 1;
  mediaLength: number = 1;

  iTk: any;

  @Input('keyword') keyword: string;
  @Input('showSuggestList') showSuggestList: boolean;
  @Input('search-config') searchConfig: platformConfigSearch;
  autoS_toggle: boolean = true;
  blockCopy: any;
  enableSearchAllHomepages

  @HostListener('document:click', ['$event'])
  onClick(event) {

    if (!event.target.classList.contains('autosuggest-list') && !event.target.classList.contains('input-search-bar')) {
      this.autoS_toggle = false;
    } else {
      this.autoS_toggle = true;
    }
  }

  constructor(
    private _zoomService: ZoomServiceController,
    private _appRef: ApplicationRef,
    private _viewService: ViewService,
    private _cdr: ChangeDetectorRef,
    private utils: Utils,
    private _mediaService: MediaService,
    private _platformConfig: PlatformConfigService,
    private dialog: MatDialog

  ) {
    this._zoomService.setViewContainerRef(this._appRef.components[0].instance.vcr);
  }

  ngOnInit() {
    this.subscribeToClick()
    this.subscribeToSearchChange()

    this._platformConfig.getBlockCopy().subscribe({
      next: resp => this.blockCopy = resp.blockCopy,
      error: err => console.log({ blockCopyError: err })
    });

    this._platformConfig.getEnableSearchAllHomepages().subscribe({
      next: resp => this.enableSearchAllHomepages = resp.enableSearchAllHomepages,
      error: err => console.log({ searchConfigError: err })
    });

    this._mediaService.getIamToken().subscribe(itk => this.iTk = itk);
  }

  subscribeToSearchChange() {
    this.search$.pipe(debounceTime(300)).subscribe((keyword) => {
      keyword ? this.searchItem(keyword) : this.resetResult()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.keyword) {
      // emit to subject with debounce pipe
      this.search$.next(changes.keyword.currentValue)
    }
  }

  searchItem(keyword) {
    let params = {
      queryTerm: keyword,
      page: 0,
      size: 5
    }

    let homeId = this.utils.getStorage('homeId');

    if (this.enableSearchAllHomepages) {
      homeId = null
    }

    this.pageLength = 1;
    this.userLength = 1;
    this.mediaLength = 1;
    this.showSuggestList = true;

    if (!this.searchConfig || (this.searchConfig && this.searchConfig.searchPage))
      this.pages$ = this._viewService.searchPageView(keyword, 5, 0, homeId).pipe(catchError(() => of({ content: [] })), map(page => this.setContentLength(page.content, 'pageLength')));
    if (!this.searchConfig || (this.searchConfig && this.searchConfig.searchUser))
      this.users$ = this._viewService.searchUsersView(params).pipe(catchError(() => of({ content: [] })), map(users => this.setContentLength(users.content, 'userLength')));
    if (!this.searchConfig || (this.searchConfig && this.searchConfig.searchMedia))
      this.medias$ = this._viewService.searchMediasView(keyword, '5', 0, '', '').pipe(catchError(() => of({ content: [] })), map(medias => this.setContentLength(medias.content, 'mediaLength')))

    this._cdr.detectChanges();
  }

  setContentLength(content: any[], itemLengthVarName: string): any {
    this[itemLengthVarName] = content.length;
    return content
  }

  subscribeToClick() {
    let click$ = fromEvent(document, 'click');

    click$.pipe(
      filter((event: any) => this.showSuggestList && event.path.filter(e => e && e.classList ? e.classList.contains('autosuggest-list') : false).length == 0))
      .subscribe(e => this.resetResult())
  }

  openMedia(media) {
    if (media.format === 'image' || media.is_image) {
      this.openImage(media);
    } else {
      this.downloadFile(media.url, this.iTk, media.title, media.extension);
    }
  }

  resetResult() {
    this.showSuggestList = false;
    this.pages$ = undefined;
    this.users$ = undefined;
    this.medias$ = undefined;
    this.pageLength = 1;
    this.userLength = 1;
    this.mediaLength = 1;
  }

  openImage(media): void {
    let appref = this._appRef.components[0].instance as AppComponent;
    this._zoomService.setViewContainerRef(appref.vcr);

    if (media) {
      this._zoomService.configureMedia(media);

      if (media.content_type.includes('image')) {
        this._zoomService.configure(media.url_download, this.iTk);
      }
    }

    this._zoomService.open();
  }

  downloadFile(url, iTk, name, extension) {
    this._mediaService.getCDNImage(url, iTk).subscribe((binarie: any) => {
      let file = toDataURL(binarie)
      if (file) {
        file.then((data: any) => {
          const blob = this.convertBase64ToBlob(data.result);
          const blobUrl = URL.createObjectURL(blob);
          if (extension == 'pdf') {
            this.dialog.open(PdfViewerDialogComponent, {
              data: {
                url: blobUrl,
                blockCopy: this.blockCopy ? 0 : 1
              },
              maxHeight: '99vh',
              minWidth: '80vw',
            })
          } else {
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = blobUrl;
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        })
      }
    })
  }

  convertBase64ToBlob(base64Image: string) {
    const parts = base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    // so deus sabe pq tava quebrando quando baixa esse mimetype
    const uInt8Array = new Uint8Array(imageType === 'application/vnd.ms-excel.sheet.macroenabled.12' ? decodedData.length - 1 : decodedData.length);

    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: imageType });
  }

}
