
<div class="chat-users-image-container">

    <div class="one-item d-flex align-items-center justify-content-center" *ngIf="photos.length == 1 || photos.length == 0">
        <img [ngClass]="'chat-photo'" [src]="photos[0] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
    </div>

    <div class="two-items" *ngIf="photos.length == 2">
      <div class="d-flex justify-content-start">
        <img [ngClass]="'chat-photo'" [src]="photos[0] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
      </div>
      <div class="d-flex justify-content-end">
        <img [ngClass]="'chat-photo'" [src]="photos[1] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
      </div>
    </div>

    <div class="three-items" *ngIf="photos.length == 3">
        <div class="d-flex two-objects justify-content-center">
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[0] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[1] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
        </div>
        <div class="d-flex justify-content-center">
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[2] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
        </div>
    </div>

    <div class="four-items" *ngIf="photos.length == 4">
        <div class="two-objects">
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[0] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[1] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
        </div>
        <div class="two-objects">
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[2] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[3] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
        </div>
    </div>

    <div class="more-items" *ngIf="photos.length > 4">
        <div class="two-objects">
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[0] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[1] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)" />
        </div>
        <div class="two-objects">
            <img [ngClass]="'chat-photo img-fluid'" [src]="photos[2] | authImage:'image':iTk:mdSrvc | async" (error)="onError($event)"/>
            <div class="other-users img-fluid"><span class="other-user-number">{{photos.length <= 99 ? photos.length - 3 : '+99'}}</span></div>
        </div>
    </div>
</div>
