<!-- <section>
  <div *ngFor="let step of steps; let i = index" [ngClass]="{'active': i + 1== currentStep, 'passed': i + 1 < currentStep }">
    <div (click)="navigate($event, i)">
      <span>{{ step }}</span>
    </div>
  </div>
</section> -->
<section class="mt-1">
  <div class="empty" *ngIf="steps.length < 3"></div>
  <div [attr.data-length]="steps.length" [attr.data-empty]="(steps.length < 3) && i == 0" [attr.data-current]="currentStep == i+1" class="div-wizard" *ngFor="let step of steps; let i = index"
    [ngClass]="{'active': i + 1 == currentStep, 'passed': i + 1 < currentStep }">
    <div class="row justify-content-center">
      <div class="stage">
        <span class="labelSpan">
          {{i+1}}
        </span>
        <div class="center-circle">
          <mmp5-mm-icons [icon]="icon[i]"></mmp5-mm-icons>
        </div>
      </div>
    </div>
    <section class="wizard">
      <span><strong>{{ step }}</strong></span>
    </section>
  </div>
</section>