import { Pipe, PipeTransform } from "@angular/core";


@Pipe({name: 'userValue'})

export class UserValue implements PipeTransform {

    constructor(){

    }

    transform(value: string,att: string): string {
        if(value){
            switch (att) {
                case 'chargeable':
                    if(value === 'true'){
                        return 'button.yes'
                    } else {
                        return 'button.no'
                    };
                case 'notViewUser':
                    if(value === 'true'){
                        return 'button.yes'
                    } else {
                        return 'button.no'
                    };
                case 'status':
                    return `users.management.createuser.techdata_status.${value.toLowerCase()}`;
                case 'personalData.civilStatus':
                    return `users.management.createuser.personaldata_civilstatus.${value.toLowerCase()}`;
                case 'personalData.gender':
                    return `users.management.createuser.personaldata_gender.${value.toLowerCase()}`;
                default:
                    return value
            }
        } else {
            return ''
        }
    }
}