<div class="autosuggest-list" *ngIf="showSuggestList && autoS_toggle">
    <ng-scrollbar [autoHeightDisabled]="false" [appearance]="'compact'">
        <div class="autosuggest-content">
            <div class="pages" *ngIf="pages$">
                <div class="search-header" *ngIf="pageLength">
                    <mmp5-mm-icons class="icon-header" [icon]="['icon', 'icon-gestao-de-conteudos-3']"></mmp5-mm-icons>
                    <h5> {{'view.search.page' | translate}} </h5>
                </div>
                <div *ngIf="pages$ | async as pagesResult;else spinner">
                    <ul class="search-list">
                        <li class="search-list-item" *ngFor="let page of pagesResult"><a class="text-decoration-none text-reset title" (click)="resetResult()" [routerLink]="['/hp', 'page', page?.menuId || '']" [innerHTML]="page?.title"></a></li>
                    </ul>
                </div>
                <ng-template #spinner>
                    <div class="spinner-backeground">
                        <mm-spinner></mm-spinner>
                    </div>
                </ng-template>
            </div>
            <div class="users" *ngIf="users$">
                <div class="search-header" *ngIf="userLength">
                    <mmp5-mm-icons class="icon-header" [icon]="['icon', 'icon-wizard-2-b']"></mmp5-mm-icons>
                    <h5>{{ 'breadcrumb.users.users' | translate }}</h5>
                </div>
                <ul class="search-list" *ngIf="users$ | async as usersResult; else spinner">
                    <li class="search-list-item list" *ngFor="let user of usersResult">
                        <h5 class="mb-0"><a class="text-decoration-none text-reset title" [routerLink]="['/hp', 'user-profile', user?.id]">{{user?.displayName}}</a></h5>
                        <span class="text-item">{{user?.corporateData?.workPosition}}</span>
                        <span class="text-item">{{user?.corporateData?.extension}}</span>
                    </li>
                </ul>
                <ng-template #spinner>
                    <div class="spinner-backeground">
                        <mm-spinner></mm-spinner>
                    </div>
                </ng-template>
            </div>
            <div class="medias" *ngIf="medias$">
                <div class="search-header" *ngIf="mediaLength">
                    <mmp5-mm-icons class="icon-header" [icon]="['icon', 'icon-video']"></mmp5-mm-icons>
                    <h5>{{ 'view.search.media' | translate }}</h5>
                </div>
                <ul class="search-list" *ngIf="medias$ | async as mediasResult; else spinner">
                    <li class="search-list-item list" *ngFor="let media of mediasResult">
                        <h5 class="mb-0"><a class="text-decoration-none text-reset title pointer" (click)="openMedia(media)">{{media?.title}}</a></h5>
                        <span class="text-item" [innerHTML]="media?.description"></span>
                    </li>
                </ul>
                <ng-template #spinner>
                    <div class="spinner-backeground">
                        <mm-spinner></mm-spinner>
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-scrollbar>
    <div class="more-results">
        <a class="text-decoration-none" (click)="resetResult();" [routerLink]="['/hp', 'search', keyword]">Mais resultados</a>
    </div>
</div>
