import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertControllerComponent } from './alert-controller.component';
import { AlertController } from './alert-controller';
import { FieldsetHarukiModule } from '../fieldset-haruki/fieldset-haruki.module';

@NgModule({
    imports: [
        CommonModule,
        FieldsetHarukiModule
    ],
    declarations: [
        AlertControllerComponent
    ],
    exports: [
        AlertControllerComponent
    ],
    providers: [
        AlertController
    ]
})
export class AlertControllerModule { }
