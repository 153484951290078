import { Action } from "@ngrx/store";

export const SET_CREATEUSER = '[PENDENTITEMS] Set CreateUser'
export const RESET_CREATEUSER = '[PENDENTITEMS] Reset CreateUser'
export const SET_DEFINEHOMEPAGE = '[PENDENTITEMS] Set DefineHomepage'
export const RESET_DEFINEHOMEPAGE = '[PENDENTITEMS] Reset DefineHomepage'
export const SET_CREATEPAGE = '[PENDENTITEMS] Set CreatePage'
export const RESET_CREATEPAGE = '[PENDENTITEMS] Reset CreatePage'


export class SetCreateUser implements Action{
    readonly type = SET_CREATEUSER;

    constructor(){}
}

export class ResetCreateUser implements Action {
    readonly type = RESET_CREATEUSER

    constructor(){}
}

export class SetDefineHomepage implements Action {
    readonly type = SET_DEFINEHOMEPAGE

    constructor(public payload?: {id: string, clientId: string}){}
}

export class ResetDefineHomepage implements Action {
    readonly type = RESET_DEFINEHOMEPAGE

    constructor(){}
}

export class SetCreatePage implements Action {
    readonly type = SET_CREATEPAGE

    constructor(){}
}

export class ResetCreatePage implements Action {
    readonly type = RESET_CREATEPAGE

    constructor(){}
}

export type Actions = SetCreateUser |
                      ResetCreateUser |
                      SetDefineHomepage |
                      ResetDefineHomepage |
                      SetCreatePage |
                      ResetCreatePage