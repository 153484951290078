<div class="notify-tray-content">
    <div class="notify-tray__header">
        <h5>
            {{'notify.tray.title' | translate}}
        </h5>
    </div>
    <div class="panel with-nav-tabs panel-dark">
        <div class="card-header pt-1">
            <ul class="nav nav-tabs">
            <li class="nav-item"><a (click)="active = 1" data-toggle="tab" [ngClass]="['nav-link', active == 1 ? 'active' : '']">{{'notify.tray.inbox' | translate}} ({{unviewedNotifications.default <= 0 ? 0 : unviewedNotifications.default }})</a></li>
            <li class="nav-item"><a (click)="active = 2" data-toggle="tab" [ngClass]="['nav-link', active == 2 ? 'active' : '']">{{'notify.tray.activities' | translate}} ({{unviewedNotifications.activities <= 0 ? 0 : unviewedNotifications.activities }})</a></li>
            </ul>
        </div>
        <div class="card-body">
            <div class="tab-content">
                <div [ngClass]="['tab-pane', 'fade', active == 1 ? 'in active' : '']" id="tab1default">
                    <mm-notify-tray-list (closed)="close.emit(true)" [items]="notifys" [type]="'default'">
                        {{'notify.tray.emptyNotify' | translate}}
                    </mm-notify-tray-list>                
                </div>
                <div [ngClass]="['tab-pane', 'fade', active == 2 ? 'in active' : '']" id="tab2default">
                    <mm-notify-tray-list (closed)="close.emit(true)" [items]="activitiesNotify" [type]="'activities'">
                        {{'notify.tray.emptyActivities' | translate}}
                    </mm-notify-tray-list>                
                </div>
            </div>
        </div>
    </div>
    <div class="see-all">
        <a (click)="close.emit(true)" [routerLink]="['/hp', 'notify', 'all']">{{'notify.tray.seeAll' | translate}}</a>
    </div>
</div>
