import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BannerInterfaceManager } from '../../models/homepage';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { BottomOverlapBannerComponent } from '../bottom-overlap-banner/bottom-overlap-banner.component';
import { Utils } from '../../utils/utils';
import { MediaService } from '../../components/media/media.service';

@Component({
  selector: 'app-left-image-banner',
  templateUrl: './left-image-banner.component.html',
  styleUrls: ['./left-image-banner.component.scss']
})
export class LeftImageBannerComponent extends BottomOverlapBannerComponent {

  constructor(
    public router: Router,
    public utils: Utils,
    public _mediaService: MediaService
    ) {
    super(router,_mediaService, utils);
  }

}
