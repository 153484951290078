import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MMPdfViewerComponent } from './mm-pdf-viewer.component';

import { MMIconsModule } from "../mm-icons/mm-icons.module";

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { FieldsetHarukiModule } from '../fieldset-haruki/fieldset-haruki.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        PdfViewerModule,
        MMIconsModule,
        FieldsetHarukiModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    declarations: [
        MMPdfViewerComponent
    ],
    exports: [
        MMPdfViewerComponent
    ],
    providers: []
})

export class MMPdfViewerModule {}
