import { NgModule } from "@angular/core";
import { PopUpComponent } from "./pop-up.component";
import { OverlayModule } from "@angular/cdk/overlay";


@NgModule({
    declarations: [PopUpComponent],
    exports: [PopUpComponent],
    imports: [OverlayModule]
})
export class PopUpModule{}