<div id="alertComponent" *ngIf="active" class="d-flex justify-content-center align-items-center flex">

<div id="alertComponent-backdrop">

</div>

  <section id="alertComponent-container" [ngStyle]="{'width': options.width ? options.width : '520px'}">
    <h1><strong>{{ options.title }}</strong></h1>

    <div class="row" *ngIf="options.error">
      <div class="col-12">
        <img src="../../assets/images/erro.png" alt="sessão expirada" *ngIf="options.error == 'login'" class="img-fluid"/>
        <img src="../../assets/images/droppedsession.png" alt="sessão expirada" *ngIf="options.error == 'destroySession'" class="img-fluid"/>
        <img src="../../assets/images/recovery_work.png" alt="recuperar sessão" *ngIf="options.error == 'pendent'" class="img-fluid"/>
      </div>
    </div>

    <p class="pb-0 text-center" *ngIf="options.error == 'login'">Sessão expirada, você será redirecionado à tela de login.</p>
    <p class="pb-0 text-center" *ngIf="options.error == 'destroySession'">Esta sessão foi finalizada em outro dispositivo ou navegador.<br> Você será redirecionado à tela de login.</p>

    <p *ngIf="options.message" [innerHTML]='options.message'>
    </p>

    <form class="pb-1">
      <ng-template ngFor let-in [ngForOf]="options.input" let-i="index">
        <p class='label' [innerHTML]='in.label'></p>
        <input class="alertInput" [type]="in.type || 'text'" [value]="in.value" (change)="change($event, i, 'i', in.type)">
      </ng-template>
    </form>

    <form *ngIf="options.showSelectTag">
        <p *ngIf="options.selectOptionsLabel" [innerHTML]="options.selectOptionsLabel"></p>
        <select id="alert-select" class="form-select" required [value]="options.selectOptions ? options.selectOptions[0]?.id : null" (change)="change($event, '0', 's', null)">
          <option *ngFor="let opt of options.selectOptions" [value]="opt.id">{{opt.description}}</option>
        </select>
    </form>

    <div id="alertComponent-buttons" [ngClass]="[options.error ? 'justify-content-center' : '', options.buttonCenter ? 'justify-content-between' : '']">
      <button *ngFor="let b of options.buttons"
        [class]="'btn btn-' + b.type"
        (click)="buttonClicked(b);">
        <strong>{{b.text}}</strong></button>
    </div>

  </section>

</div>
