
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconBoxComponent } from './icon-box.component';

@NgModule({
  declarations: [
    IconBoxComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [],
  exports: [
    IconBoxComponent
  ]
})
export class IconBoxModule { }
