import { Component, OnInit, Input, HostListener, Output, EventEmitter, ViewChild, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgScrollbar } from 'ngx-scrollbar';
import * as moment_ from 'moment';
const moment = moment_;

export const momentDefaultDateFormated = 'DD/MM/YYYY HH:mm'

@Component({
  selector: 'mm-table',
  templateUrl: './mm-table.component.html',
  styleUrls: ['./mm-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MmTableComponent implements OnInit {

  @Input('values') values:any[] = [{Column1: 'value2',Column2: '1', linkTo:"/users"}, {Column1: 'value1',Column2: '2'}]
  @Input('columnNames') columnNames: Array<string> = ['Column1', 'Column2']
  @Input('thClass') thClass: Array<string> = ['text-center', 'text-center']
  @Input('tdClass') tdClass: Array<string> = ['text-center asd', 'text-center']
  @Input('iconClass') iconClass: any = {};
  @Input('headerTooltip') headerTooltip: any = []
  @Input('tableSize') tableSize: string = 'unset';
  @Input('tableWidth') tableWidth: string = '100%';
  @Input('track') track: 'horizontal' | 'vertical' | 'all'

  @Output('tdClick') tdClick = new EventEmitter()

  @ViewChild(NgScrollbar, {static: true}) scroll : NgScrollbar;

  sortArray: Array<string> = ['up']
  innerWidth: any;
  columnVisible: Array<string> = []
  filtered: boolean = false
  filtering: boolean = false
  filter: UntypedFormGroup
  expand: boolean;
  isAObject:boolean

  @HostListener('window:resize', ['$event'])
  OnResize(event){
    this.innerWidth = window.innerWidth
    if (window.innerWidth >= 450) {
      this.getColumVisible()
    }
  }

  constructor(
    private route: Router,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) { }

  isObject(obj: any){
    return obj !== null && typeof obj === 'object'
  }

  update(){
    this.expand = !this.expand;
  }

  ngOnInit() {

    this.filter = this.formBuilder.group({});
    this.innerWidth = window.innerWidth;
    this.getColumVisible();
    this.ActualColumns(this.columnNames)
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.columnNames)
      this.ActualColumns(changes.columnNames.currentValue)
  }

  sort(collumName: string){
    let index = this.columnNames.indexOf(collumName)
    let sort = this.sortArray[index]
    this.sortArray = [];
    typeof sort == 'undefined' ? sort = 'down' : (sort == 'up' ? sort = 'down' : sort = 'up' )
    this.values = this.values.map(val => val).reverse().sort(this.dynamicSort(( sort == 'up' ? '-' : '')+collumName))
    this.sortArray[index] = sort
  }

  linkTo(index, tdindex) {
    if(this.values[index].linkTo){
      if(Array.isArray(this.values[index].linkTo)){
        if(this.values[index].linkTo[tdindex]){
          this.route.navigate([this.values[index].linkTo[tdindex]])
        }
      }else {
        this.route.navigate([this.values[index].linkTo])
      }
    }
    if(this.values[index].eventEmitter){
      this.tdClick.emit({...this.values[index], tdindex})
    }
  }

  iconClick(index, event){
    if(this.values[index].iconEmitter){
      //adicionado preventDefault pra nao interferir no metodo linkTo adicionado no td.
      event.preventDefault();
      if(this.values[index].linkTo){
        event.stopPropagation();
      }
      this.tdClick.emit(this.values[index])
    }
  }

  getColumVisible() {
    if(!this.filtered){
      this.columnVisible = []
      this.columnNames.forEach((v,i) => {
        if(this.innerWidth <= 425 && i<2){
          this.columnVisible.push(v)
        } else if (this.innerWidth > 425) {
          this.columnVisible.push(v)
        }
      })
    }
  }

  setFilters() {
    let form = this.filter.value
    this.columnVisible = []
    Object.keys(form).forEach(key => {
      if(form[key]){
        this.columnVisible.push(key)
      }
    })
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
      let first = typeof a[property] === 'string' ? a[property].toLowerCase() : a[property];
      let second = typeof b[property] === 'string' ? b[property].toLowerCase() : b[property];

      let firstDate = new Date(first);
      let secondDate = new Date(second);

      if (!isNaN(firstDate.getTime()) && !isNaN(secondDate.getTime())) {
          let result = (firstDate > secondDate) ? -1 : (firstDate < secondDate) ? 1 : 0;
          return result * sortOrder;
      } else {
          let result = (first > second) ? -1 : (first < second) ? 1 : 0;
          return result * sortOrder;
      };
    };
  }

  ActualColumns(columns){
    this.filter = this.formBuilder.group({});
    this.innerWidth = window.innerWidth;
    this.getColumVisible();
    columns.forEach(a => {
      // this.sortArray.push('down')
      this.filter.addControl(a, this.formBuilder.control(false))
    })
  }
}
