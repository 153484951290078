import { NgModule, ChangeDetectorRef } from "@angular/core";
import { LoadingSpinnerComponent } from "./loading-spinner.component";
import { CommonModule } from "@angular/common";
import { LoadingSpinner } from "./loading-spinner";
import { SpinnerComponent } from "./spinner/spinner.component";

@NgModule({
    declarations: [
        LoadingSpinnerComponent,
        SpinnerComponent
    ],
    exports: [
        LoadingSpinnerComponent,
        SpinnerComponent
    ],
    imports: [
        CommonModule
    ],
    providers: [
        LoadingSpinner
    ]
})

export class LoadingSpinnerModule{}