<div *ngIf="rooms && rooms.length > 0 && this.loggedUserId" class="chat-tray-list">
  <div
    [ngClass]="{'chat-item':true, 'active':item._id == selected? true : false}"
    (click)="getItem(i)"
    *ngFor="let item of rooms;index as i"
  >
      <div class="room-user-images">
          <mm-chat-users-images [users]="item.users"></mm-chat-users-images>
      </div>
      <div class="room-data">
        <div class="room-info">
          <div class="d-flex justify-content-between">
            <h5 class="room-title">{{item.name}}</h5>
            <span class="room-date" *ngIf="item.lastMessage?.createdDateTimeFmt">{{item.lastMessage?.createdDateTimeFmt?.date}} - {{item.lastMessage?.createdDateTimeFmt?.hour}}</span>
            <span class="room-date" *ngIf="item?.createdDateTimeFmt">{{item?.createdDateTimeFmt?.date}} - {{item?.createdDateTimeFmt?.hour}}</span>
          </div>
          <div class="d-flex justify-content-between">

            <p class="last-message" *ngIf="item.users && item.users.length > 2 && item.lastMessage">{{item.lastMessage?.user.fullName}}: {{item.lastMessage?.text}}</p>
            <p class="last-message" *ngIf="item.users && item.users.length <= 2 && item.lastMessage">{{item.lastMessage?.text}}</p>
            <div
            *ngIf="item.receivedMessage && item.receivedMessage > 0 && item._id != selected && loggedUserId != item.lastMessage?.user.userId"
              class="unread-messages"
            >
            <span>{{item?.receivedMessage}}</span>
          </div>
          </div>
        </div>
      </div>
  </div>
</div>
<div *ngIf="rooms && rooms.length <= 0" class="chat-tray-list d-flex justify-content-center">
  <p>Inicie uma conversa clicando em "Iniciar uma nova conversa"</p>
</div>
