import { Injectable, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';

import { AlertControllerComponent } from './alert-controller.component';
import { Router } from '@angular/router';

@Injectable()
export class AlertController {

  ref: ComponentRef<AlertControllerComponent> = null;
  vcf: ViewContainerRef;
  factory: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router
    ) {
  }

  configure(options) {
    this.ref = this.vcf.createComponent(this.factory);
    this.ref.changeDetectorRef.detectChanges(); 

    if(options.error =='login' && Object.keys(options).length == 1){
      let config = {
        title: 'Oops!',
        error: 'login',
        top: '19%',
        width: '400px',
        left: '57%',
        buttons: [
            {
                  text: 'ok',
                  type: 'primary pt-1 pb-1',
                  handler: () => {
                  this.router.navigate(['login']);
                  }
            }
        ] 
      }
      this.ref.instance.options = config;
    }else {
      this.ref.instance.options = options;
    }
  }

  setViewContainerRef(vcf) {
   this.factory = this.componentFactoryResolver.resolveComponentFactory(AlertControllerComponent);
   this.vcf = vcf
  }

  open() {
    if (!this.ref) {
      throw new Error('No ViewContainerRef set');
    } else {  
      this.ref.instance.active = true;
    }
  }

  close() {
    if (!this.ref) {
       throw new Error('No ViewContainerRef set');
    } else {
      this.ref.destroy();
      this.ref.instance.active = false;
    }

  }

}

