import { Component, OnInit, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'mmp5-alert-controller',
  templateUrl: './alert-controller.component.html',
  styleUrls: ['./alert-controller.component.scss']
})
export class AlertControllerComponent implements OnInit {

  active: boolean = false;
  options: any = {};
  selectValue: any
  formControl: Array<UntypedFormControl>
  error: boolean = false

  @Output('sendInput') sendInput = new EventEmitter;

  constructor(
    public _elementRef: ElementRef,
    public _renderer: Renderer2
  ) {
    _renderer.addClass(_elementRef.nativeElement, 'alert-confirm');
  }

  ngOnInit() {

  }

  change(event, i, type, input_type){
    console.log({event, i, type, input_type})
    if(type == 'i'){

      if(input_type === "file"){
          const file = event.target.files[0];

          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            console.log(reader.result)
            console.log(this.options)
          this.options.input[i].value = reader.result
        }
      }else{
        this.options.input[i].value = event.target.value
      }

    } else {
      this.selectValue = event.target.value
    }
  }

  buttonClicked(button) {
    if (button.handler) {
      if(this.options.input){
        if(this.options.input[0].type="email"){

          let r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let v : string = this.options.input[0].value
          if(r.test(v)){
            if(this.options.selectOptions){
              let val = {
                input: this.options.input,
                select: this.selectValue
              }
              this.sendInput.emit(val)
              button.handler(val);
            } else {
              this.sendInput.emit(this.options.input)
              button.handler(this.options.input);
            }
          } else {
            this.error = true
            if(this.options.selectOptions){
              let val = {
                input: this.options.input,
                select: this.selectValue,
                invalid: true
              }
              this.sendInput.emit(val)
              button.handler(val);
            } else {
              this.sendInput.emit(this.options.input)
              button.handler(this.options.input);
            }
          }
        } else{
          if(this.options.selectOptions){
            let val = {
              input: this.options.input,
              select: this.selectValue
            }
            this.sendInput.emit(val)
            button.handler(val);
          } else {
            this.sendInput.emit(this.options.input)
            button.handler(this.options.input);
          }
        }
      }else {
        button.handler();
      }
    }

    this.active = false;
  }


}
