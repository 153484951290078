export class Device {
  deviceType: string;
  deviceId: string;
  userId: string;
  deviceName: string;
  deviceDescription: string;
  clientId: string;
  deviceToken: string;

  constructor() {
    this.deviceType = "";
    this.deviceId = "";
    this.userId = "";
    this.deviceName = "";
    this.deviceDescription = "";
    this.clientId = "";
    this.deviceToken = "";
  }
}