import { NgModule } from "@angular/core";
import { PaginationComponent } from "./pagination.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations: [
        PaginationComponent
    ],
    imports:[
        CommonModule,
        TranslateModule
    ],
    exports: [
        PaginationComponent
    ]    
})
export class PaginnationModule{
    
}