<section class="mm-box-container">
    <header class="mm-box-header">  {{ 'platformConfig.lgpd.TermOfCollect' | translate }} </header>
    <div class="mm-box-content">
      <ng-scrollbar [track]="'vertical'" [autoHeightDisabled]="false" [appearance]="'standard'">
          <div class="content ct-lgpd">
              <div class="description d-flex" *ngIf="term?.description">
                  <div class="icon">
                      <mmp5-mm-icons [icon]="['icon','icon-info']"></mmp5-mm-icons>
                  </div>
                  <p>{{term?.description}}</p>
              </div>
              <hr class="line-discription" *ngIf="term?.description"/>
              <dynamic-html [ngClass]="{'dynamic-html':true, 'blockCopy': this.blockCopy}" [content]="term?.term"></dynamic-html>
          </div>
      </ng-scrollbar>
      <div class="accept">
          <mmp5-fieldset-haruki
              [formControl]="accept"
              [type]="'checkbox'"
              [checked]="accept?.value"
              [label]="'Li e concordo *'">
          </mmp5-fieldset-haruki>
          <button class="btn btn-primary" [disabled]="!accept?.value" (click)="close()">{{ 'button.accept' | translate }}</button>
      </div>
    </div>
</section>
