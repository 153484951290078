<ol class="breadcrumb">
    <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; last as isLast;">
        <li *ngIf="breadcrumb.label"
            class="breadcrumb-item"
            [ngClass]="{'active': isLast}" aria-current="page">
            <a *ngIf="!isLast; else lastRoute"
                [routerLink]="[breadcrumb.url]"
                routerLinkActive="active">
                {{ breadcrumb.label | translate }}
            </a>

            <ng-template #lastRoute>{{ breadcrumb.label | translate }}</ng-template>
        </li>
    </ng-container>
</ol>

