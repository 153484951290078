import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ColorsState } from "../stores/colors.state";
import { BehaviorSubject } from "rxjs";
import { Color } from "../models/homepage";
import { hexToHSL, hslToHex } from '../utils/colors-utils'
import * as ColorsActions from '../actions/colors.actions'

@Injectable({providedIn: 'root'})
export class HomepageColorsService {

    private themeWrapper = document.querySelector('body');
    private _colors$: BehaviorSubject<Color[]> = new BehaviorSubject([])

    constructor(
        private _colorStore: Store<ColorsState>
    ){
        let colors$ = this._colorStore.select('colors')
        colors$.subscribe((colors: any) => colors && this._colors$.next(colors.colors))
    }

    updateColors(colors: Color[]){
        this._colorStore.dispatch(new ColorsActions.SetColors(colors))
    }

    setColor(ColorId: string){
        let colors = this._colors$.value.filter(color => color.id == ColorId)
        
        if(colors.length > 0){
            this.colorsConfig(colors[0])
        } else {
            let defaultColors = {
                first:'#ff5e3a',
                second: '#515365',
                third: '#38a9f6',
                fourth: '08ddc1',
                fifth: '#ffdc1b',
                sixth: '#7c5ac2',
            }
            this.colorsConfig(defaultColors)
        }
    }
    colorsConfig(defaultColors: { first: any; second: any; third: any; fourth: any; fifth: any; sixth: any; }) {
        let {first, second, third, fourth, fifth, sixth} = defaultColors

        // colors for mobile google chrome
        document.querySelector('meta[name="theme-color"]').setAttribute("content", second);
     
        first && this.themeWrapper.style.setProperty('--primary', first)
            second && this.themeWrapper.style.setProperty('--dark', second)
            third && this.themeWrapper.style.setProperty('--link', third)
            third && this.themeWrapper.style.setProperty('--third', third)
            third && this.themeWrapper.style.setProperty('--secondary', third)
            fourth && this.themeWrapper.style.setProperty('--fourth', fourth)
            fifth && this.themeWrapper.style.setProperty('--fifth', fifth)
            sixth && this.themeWrapper.style.setProperty('--sixth', sixth)
    
            if (first){
                this.setProperty('--light-primary', first);
            }
            if (second){
                this.setProperty('--light-dark', second)
            }
            if (third){
                this.setProperty('--light-link', third)
                this.setProperty('--light-secondary', third)
            }
            if (fourth){
                this.setProperty('--light-fourth', fourth);
            }
            if (fifth){
                this.setProperty('--light-fifth', fifth)
            }
            if (sixth){
                this.setProperty('--light-sixth', sixth)
            }
    }
    setProperty(prop: string, color: any) {
        let hslColor = hexToHSL(color);

        hslColor['l'] = hslColor['l'] * 125;
        hslColor['h'] = hslColor['h'] * 360; 
        hslColor['s'] = hslColor['s'] * 100;          
        
        if(hslColor['l'] >= 90){
            hslColor['l'] = 90
        }

        this.themeWrapper.style.setProperty(prop, `hsl(${hslColor['h']},${hslColor['s']}%,${hslColor['l']}%)`)
    }
}