import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightComponent } from './highlight.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MmToastModule } from 'mm-ui';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { HighlightDataComponent } from './highlight-data/highlight-data.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [HighlightComponent, HighlightDataComponent],
    imports: [CommonModule, MatTooltipModule, MmToastModule, RouterModule, OverlayModule],
    exports: [HighlightComponent, TranslateModule],
    providers: []
})
export class HighlightModule { }
