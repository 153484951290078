import { Injectable, ViewContainerRef, Component, ElementRef, Injector, InjectionToken } from "@angular/core";
import { Overlay, OverlayConfig, ConnectionPositionPair, PositionStrategy } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { PopUpComponent } from "./pop-up.component";


@Injectable({ providedIn: 'root' })
export class PopUpService {

  vcr: ViewContainerRef
  component: ElementRef

  constructor(
    private overlay: Overlay,
    private _injector: Injector
  ) { }

  public setViewConRef(vcr: ViewContainerRef) {
    this.vcr = vcr
  }

  public setComponent(component) {
    this.component = component
  }

  show() {
    let config = new OverlayConfig({
      positionStrategy:
        // this.overlay
        //     .position()
        //     .connectedTo(this.component, { originX: 'end', originY: 'center' }, { overlayX: 'start', overlayY: 'top' })
        //     .withOffsetX(65)
        //     .withOffsetY(-30)
        this.overlay
          .position()
          .flexibleConnectedTo(this.component)
          .withPositions([{ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'top' }])
          .withDefaultOffsetX(65)
          .withDefaultOffsetY(-30)
      //positionStrategy: this.overlay.position().flexibleConnectedTo(this.component).withDefaultOffsetX(65).withDefaultOffsetY(-30)
    });
    const overlayRef = this.overlay.create(config);
    const popUpPortal = new ComponentPortal(PopUpComponent, this.vcr);
    overlayRef.attach(popUpPortal)
    // const overlayRef = this.overlay.create(this.getOverlayConfig(origin));
  }

  private getOverlayConfig({ origin, width, height }): OverlayConfig {
    return new OverlayConfig({
      width,
      height,
      hasBackdrop: true,
      backdropClass: 'popover-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions(this.getPositions())
      .withPush(false);

    return positionStrategy;
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom'
      },
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      },
    ]
  }
}
