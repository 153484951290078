import { Component, OnInit, Inject, Injectable, Input, ViewContainerRef, Output, EventEmitter, ApplicationRef } from '@angular/core';
// import { PORTAL_DATA } from '../page-actions.component';
import { fadeOpacity } from 'projects/f-cms/src/app/animations/animations';
import { Router, NavigationStart } from '@angular/router';
import { CreatePageService } from '../../../create-page/create-page.service';
import { ContentService } from '../../../content.service';
import { BehaviorSubject } from 'rxjs';
import { AlertController } from 'mm-ui';
import { AppComponent } from 'projects/f-cms/src/app/app.component';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PageActionsDialogComponent } from 'projects/f-cms/src/app/components/cms/content/menu/page-actions-dialog/page-actions-dialog.component';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  animations: [fadeOpacity]
})
export class ViewPageActionComponent implements OnInit {

  @Input ('id') public id: string
  @Input ('imported') public imported: boolean
  @Input ('homepage') public homepage: string;
  @Input ('menuId') public menuId: string;
  @Input ('deleted') public deleted: BehaviorSubject<boolean>
  @Input ('hasInternalPages') public hasInternalPages: boolean;
  @Input ('importation') public importation: boolean;
  @Input ('itemTitle') public itemTitle: string;
  @Input ('type') type: 'PAGE' | 'HOMEPAGE'
  @Input ('userRole') userRole: string;

  @Output('closed') public closed = new EventEmitter();

  canEdit: boolean;
  hover: boolean[] = [false, false,false, false, false, false]

  constructor(
     private _router: Router,
     private pageService: CreatePageService,
     private appRef: ApplicationRef,
     private contentService: ContentService,
     private alertController: AlertController,
     private vcr: ViewContainerRef,
     private messageService: MessageService,
     private dialog: MatDialog,
     private _translate: TranslateService
     ) {
    }

  ngOnInit() {
      let vcra = (this.appRef.components[0].instance as AppComponent).vcr
      this.alertController.setViewContainerRef(vcra);
      this.canEdit = this.userRole == 'CI' ? true : false
  }

  goTo(status: string){
    if(status == 'page'){
      if(this.homepage){
        this._router.navigate(['cms','content','page',this.homepage, this.id])
      } else {
        this._router.navigate(['cms','content','page',this.id])
      }
    } else if (status == 'edit') {
      if(this.homepage){
        this._router.navigate(['cms','content','edit', this.id, this.homepage, this.menuId]);
      } else {
        this._router.navigate(['cms','content','edit-homepage', this.id])
      }
    }
  }

  openDialog(type: string){
    let id = this.id;
    let itemType = !this.homepage ? 'HOMEPAGE' : 'PAGE'
    let hasInternalPages = this.hasInternalPages;
    let itemTitle = this.itemTitle;

    let dialogRef = this.dialog.open(PageActionsDialogComponent, {minWidth: '50vw', maxWidth: '90vw', data: {type, id, itemType, hasInternalPages, itemTitle}})

    dialogRef.afterClosed().subscribe(data => {
      console.log('closed');
    })
  }

  delete(){

    if(!this.homepage){
      this.alertPopup('homepage', this._translate.instant('cms.content.menu.remove'), this._translate.instant('cms.content.menu.removeHome'), this._translate.instant('cms.content.menu.wantRemoveHome'), this.contentService.deleteHomepage(this.id))
    } else {

      if(this.imported == true){

        this.alertPopup('page', this._translate.instant('cms.content.menu.remove'), this._translate.instant('cms.content.menu.removePage'), this._translate.instant('cms.content.menu.wantRemovePage'), this.pageService.deleteImportationPage(this.menuId))
      }else{
        this.pageService.hasImportations(this.id)
        .subscribe(resp => {
          if(resp.hasImportation){

            this.alertPopup('page', this._translate.instant('cms.content.menu.remove'), this._translate.instant('cms.content.menu.removePage'), this._translate.instant('cms.content.menu.deletingItWill'), this.pageService.deletePage(this.menuId,this.id, {removeImportations:true}))
          }else{

            this.alertPopup('page', this._translate.instant('cms.content.menu.remove'), this._translate.instant('cms.content.menu.removePage'), this._translate.instant('cms.content.menu.wantRemovePage'), this.pageService.deletePage(this.menuId, this.id, {}))
          }
        })
      }
    }
  }

  alertPopup(type, title, text, message, endpoint ){

    const config =  {
      showSelectTag: false,
      width: '30%',
      top: '200px',
      height: '0px',
      left: '0',
      title: title,
      buttonCenter: true,
      message: message,
      buttons: [
      {
        text: this._translate.instant('button.cancel'),
        type: 'outline-dark',
        handler: () => {}
      },
      {
        text: text,
        type: 'primary',
        handler: () => {

          endpoint.subscribe(
            () => this.successDeleted(type),
            err => {

              console.log({deleteError:err});
              this.cantDelete(type)}
          )
        }
      }
      ]
    }
    this.alertController.configure(config);
    this.alertController.open();
  }


  cantDelete(arg0): void {
    this.deleted.next(false);

    if(arg0 == 'page'){
      this.messageService.add({severity: 'error', summary: this._translate.instant('cms.content.menu.deleteErrorPage'), detail: this._translate.instant('cms.content.menu.deleteError')});
    } else {
      this.messageService.add({severity: 'error', summary: this._translate.instant('cms.content.menu.deleteErrorHome'), detail: this._translate.instant('cms.content.menu.deleteError')});
    }
    this._router.navigate([], {fragment: 'mmp-nav-margin'});

  }

  successDeleted(arg0: string): void {

    if(arg0 == 'page'){
      this.messageService.add({severity: 'success', summary: this._translate.instant('cms.content.menu.deleteSuccessPage'), detail: this._translate.instant('cms.content.menu.deleteSuccess')});
    } else {
      this.messageService.add({severity: 'success', summary: this._translate.instant('cms.content.menu.deleteSuccessHome'), detail: this._translate.instant('cms.content.menu.deleteSuccess')});
    }
    this._router.navigate([], {fragment: 'mmp-nav-margin'})

  }

}
