import { CustomAlertComponent } from "../page/custom-alert/custom-alert.component";
import { ComponentWithSelector, DirectiveWithSelector } from "./options";
import { MediaComponent } from "../page/media-view/media/media.component";
import { MediaLinkDirective } from "../../../directives/media-link/media-link.directive";
import { HighlightComponent } from "../highlight/highlight.component";

export const components: ComponentWithSelector[] = [
        {
            component: CustomAlertComponent,
            selector: 'custom-alert'
        },
        {
            component: MediaComponent,
            selector: 'media'
        },
        {
            component: HighlightComponent,
            selector: 'mm-highlight'
        }
]

export const directives: DirectiveWithSelector[] = [
    {
        selector: 'medialink',
        directive: MediaLinkDirective,
        element: 'a'
    }
]
