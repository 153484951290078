
import { Action } from '@ngrx/store'
import { UserInterface } from '../../../interfaces/userInterface';

export const SET_USER = '[USERS] Set';
export const RESET_FORM = '[USERS] Reset';

export class SetUser implements Action{
    readonly type = SET_USER;

    constructor(public payload: UserInterface){}
}

export class ResetForm implements Action {
    readonly type = RESET_FORM;

    constructor(){}
}

export type Actions = SetUser | ResetForm
