<img [src]="file" alt="cropper-image" class="img-fluid" #image crossorigin="anonymous">
<footer class="d-flex justify-content-between">
  <button class="btn btn-primary" (click)='onCrop($event)'>{{ 'button.crop' | translate }}</button>

  <div class="control-buttons">

      <div class="control-buttons">

        <div class="button-media" *ngIf="showThisButtons?.flipRight">
          <div class="btn-margin">
              <button class="btn btn-lines btn-media" (click)='flipRight($event)'>
                  <div>
                    <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-1','icon-size ng-star-inserted']" ></mmp5-mm-icons>
                    <p class="title mb-0">Girar</p>
                  </div>
              </button>
          </div>
        </div>
        <div class="button-media" *ngIf="showThisButtons?.flipLeft">
          <div class="btn-margin">
              <button class="btn btn-lines btn-media" (click)='flipLeft($event)'>
                  <div>
                    <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-1','icon-size','ng-star-inserted']" ></mmp5-mm-icons>
                    <p class="title mb-0">Girar</p>
                  </div>
              </button>
          </div>
        </div>
        <div class="button-media" *ngIf="showThisButtons?.rotateRight">
          <div class="btn-margin">
              <button class="btn btn-lines btn-media" (click)='rotateRight($event)'>
                  <div>
                    <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-1','icon-size','ng-star-inserted']" ></mmp5-mm-icons>
                    <p class="title mb-0">Girar</p>
                  </div>
              </button>
          </div>
        </div>
        <div class="button-media" *ngIf="showThisButtons?.rotateLeft">
          <div class="btn-margin">
              <button class="btn btn-lines btn-media" (click)='rotateLeft($event)'>
                  <div>
                    <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-2','icon-size','ng-star-inserted']" ></mmp5-mm-icons>
                    <p class="title mb-0">Girar</p>
                  </div>
              </button>
          </div>
        </div>

        <div class="button-media" *ngIf="showThisButtons?.scaleX">
          <div class="btn-margin">
              <button class="btn btn-lines btn-media" (click)='scaleX($event)'>
                  <div>
                    <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-3','icon-size','ng-star-inserted']" ></mmp5-mm-icons>
                    <p class="title mb-0">Inverter H</p>
                  </div>
              </button>
          </div>
        </div>
        <div class="button-media" *ngIf="showThisButtons?.scaleY">
          <div class="btn-margin">
              <button class="btn btn-lines btn-media" (click)='scaleY($event)'>
                  <div>
                    <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-4','icon-size','ng-star-inserted']" ></mmp5-mm-icons>
                    <p class="title mb-0">Inverter V</p>
                  </div>
              </button>
          </div>
        </div>
        <div class="button-media" *ngIf="showThisButtons?.zoomPlus">
          <div class="btn-margin">
              <button class="btn btn-lines btn-media" (click)='zoomPlus($event)'>
                  <div>
                    <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-1','icon-size','ng-star-inserted']" ></mmp5-mm-icons>
                    <p class="title mb-0">Zoom +</p>
                  </div>
              </button>
          </div>
        </div>
        <div class="button-media" *ngIf="showThisButtons?.zoomMinus">
          <div class="btn-margin">
              <button class="btn btn-lines btn-media" (click)='zoomMinus($event)'>
                  <div>
                    <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-1','icon-size','ng-star-inserted']" ></mmp5-mm-icons>
                    <p class="title mb-0">Zoom -</p>
                  </div>
              </button>
          </div>
        </div>
        <div class="button-media" *ngIf="showThisButtons?.reset">
          <div class="btn-margin">
              <button class="btn btn-lines btn-media" (click)='reset($event)'>
                  <div>
                    <mmp5-mm-icons [icon]="['icon','icon-editor-de-imagem-7','icon-size','ng-star-inserted']" ></mmp5-mm-icons>
                    <p class="title mb-0">Desfazer</p>
                  </div>
              </button>
          </div>
        </div>
      </div>
  </div>
  <button class="btn btn-outline-dark" (click)='onCancel($event)'>{{ 'button.cancel' | translate }}</button>
</footer>
