import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GraphOptions } from './graph-options.component';
import { GoogleChartComponent } from 'angular-google-charts'
 
@Component({
    selector: 'mmp5-graph',
    templateUrl: './graph.component.html',
    styleUrls: ['./graph.component.scss']
})

export class GraphComponent implements OnInit {
 
    googleChart : GoogleChartComponent;

    @Input('type') type: any = 'PieChart';
    @Input('elementId') elementId: string;
    @Input('columnName') columnName: Array<string> = ["Dia","Usuarios"];
    @Input('title') title: string = "Usuarios Logados";
    @Input('width') width: number = 280;
    @Input('height') height: number = 162;
    @Input('options') options: Object = {};
    @Input('data') data: Array<Array<any>> = [
        ['1', 2],
        ['2', 5],
        ['3', 17],
        ['4', 50],
        ['5', 80]
      ];

    opt: GraphOptions;
    
    constructor() {
    
    }
    ngOnInit(): void {
        
    }
}