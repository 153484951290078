<div class="template"
  [ngClass]="{'hand': template.value.clickArea == 'FULL_IMAGE' }"
  (click)="gotoPage('image')"
  [style.background]="template.value.banner ? (template.value.banner | authImage:'background':iTk:mdSrvc  | async) : (template.value?.bannerImage?.mediaUrl | authImage:'background':iTk:mdSrvc | async)"
  [ngStyle]="{'background-size':'cover'}"
  >
  <img *ngIf="mobile" [src]="(template.value.banner || template.value?.bannerImage?.mediaUrl) | authImage:'image':iTk:mdSrvc | async" (click)="gotoPage('image')" alt="">
  <div class="box__overlap" *ngIf="template.value.displayTitle && template.value.displayTitle">
    <div class="overlap__content">
      <h4 *ngIf="template.value.displayTitle">{{template.value.title}}</h4>
      <p *ngIf="template.value.displayDescription">{{template.value.description}}</p>
      <button *ngIf="template.value.clickArea == 'BUTTON'" (click)="gotoPage('button')" class="btn btn-primary">{{template.value.labelButton}}</button>
    </div>
  </div>
</div>
<!-- [ngStyle]="{'background-size':'cover', 'background-image': 'url(' + (template.value.banner || template.value?.bannerImage?.mediaUrl) + ')', 'background-repeat': 'no-repeat'}" -->
