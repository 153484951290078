<div class="custom-alert mt-1 mb-1">
    <div class="content" [ngStyle]="{'borderTop': '3px solid','borderTopColor': config?.lineAndIcoColor, 'backgroundColor': config?.backgroundColor}">
        <div class="icon__name">
            <div *ngIf="config?.mediaType != mediaType.empty" class="icon" [ngStyle]="{'color': config?.lineAndIcoColor || 'var(--primary)'}">
                <mmp5-mm-icons
                  class="alert-icon"
                  [icon]="config.ico"
                  *ngIf="config?.mediaType === mediaType.ico">
                </mmp5-mm-icons>
                <img *ngIf="config?.mediaType == mediaType.image && (config?.base64 || config?.customAlertImage?.mediaUrl)"
                    [src]="config?.base64 || (config?.customAlertImage?.mediaUrl | authImage:'image':iTk:mdSrvc | async)" class="img-icon" />
                <div *ngIf="config?.showTitle" class="title">
                    <span class="alert-title">{{config?.title}}</span>
                </div>
            </div>
            <div class="alert__text">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
