import { Page } from "../models/page";
import { FormArray, AbstractControl, FormControl, ValidationErrors } from "@angular/forms";
import { ColumnWidget } from "../models/homepage";
import { Media } from "../models/Media";

// Generated by https://quicktype.io
export interface Feature {
  displayTitle?: boolean;
  title?: string;
  featureType: featureType;
  pageId?: string;
  templateId?: string;
  id?: string;
}

export interface TitleFeatureConfig extends Feature {
  textSize: string;
  order: number;
  id: string;
  content?: any
  size?: any;
  galleryAlignment?: any;
}

export enum featureType {
  pageTitle = 'PAGE_TITLE',
  media = 'MEDIA',
  pageDesc = 'PAGE_DESCRIPTION',
  pageList = 'PAGE_LIST',
  breadcrumb = 'BREADCRUMB',
  pageDates = 'PAGE_DATES',
  htmlField = 'HTML_FIELD',
  coverImage = 'COVER_IMAGE',
  fixedText = 'FIXED_TEXT',
  print = 'PRINT',
  editNote = 'EDIT_NOTE',
  workflow = 'WORKFLOW',
  revisionTable = 'REVISION_TABLE',
  viewEditRule = 'PERMISSION',
  imageGallery = 'IMAGE_GALLERY',
  displayMedia = 'DISPLAY_MEDIA',
  htmlPlus = 'HTML_PLUS',
  formPage = 'FORM_PAGE',
  formTemplate = 'FORM_TEMPLATE',
  glossary = 'GLOSSARY',
  quiz = 'QUIZ_PAGE',
  course = 'COURSE',
  flowsurvey = 'FLOW_SURVEY',
  tag = 'TAG',
  customEmail = 'CUSTOM_EMAIL',
  spreadsheet = 'SPREADSHEET',
  rooms = 'ROOMS',
  support = 'SUPPORT',
  redirect = 'REDIRECT',
}

export enum featureContentType {
  HTML_FIELD = "HTML_FEATURE_CONTENT",
  PAGE_LIST = "PAGE_LIST_FEATURE_CONTENT"
}

export enum HTMLplusContentGridType {
  WHOLE = 'WHOLE',
  HALVES = 'HALVES',
  THIRDS = 'THIRDS',
  THIRDS_TWO_ONE = 'THIRDS_TWO_ONE',
  THIRDS_ONE_TWO = 'THIRDS_ONE_TWO',
  FOURTHS = 'FOURTHS',
  FIFTHS_TWO_THREE = 'FIFTHS_TWO_THREE',
  FIFTHS_THREE_TWO = 'FIFTHS_THREE_TWO',
  CUSTOM = 'CUSTOM'
}

export enum HTMLPlusContentGridTypeColumnSize {
  WHOLE = 1,
  HALVES = 2,
  THIRDS = 3,
  THIRDS_ONE_TWO = 2,
  THIRDS_TWO_ONE = 2,
  FOURTHS = 4,
  FIFTHS_THREE_TWO = 2,
  FIFTHS_TWO_THREE = 3,
}

export interface HTMLPlusConfig extends Feature {
  displayTitle: boolean;
  title: string;
  order: number;
  content: {
    contents: HTMLPlusContent[],
    id: string
  };
  value: HTMLPlusContent[]
}

export interface HTMLPlusContent extends HTMLContent {
  contents: HTMLPlusContent[];
  title?: string;
  id?: string;
  identifier?: string;
  order?: number;
  images?: any[];
  galleryType?: string;
  displayTitle?: boolean;
  mediaInfo?: Record<string, any>;
  customAlert?: Record<string, any>;
  gridType?: HTMLplusContentGridType;
  backgroundColor?: string;
  borderSize?: number;
  borderColor?: string;
  columnSpan?: number;
  columnAmount?: number;
  columnSize?: string;
}

// Generated by https://quicktype.io

export interface HTMLFeatureConfig extends Feature {
  limitCharacters: boolean;
  numberOfCharacters: number;
  enableFunctionHtml: string[];
  displayScrolling: boolean;
  maximumHeight: number;
  displayTitle: boolean;
  allowImages: boolean;
  allowFlaps: boolean;
  allowCollapses: boolean;
  allowCustomAlerts: boolean;
  order: number;
  title: string;
  content?: HTMLContent;
  size?: any;
  galleryAlignment?: any;
}

export interface HTMLContent {
  id?: string;
  contentType?: string;
  htmlContent?: any;
  htmlContentDynamic?: any;
}

// Generated by https://quicktype.io

export interface ListPageConfig extends Feature {
  pageViewType: string;
  ordination: string;
  displayForm: string;
  showDescription: string;
  limitDescription: number;
  canPaginate: string;
  itensPerPage: number;
  order: number;
  content?: PageListContent;
  size?: any;
  galleryAlignment?: any;
  showCoverImageMiniature?: any;
  showCoverImageDefault?: any;
  showAccessStatus?: any;
  showCreationDate?: any;
  showModifiedDate?: any;
  showPublishingDate?: any;
  showReadingStatus?: any;

  totalPages?: number;
}

// Generated by https://quicktype.io

export interface PageDescConfig extends Feature {
  textSize: string;
  order: number;
  showDescriptionItalic: boolean;
}

// Generated by https://quicktype.io

export interface FixedTextConfig extends Feature {
  displaySize: string;
  showTextItalic: boolean;
  showTextBold: boolean;
  fixedText: string;
}

// Generated by https://quicktype.io

export interface PrintConfig extends Feature {
  dontPrint: boolean;
  showWaterMarkWorkflow: boolean;
  showIsoHead: boolean;
  showIsoFooter: boolean;
}

// Generated by https://quicktype.io

export interface PageListContent {
  id: string;
  feature: Feature;
  contentType: string;
  pages?: Page[]
}

export interface SidebarConfig {
  side: string;
  widgets: ColumnWidget[]
  id: string;
  new?: boolean;
}

export interface SidebarWidget {
  type: featureType;
  order: number
}

export interface PageListPage {
  page?: Page | Page[];
  id?: string;
  pageId?: string;
}

export interface BreadcrumbFeatureConfig extends Feature {
  textSize: string;
  separator: string;
  itemsBetweenEllipsis: number;
  showActualPage: string;
  order: number;
  displayLimit: boolean;
  labelFirstLevel: string;
  content: any;
  size?: any;
  galleryAlignment?: any;
  creationDate?: any;

}

export interface PageDateFeatureConfig extends Feature {
  displayDate?: string,
  showHour?: boolean,
  displayForm: string,
  showAuthor: boolean,
  showAuthorPhoto: boolean,
  showAuthorLink: boolean,
  showUpdateDate?: boolean,
  showPublishDate?: boolean,
  showCreationDate?: boolean,
  order: number,
  showDate?: boolean,
}

export interface coverImageConfig extends Feature {
  alignment: string,
  enlargeImageCover: boolean,
  order: number
}

export interface NoteFeatureConfig extends Feature {
  displayTitle: boolean,
  title: string,
  textSize: string,
  order: number
}

export interface RevisionTableFeatureConfig extends Feature {

  displayNumbersAndDates: boolean,
  displayDescriptionChange: boolean,
  displayResponsibleArea: boolean,
  numberVersion?: number,
  versionNumber?: number,
  limitDisplay: boolean,
  expectedDate: boolean,
  order: number
}

export interface CustomEmailFeatureConfig extends Feature {
  emailVariables?: CustomEmaiFeaturelVariableConfig[],
  emailSubject?: (string | ((control: AbstractControl) => ValidationErrors))[],
  emailBody?: (string | ((control: AbstractControl) => ValidationErrors))[]
}

export interface CustomEmaiFeaturelVariableConfig {
  id: string,
  variableName: string,
  variableCode: string
}

// Generated by https://quicktype.io

export interface WorkflowConfig extends Feature {
  displayTitle: boolean;
  title: string;
  showPhases: boolean;
  showResponsibles: boolean;
  showPhaseResponsibles: boolean;
  showPhaseDeadlines: boolean;
}

export interface ImageGalleryConfig extends Feature {
  galleryType: GalleryTypeEnum,
  size: sizeEnum,
  galleryAlignment: alignEnum,
  canLimitImages: boolean,
  displayTitle: boolean,
  showDescription: boolean,
  imagesLimit: number,
  content?: ImageGalletyContent
}

export interface ImageGalletyContent {
  title: string,
  images: {
    mediaId: string,
    order: number
  }[]
}

export interface DisplayedMediaContent {

  displayedMedias: string[];
  medias?: any[];
}

export interface DisplayMediaConfig extends Feature {
  displayForm: DisplayMediaTypeEnum,
  orderingType: orderingMediaTypeEnum,
  showDescription: boolean,
  limitDescriptionSize: boolean,
  descriptionSize: number,
  showCreationDate: boolean,
  showLastEditDate: boolean,
  showThumbnails: boolean,
  showImageThumbnails: boolean,
  showPagination: boolean,
  itemsPerPage: number,
  accessStatus: boolean,
  download: boolean,
  showViewButton: boolean,
  image: boolean,
  video: boolean,
  document: boolean,
  sheet: boolean,
  presentation: boolean,
  pdf: boolean,
  other: boolean,
  mediaSize: number,
  content?: DisplayedMediaContent

}

export enum alignEnum {
  left = 'LEFT',
  center = 'CENTER',
  right = 'RIGHT'
}

export enum sizeEnum {
  small = 'SMALL',
  medium = 'MEDIUM',
  large = 'BIG'
}

export enum GalleryTypeEnum {
  gallery = 'GALLERY',
  mural = 'MURAL',
  album = 'ALBUM',
  masonry = 'MASONRY'
}

export enum DisplayMediaTypeEnum {
  list = 'LIST',
  two_columns = 'TWO_COLUMNS',
  three_columns = 'THREE_COLUMNS',
  four_columns = 'FOUR_COLUMNS'
}

export enum orderingMediaTypeEnum {
  ascending_alphabetical = 'ASCENDING_ALPHABETICAL',
  descending_alphabetical = 'DESCENDING_ALPHABETICAL',
  news_creation_date = 'NEWS_CREATION_DATE',
  news_last_modified_date = 'NEWS_LAST_MODIFIED_DATE',
  oldest_creation_date = 'OLDEST_CREATION_DATE',
  oldest_last_modified_date = 'OLDEST_LAST_MODIFIED_DATE',

}

export enum BreadCrumbSeparator {
  'BAR' = '/',
  'ARROW' = '>',
  'TRACE' = '-'
}

export interface ViewEndEditRules extends Feature {
  templateId: string;
  displayTitle: boolean;
  title: string;
  displayIcon: boolean;
  displayReadPermission: boolean;
  displayWritePermission: boolean;
  order: number;
}
