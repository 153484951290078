<div
  [attr.data-identifier]="key"
  [ngClass]="['fieldset--haruki', formControl?.value != '' && formControl?.value != null ? 'filled' : '' , !value && value != 0 ? 'fieldset--haruki-full': '', type == 'number' ? 'input-group': '', type != 'number' ? 'no-input-group': '', contentClass ]"
>

  <div class="input-group-prepend" *ngIf="type == 'number'" [attr.data-small]="small">
    <button [ngClass]="['btn', 'btn-'+colorPallet]" (click)="step($event, -numberStepper)" [disabled]="formControl.disabled || disabled">
      -
    </button>
  </div>

  <div class="input-group-prepend" *ngIf="dropdown && dropdownPosition == 'before'">
    <button class="btn btn-outline-secondary dropdown-toggle" type="button" [disabled]="dropdownDisabled" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{dropdownName}}</button>
    <div class="dropdown-menu">
      <a class="dropdown-item" (click)="selectDropdown(drop)" *ngFor="let drop of dropdown">{{drop}}</a>
    </div>
  </div>

  <span *ngIf="type == 'date' && !(disabled || formControl.disabled)" [class]="type" (click)="focus($event)" >
    <mmp5-mm-icons [attr.data-tooltip]="tooltip != ''" [icon]="icon" class="iconsize"></mmp5-mm-icons>
  </span>
  <div class="input-label">
    <input
      [class]="type + '&nbsp;' + class"
      *ngIf="!selectOptions && type != 'color' && type !='switch' && type !='checkbox' && type !='checkboxGroup' && type !='textarea' && type !='radio' && type != 'slider' && type != 'phone' && type != 'file' && type != 'colorPicker'"
      #defaultinput
      [attr.data-small]="small"
      [type]="type != 'number' && type != 'date' ? type : 'text'"
      [disabled]="formControl.disabled || disabled"
      [id]="'input_' + key"
      [ngClass]="{'form-control': true, 'input-number': type == 'number', 'input-date': type == 'date', 'hide-search-input-x': type == 'search' || hideX}"
      [mask]="type != 'date' ? mask : dateMask"
      [value]="value"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [specialCharacters]="specialCharacters"
      [patterns]="patterns"
      [attr.maxLength]="maxLength ? maxLength : null"
      (blur)="blur($event)"
      (change)="onChange($event)"
      (cut)="onChange($event)"
      (focus)="focus($event)"
      (paste)="onChange($event)"
      [attr.data-label]="label ? 'true' : 'false'"
      (keyup)="onKeyup($event)"
      (keypress)="onKeypress($event)"
      [attr.autocomplete]="autocomplete ? autocomplete : key && key == 'false' ? 'off' : key"
      [attr.data-overflow]="type == 'number' ? '0' : (tooltip ? 2 : 1)"
      [attr.max]="type=='number' && max ? max : null"
      [attr.min]="type=='number' && min ? min : null"
      [readonly]="readOnly"

    />
    <label [for]="'input_' + key" [ngClass]="'input_' + key" *ngIf="type != 'file' && type != 'switch' && type != 'number' && type != 'checkbox' && type != 'checkboxGroup'" >
      <span class="filled-span">{{ label }}</span>
    </label>
  </div>

  <div class="input-group-append" *ngIf="dropdown && dropdownPosition == 'after'">
    <button class="btn btn-outline-secondary dropdown-toggle" [disabled]="dropdownDisabled" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{dropdownName}}</button>
    <div class="dropdown-menu">
      <!-- <div role="separator" class="dropdown-divider"></div> -->
      <a class="dropdown-item" (click)="selectDropdown(drop)" *ngFor="let drop of dropdown">{{drop}}</a>
    </div>
  </div>

  <div *ngIf="type == 'file'">
    <input
      #defaultinput
      class="form-control input-file"
      [id]="'input_' + key"
      [attr.data-small]="small"
      [type]="'file'"
      [multiple]="multipleFiles ? 'multiple' : ''"
      [accept]="accept ? accept : ''"
      [attr.autocomplete]="autocomplete ? autocomplete : key"
      [attr.data-label]="label ? 'true' : 'false'"
      [value]="null"
      (blur)="blur($event)"
      (change)="onChange($event)"
      (cut)="onChange($event)"
      (focus)="focus($event)"
      (paste)="onChange($event)"
      (keyup)="onKeyup($event)"
      (keypress)="onKeypress($event)"
      [attr.data-overflow]="(tooltip ? 2 : 1)"
    />
  </div>

  <input
    *ngIf="type == 'phone'"
    [attr.max]="type=='phone' && max ? max : null"
    [attr.min]="type=='phone' && min ? min : null"
    [disabled]="formControl.disabled || disabled"
    [ngClass]="'form-control'"
    [id]="'input_' + key"
    [type]="'text'"
    [dropSpecialCharacters]="dropSpecialCharacters"
    [specialCharacters]="specialCharacters"
    [patterns]=""
    [attr.data-small]="small"
    [attr.data-label]="label ? 'true' : 'false'"
    [dropSpecialCharacters]="true"
    mask="{{phoneMask}}"
    (keyup)="onKeyup($event)"
    (blur)="blur($event)"
    [(ngModel)]="value"
  />
  <input
    *ngIf="type == 'color'"
    [disabled]="formControl.disabled || disabled"
    [id]="'input_' + key"
    [ngClass]="{'form-control': true, 'input-color': true }"
    mask="{{mask}}"
    [value]="value"
    [dropSpecialCharacters]="dropSpecialCharacters"
    [specialCharacters]="specialCharacters"
    [patterns]=""
    (blur)="blur($event)"
    (change)="onChange($event)"
    (keyup)="onKeyup($event)"
    [(colorPicker)]="color"
    [cpOutputFormat]="'hex'"
    [cpPosition]="position"
    [attr.data-label]="label ? 'true' : 'false'"
    [cpOKButton]="true"
    [cpSaveClickOutside]="false"
    (colorPickerSelect)="onColorPickerSelect(color)"
    (colorPickerClose)="onColorPickerClose()"
    [attr.autocomplete]="autocomplete ? autocomplete : key"
  />

  <!-- <div *ngIf="type == 'file'" class="fieldset--haruki-filearea">
    <div [ngClass]="{'btn-file':true,' has-icon': fileButtonIcon}">
      <button class="btn btn-primary" (click)="openDialog($event)">
        <mmp5-mm-icons [icon]="['fa-solid','fa-paperclip']"></mmp5-mm-icons>
        {{ label }}
      </button>
      <div class="ms-1" *ngIf="hasFile && !filePreview">{{ hasFile }}</div>
      <div class="ms-1" *ngIf="!hasFile && !filePreview">{{ fileName ? fileName : ('users.management.createuser.nochoosefile' | translate)}}</div>
    </div>
  </div> -->
  <div *ngIf="type == 'colorPicker'" class="d-flex">
    <input
      [disabled]="formControl.disabled || disabled"
      [id]="'input_' + key"
      [ngClass]="{'form-control': true, 'input-color': true }"
      mask="{{mask}}"
      [value]="value"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [specialCharacters]="specialCharacters"
      [patterns]=""
      (blur)="blur($event)"
      (change)="onChange($event)"
      (keyup)="onKeyup($event)"
      [(colorPicker)]="color"
      [cpOutputFormat]="'hex'"
      [cpPosition]="position"
      [attr.data-label]="label ? 'true' : 'false'"
      [cpOKButton]="true"
      [cpSaveClickOutside]="false"
      (colorPickerSelect)="onColorPickerSelect(color)"
      (colorPickerClose)="onColorPickerClose()"
      [attr.autocomplete]="autocomplete ? autocomplete : key"
    />
    <div [(colorPicker)]="color" [cpOutputFormat]="'hex'" [cpOKButton]="true" (colorPickerChange)="onColorPickerSelect($event)"
      [ngStyle]="{'background': color ? color : '#FFFFFF'}"
      class="view-color border-color"
      [attr.data-color]="color">
    </div>
  </div>

  <div *ngIf="type == 'textarea'">
    <textarea
      [ngStyle]="{'height': height ? height : 'auto'}"
      class="form-control"
      [attr.rows]="rows ? rows : 5"
      [id]="'textarea_'+key"
      [disabled]="formControl.disabled || disabled"
      [value]="value"
      [attr.maxLength]="maxLength ? maxLength : null"
      (blur)="blur($event)"
      (change)="onChange($event)"
      (cut)="onChange($event)"
      (focus)="focus($event)"
      [attr.maxLength]="maxLength ? maxLength : null"
      (paste)="onChange($event)"
      (keyup)="onKeyup($event)"
      (keypress)="onKeypress($event)"
      [attr.autocomplete]="autocomplete ? autocomplete : key">
    </textarea>
  </div>

  <div *ngIf="type == 'file'" class="fieldset--haruki-filearea">
    <div [ngClass]="{'btn-file':true,' has-icon': fileButtonIcon}">
      <button class="btn btn-primary" (click)="openDialog($event)">
        <mmp5-mm-icons [icon]="['fa-solid','fa-paperclip']"></mmp5-mm-icons>
        {{ label }}
      </button>
      <div class="ms-1" *ngIf="hasFile && !filePreview">{{ hasFile }}</div>
      <div class="ms-1" *ngIf="!hasFile && !filePreview">{{ fileName ? fileName : ('users.management.createuser.nochoosefile' | translate)}}</div>
    </div>
    <ngx-file-drop
      *ngIf="fileDrop"
      headertext="Drop files here"
      (onFileDrop)="droppedFiles($event)"
      (click)="openDialog($event)"
      contentClassName="'fieldset--haruki-filearea-filedrop'"
      dropZoneLabel="fileDropLabel"
    >
    </ngx-file-drop>

    <ul class="list-group" *ngIf="listFiles && !returnBase64">
      <li class="list-group-item" *ngFor="let file of formControl.value; let i = index;">
        <div>{{ file.original.name }}</div>
        <div [ngClass]="{'img-container':true, 'dark':darkBG}">
          <img [src]="file.data.result" [alt]="file.original.name" *ngIf="filePreview && isImage(file.original.type)" />
          <button class="btn btn-outline-dark" (click)="removeSelectedFile($event, index)">X</button>
        </div>
      </li>
    </ul>
    <ul class="list-group" *ngIf="listFiles && returnBase64 && multipleFiles">
      <li class="list-group-item" *ngFor="let file of formControl.value; let i = index;">
        <div [ngClass]="{'img-container':true, 'dark':darkBG}">
          <img [src]="file" [alt]="'file'" *ngIf="filePreview" />
          <button class="btn btn-outline-dark" (click)="removeSelectedFile($event, index)">X</button>
        </div>
      </li>
    </ul>
    <ul class="list-group" *ngIf="listFiles && returnBase64 && !multipleFiles && formControl.value">
      <li class="list-group-item">
        <div [ngClass]="{'img-container':true, 'dark':darkBG}">
          <img [src]="formControl?.value" [alt]="'file'" *ngIf="filePreview" />
          <button class="btn btn-outline-dark" (click)="clearFormControl()">X</button>
        </div>
      </li>
    </ul>
  </div>
  <div class="input-group-append" *ngIf="type == 'number'" [attr.data-small]="small">
    <button [ngClass]="['btn', 'btn-'+colorPallet]" (click)="step($event, numberStepper, 'plus')" [disabled]="formControl.disabled || disabled">
      +
    </button>
  </div>


  <!-- search component ---------------------------------------------------------------------------->
  <div class="searchstyle" *ngIf="type === 'search'">
    <div class="input-group-append" id="btn-search">
      <button *ngIf="searchButton else noSearchButton" class="btn btn-primary" (click)="openDialog($event)" [disabled]="formControl.disabled">
        <mmp5-mm-icons [icon]="['icon','icon-wizard-1-e']"></mmp5-mm-icons>
      </button>
      <ng-template #noSearchButton>
        <mmp5-mm-icons class="no-button-search-icon" [icon]="['icon','icon-wizard-1-e']"></mmp5-mm-icons>
      </ng-template>
    </div>
  </div>

  <ngb-datepicker [ngClass]="['dropdown', positionDatepicker]" *ngIf="type == 'date'" firstDayOfWeek="7" [hidden]="hideCalendar" [navigation]="'select'" #dp (dateSelect)="dateSelect($event)"></ngb-datepicker>
  <ng-select
    *ngIf="selectOptions"
    #defaultinput
    notFoundText="Sem mais opções"
    (open)="expanded(true); ngFocus = true"
    (close)="expanded(false); ngFocus = false"
    (change)="onNgSelectChange($event); ngFocus = false"
    [(ngModel)]="selectValue"
    (clear)="clearNgSelect(); ngFocus = true"
    [clearable]="clearable"
    [disabled]="formControl.disabled"
    [selectOnTab]="true"
    (blur)="ngFocus = false"
    [openOnEnter]="true"
    [disabled]="formControl.disabled || disabled"
    [searchable]="canSearchList"
    [items]="selectOptions"
    [attr.data-label]="label ? 'true' : 'false'"
    class="form-control"
    [id]="selectValue"
    (keypress)="onKeypress($event)"
    [attr.data-tooltip]="tooltip != ''">
  </ng-select>

  <span class="form-text text-helper" *ngIf="helperText">{{ helperText }}</span>
  <a class="form-text text-helper" [class]="'link-input mb-1'" *ngIf="link" [routerLink]='link'>{{ linkText }}</a>

  <mat-checkbox
    [color] = "colorPallet"
    *ngIf="type == 'checkbox'"
    class="check"
    [checked]="checked"
    (change)="onChange($event)"
    [id]="key"
    [name]="key"
    [value]="checkboxValue"
    [disabled]="formControl?.disabled || disabled"
  >
    <div class="d-flex align-items-center">
      <p class="mb-0">
        {{label}}
      </p>
      <mmp-tooltip
        *ngIf="tooltipCheck"
        [text]="tooltipCheck"
        class="tooltip-radio"
        [icon]="tooltipIcon || ['icon','icon-tooltip']">
      </mmp-tooltip>
    </div>
  </mat-checkbox>

  <div class="checkboxGroup" *ngIf="type == 'checkboxGroup'">
    <ng-template ngFor [ngForOf]="options" let-opt>
      <mat-checkbox
        [color] = "colorPallet"
        class="check"
        [checked]="getChecked(opt)"
        (change)="onChange($event, opt)"
        [id]="key+'_'+opt"
        [name]="key+'_'+opt">{{opt}}
      </mat-checkbox>
    </ng-template>
  </div>

  <label *ngIf="type == 'radio' && label" [innerHTML]="label | safe:'html'"></label>
  <mat-radio-group
    [color]="colorPallet"
    *ngIf="type == 'radio'"
    [(ngModel)]="value"
    [value]="value"
    [ngStyle]="radioGroupStyle"
    (change)="onChange($event)"
    [id]="'radiogp_'+key"
    [name]="key">
    <ng-template [ngIf]="!objectRadio">
      <mat-radio-button [ngClass]="['mat-'+colorPallet || '']" [disabled]="formControl?.disabled || disabled" *ngFor="let opt of options; let i = index" [id]="'radio_opt_'+i+key" [value]="opt">{{opt}}</mat-radio-button>
    </ng-template>
    <ng-template [ngIf]="objectRadio">
      <div class="d-flex align-items-center mb-1" *ngFor="let opt of options; let i = index">
        <div *ngIf="options">
          <mat-radio-button [ngClass]="['mat-'+colorPallet || '']" [disabled]="formControl?.disabled || disabled" [id]="'radio_opt_'+i+key"  [value]="opt.value">
            <span [innerHTML]="opt?.name ? (opt?.name | safe:'html') : ''"></span>
          </mat-radio-button>
          <mmp-tooltip
            *ngIf="opt.tooltip"
            class="tooltip-radio"
            [text]="opt.tooltip"
            [icon]="opt.tooltipIcon || ['icon','icon-tooltip']">
          </mmp-tooltip>
        </div>
      </div>
    </ng-template>
  </mat-radio-group>

  <mmp-tooltip
    *ngIf="tooltip != ''"
    [text]="tooltip"
    class="tooltip-input"
    [icon]="tooltipIcon || ['icon','icon-tooltip']">
  </mmp-tooltip>

  <div
    *ngIf="formControl?.value && !selectOptions && !dropdown && type != 'date' && type != 'switch' && type != 'checkbox' && type != 'checkboxGroup' && type != 'radio' && type != 'file' && type != 'slider' && type != 'colorPicker' && !hideX"
    [ngClass]="(tooltip != '' || double) ? 'ng-select clear-all double' : 'ng-select clear-all'" [attr.data-label]="label ? 'true' : 'false'" >
    <span *ngIf="type != 'search' && type != 'number' && !(disabled || formControl.disabled)" class="ng-clear-wrapper ng-star-inserted" title="Clear all" (click)="clearNgSelect()"><span aria-hidden="true" class="ng-clear">×</span></span>
  </div>

  <div class="slider d-flex align-items-center" *ngIf="type === 'slider'">
    <span>{{sliderOptions?.min || 0}}</span>

    <mat-slider *ngIf="type === 'slider'"
      class="slider-mat flex-grow-1"
      [disabled]="disabled"
      [max]="sliderOptions?.max"
      [step]="sliderOptions?.step"
      [min]="sliderOptions?.min"
      [discrete]="sliderOptions?.discrete"
      [showTickMarks]="sliderOptions?.showTickMarks"
      (change)="onChange($event)"
    >
      <input matSliderThumb [(ngModel)]="value">
    </mat-slider>
    <span *ngIf="sliderOptions?.max">{{sliderOptions?.max}}</span>
  </div>

  <mmp5-error-validation [ngClass]="{'display-none': showError, 'display': !showError }" *ngIf="showError && type !='checkbox' && type != 'switch'" [field]="formControl"></mmp5-error-validation>
</div>

<div *ngIf="type == 'switch'" [class]="class ? class+' sb-toggle' : 'sb-toggle'">
  <input
    class="sb-toggle__checkbox"
    type="checkbox"
    [id]="'check_'+key"
    [name]="key"
    [checked]="checked"
    [ngClass]="{'form-control': true, 'input-color': true }"
    (change)="onChange($event)">
  <label class="sb-toggle__label sb-toggle__label--greenAndWhite" [for]="'check_'+key"></label>
</div>
