import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import * as lodash from 'lodash'
import deepash from 'deepdash'

import { Page } from 'projects/f-cms/src/app/models/page';
import { Router } from '@angular/router';
import { ContentService } from '../../../../content.service';

const _ = deepash(lodash)

@Component({
  selector: 'app-page-list',
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.scss']
})
export class PageListComponent implements OnInit {

  @Input() page$: BehaviorSubject<Page[]>
  @Input('picked$') picked$: BehaviorSubject<Page[]> = new BehaviorSubject([]);
  @Input('maxPages') maxPages: number
  @Input('selectHomepage') selectHomepage: boolean = false;
  @Input('onlyHomePage') onlyHomePage: boolean = false;
  @Input('showCheck') showCheck = false;
  @Input('id') id;
  @Input('pickedItemAttribute') pickedItemAttribute: string = 'id';
  @Input('pickedCheckedAttribute') pickedCheckedAttribute: string = 'id';
  @Input('type') type: 'SELECT_PAGE' | 'REDIRECT_TO_PAGE' = 'SELECT_PAGE';
  @Input('canSearch') canSearch: boolean = false;
  @Input('hasChild') hasChild = (_: number, node: Page) => node.hasChildren || node?.pages?.length > 0;
  @Input('treeControl') treeControl = new NestedTreeControl<Page>(node => node.pages)

  @ViewChild('componentContainer', { static: true }) componentContainer: ElementRef;

  @Output('selected') selected = new EventEmitter();

  dataSource = new MatTreeNestedDataSource<Page>();

  constructor(
    private _router: Router,
    private contentService: ContentService
  ) { }

  ngOnInit() {
    //this.page$ && this.page$.subscribe(page => this.getNodes(page))
    if (this.page$?.getValue()) {
      this.page$.subscribe(page => this.getNodes(page))
    } else {
      this.getHomepages()
    }
  }

  getHomepages() {
    this.contentService.contentMenuListByStepMain().subscribe({
      next: (menuData: any) => {
        const addEmptyPages = menuData.map(menu => ({ ...menu, hasChildren: true }));
        this.dataSource.data = addEmptyPages;
      }
    })
  }

  selectPage(event, node: Page) {
    if (this.type === 'SELECT_PAGE') {
      if (node.homePageParent) {
        this.addPicked(node)
      } else if (this.selectHomepage) {
        this.addPicked(node);
      }

      this.selected.emit(node);
    } else {
      this._router.navigate(['/hp/page', node.menuId]);
      this.selected.emit();
    }
  }

  checkSelected(node: Page) {
    let picked = []
    picked = this.picked$.value;

    return picked.filter(page => page[this.pickedCheckedAttribute] === node[this.pickedCheckedAttribute]).length > 0;
  }

  addPicked(node: Page) {
    let picked = this.picked$.value
    if (picked.filter(pick => pick[this.pickedItemAttribute] === node[this.pickedItemAttribute]).length) {
      picked = picked.filter(pick => pick[this.pickedItemAttribute] != node[this.pickedItemAttribute])
    } else {
      picked.push(node)
      picked = _.uniqBy(picked, (pick) => pick[this.pickedItemAttribute])
      if (this.maxPages) {
        if (this.maxPages == 1) {
          picked = [picked.pop()];
        } else {
          picked = picked.filter((p, i) => this.maxPages >= (i + 1));
        }
      }
    }
    this.picked$.next(picked)
  }

  getBreadcrumb(id: string, pages: Page[], path: string) {
    let bread;
    let page = _.filterDeep(
      pages,
      (value, key, { path, depth, parent, parentKey, parentPath }) => {
        if (path.id == id) {
          if (key == 'title') {
            let pathString: string = depth.path
            // let paths = pathString.split('.').filter(p => p != 'title').map((p) => `${p}.title`)
            let paths = []
            pathString.split('.').forEach((p, i, a) => i > 0 ? paths[i] = `${p}.title` : a.reduce((a, c, index) => index <= i ? `${a}.${c}` : ""))
            bread = paths.map(p => _.get(pages, p)).join(' / ')
            return true
          }
        }
      }
    )
    return bread
  }

  getNodes(page: Page[]): void {
    this.dataSource.data = page
    this.treeControl.dataNodes = page
    this.treeControl.collapseAll()
  }

  loadChildPages(e, node) {
    e.stopPropagation();

    const isFirstLevel = !node.pageId;

    if (isFirstLevel && !node.pages?.length) {
      if (!node.pages?.length) {
        this.contentService.contentMenuListChildren(node.id).subscribe({
          next: (menuData: any) => this.updateDataSourceData(menuData, node)
        });
      }
    } else {
      if (!node.pages?.length) {
        this.contentService.contentMenuListChildrenSubpages(node.id).subscribe({
          next: (menuData: any) => this.updateDataSourceData(menuData, node)
        });
      }
    }
  }

  updateDataSourceData(menuData, node) {
    node.pages = menuData;
    const updatedNodes = [...this.dataSource.data];

    this.dataSource.data = null;
    this.dataSource.data = updatedNodes;
    this.treeControl.dataNodes = updatedNodes;
  }

  search(seachObj) {
    if (seachObj.name) {
      this.contentService.contentMenuSearch(
        seachObj.name,
        seachObj.published,
        seachObj.expired,
        seachObj.validDate,
        seachObj.noPublisedDate,
        ''
      ).subscribe({
        next: (menuData: any) => {
          this.dataSource.data = menuData;
          this.treeControl.dataNodes = menuData;
          this.treeControl.expandAll();
        }
      });
    } else {
      this.dataSource.data = [];
      this.getHomepages();
    }
  }
}

