import { UserPhoto } from "../interfaces/userInterface";

export interface ChatConversationsInterface extends chatMessages{
  clientId:       string,
  createdAt:      string,
  createdByUser:  chatUserInterface,
  lastMessage?:   chatMessages,
  name:           string,
  updatedAt:      string,
  users:          chatUserInterface[],
  usersPhotos:    any,
  receivedMessage:number,
  __v:            number,
  _id:            string,
}

export interface chatUserInterface{
  clientId:     string,
  createdAt:    string,
  displayName:  string,
  fullName:     string;
  login:        string,
  photo:        UserPhoto,
  updatedAt:    string,
  userId:       string,
  __v:          number,
  _id:          string,
}

export interface chatMessages{
  clientId: string,
  createdAt: string,
  messageType: string,
  roomId: string,
  text: string,
  updatedAt: string,
  user: chatUserInterface | any,
  __v: number,
  _id: string,
}
export interface UserFullName{
  fullName: string;
}
