import { NgModule } from "@angular/core";
import { MMngxPaginationComponent } from "./ngx-pagination.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
        MMngxPaginationComponent
    ],
    imports: [
        CommonModule
    ], 
    exports: [
        MMngxPaginationComponent
    ]
})

export class MMngxPaginationModule{}