import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { FieldsetHarukiSelectOption } from '../fieldset-haruki/fieldset-haruki-select-option';

@Component({
  selector: 'mm-alice-keys-config',
  templateUrl: './alice-keys-config.component.html',
  styleUrls: ['./alice-keys-config.component.scss']
})
export class AliceKeysConfigComponent implements OnInit {

  @Input('forms') forms: UntypedFormArray;
  @Input('type') type: 'LICENSE' | 'CMS' = 'CMS';

  @Output('addNew') addNewEmitter = new EventEmitter();
  @Output('removeForm') removeFormEmitter = new EventEmitter();

  typeOptions: FieldsetHarukiSelectOption[] = [
    { label: 'Mensagem de Sistema', value: 'SYSTEM_MESSAGE' },
    { label: 'Conector Externo', value: 'EXTERNAL_CONNECTOR' },
  ];

  constructor() { }

  ngOnInit() {
    if (this.type === 'LICENSE') {
      this.typeOptions.push(
        { label: 'Definição de tabela', value: 'TABLE_DEFINITION' },
        { label: 'Modelo LLM', value: 'MODEL' },
        { label: 'Modelo de Embedding', value: 'MODEL_EMBEDDING' },
        { label: 'Tamanho máximo dos segmentos de indexação', value: 'DOCUMENT_SPLITTER_SEGMENT_SIZE' },
        { label: 'Dimensão do index', value: 'INDEX_DIMENSION' },
        { label: 'Quantidade de items na busca', value: 'SEARCH_RESULTS_SIZE' },
        { label: 'Modelo de LLM mais barato', value: 'CHEAPER_MODEL' },
        { label: 'Modelo de Ferramentas', value: 'TOOLS_MODEL' },
      );
    }
  }

  addNew() {
    this.addNewEmitter.emit();
  }

  removeForm(index: number) {
    this.removeFormEmitter.emit(index);
  }
}
