<div *ngIf="active" class="zoomImageComponent d-flex justify-content-center align-items-center flex" (click)="close()">
    <div class="media-header" *ngIf="media">
        <div class="name-desc">
            <h1>{{media.title}}</h1>
            <p>{{media.description}}</p>
        </div>
        <div class="download-link d-flex">
            <!-- <i class="fa fa-download" (click)="downloadMedia(); $event.stopPropagation()"></i> -->
            <!-- <i class="fa fa-times"></i> -->
            <mmp5-mm-icons [icon]="['fa-solid','fa-xmark']"></mmp5-mm-icons>
        </div>
    </div>
    <div *ngIf="!pdfUrl && !imageUrl && !content" class="info-no-media-view">
        <h3>O formato do arquivo que você está tentando visualizar não é suportado por este visualizador.</h3>
    </div>
    <img *ngIf="imageUrl" [src]="imageUrl | authImage:'image':iTk:mdSrvc | async" />

    <div class="pdf" *ngIf="pdfUrl">
        <ng-scrollbar [visibility]="'hover'" [autoHeightDisabled]="false" [track]="'vertical'">
            <div class="pdf-view">
                <pdf-viewer [src]="pdfUrl" [zoom]="0.8" [(page)]="page" [show-all]="true" [original-size]="true" [stick-to-page]="true" [fit-to-page]="false"></pdf-viewer>
            </div>
        </ng-scrollbar>
    </div>
    <div class="pdf-options" *ngIf="pdfUrl">
        <!-- <button class="btn btn-primary" (click)="page = page - 1; $event.stopPropagation()"><</button>
        <button class="btn btn-primary" (click)="page = page + 1; $event.stopPropagation()">></button> -->
    </div>
    <div class="zoom-ng-content">
        <ng-content></ng-content>
    </div>
    <!-- <mmp5-mm-pdf-viewer *ngIf="pdfUrl" [url]="pdfUrl"></mmp5-mm-pdf-viewer> -->
    <div (click)="closed.emit(true)" class="zoomImageComponent-backdrop">
    </div>
</div>
