<div class="menu">
  <div class="row mt-2">
    <div class="col-md-4 col-sm-12 template" (click)="displaysCurrentTemplates($event, 'optionSolidDivided')">
      <div class="template__item" [ngClass]="{'template__item--selected': currentTemplate === 'optionSolidDivided'}">
        <div class="template__img" (click)="selectTemplateType('overlappedBotton')">
          <img src="../../../../../assets/images/banner-tipo-1-esquerda.png" alt="">
        </div>
        <div class="template__title">
          <h6>{{'content.managerBanner.side' | translate}} </h6>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 template" (click)="displaysCurrentTemplates($event, 'overlappedWholeImage')">
      <div class="template__item" [ngClass]="{'template__item--selected': currentTemplate === 'overlappedWholeImage'}">
        <div class="template__img">
          <img src="../../../../../assets/images/banner-tipo-2.png" alt=""> 
        </div>
        <div class="template__title">
          <h6>{{'content.managerBanner.wholeImage' | translate}}</h6>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 template" (click)="displaysCurrentTemplates($event, 'textBoxBottom')">
      <div class="template__item" [ngClass]="{'template__item--selected': currentTemplate === 'textBoxBottom'}">
        <div class="template__img" (click)="selectTemplateType('FULL_IMAGE_WITH_BOX_UNDERNEATH_1000x100')">
          <img src="../../../../../assets/images/banner-tipo-3.png" alt=""> 
        </div>
        <div class="template__title">
          <h6>{{'content.managerBanner.downImage' | translate}}</h6>
        </div>
      </div>
    </div>
  </div>
  <!-- template half color with solidity -->
  <div class="row" *ngIf="currentTemplate == 'optionSolidDivided'">
    <div class="col-md-4 col-sm-12 template">
      <div class="template__item" [ngClass]="{'template__item--selected': currentTemplateType === 'IMAGE_ON_LEFT'}">
        <div class="template__img" (click)="selectTemplateType('IMAGE_ON_LEFT')">
          <img src="../../../../../assets/images/banner-tipo-1-direita.png" alt="">
        </div>
        <div class="template__title">
          <h6>{{'content.managerBanner.leftImage' | translate}}</h6>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 template">
      <div class="template__item" [ngClass]="{'template__item--selected': currentTemplateType === 'IMAGE_ON_RIGHT'}">
        <div class="template__img" (click)="selectTemplateType('IMAGE_ON_RIGHT')">
            <img src="../../../../../assets/images/banner-tipo-1-esquerda.png" alt="">
        </div>
        <div class="template__title">
          <h6>{{'content.managerBanner.rightImage' | translate}}</h6>
        </div>
      </div>
    </div>
  </div>
  <!-- template whole image with overlay -->
  <div class="row" *ngIf="currentTemplate == 'overlappedWholeImage'">
    <div class="col-md-4 col-sm-12 template">
      <div class="template__item" [ngClass]="{'template__item--selected': currentTemplateType === 'TEXT_OVERLAY_ON_LEFT'}">
        <div class="template__img" (click)="selectTemplateType('TEXT_OVERLAY_ON_LEFT')">
          <img src="../../../../../assets/images/banner-tipo-2-esquerda.png" alt="">
        </div>
        <div class="template__title">
          <h6>{{'content.managerBanner.overlapWholeImage' | translate}}</h6>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 template">
      <div class="template__item" [ngClass]="{'template__item--selected': currentTemplateType === 'TEXT_OVERLAY_ON_CENTER'}">
        <div class="template__img" (click)="selectTemplateType('TEXT_OVERLAY_ON_CENTER')">
          <img src="../../../../../assets/images/banner-tipo-2-central.png" alt="">
        </div>
        <div class="template__title">
          <h6>{{'content.managerBanner.overlapCenter' | translate}}</h6>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 template">
      <div class="template__item" [ngClass]="{'template__item--selected': currentTemplateType === 'TEXT_OVERLAY_ON_RIGHT'}">
        <div class="template__img" (click)="selectTemplateType('TEXT_OVERLAY_ON_RIGHT')">
          <img src="../../../../../assets/images/banner-tipo-2-direita.png" alt="">
        </div>
        <div class="template__title">
          <h6>{{'content.managerBanner.overlapRight' | translate}}</h6>
        </div>
      </div>
    </div>
  </div>
</div>
