import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { ContentService } from "./content.service";

@Injectable({providedIn: 'root'})
export class ContentMenuResolver {


    constructor(private contentService: ContentService,){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        // return  this.contentService.ContentMenuList()
        return of([]);
    }
}