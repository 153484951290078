<div [ngClass]="['tabs-content', child ? '' : 'margin']">
    <div class="panel with-nav-tabs panel-dark">
        <div class="card-header-tab">
            <ul class="nav nav-tabs">
                <li *ngFor="let tab of content?.contents; let i = index" [ngClass]="['nav-item']">
                    <a (click)="openTabs[content?.identifier] = i; getTabsContent(content, tab)" data-toggle="tab" [ngClass]="['nav-link', openTabs[content?.identifier] == i ? 'active' : '', openTabs[content?.identifier] === undefined && i == 0 ? 'active' : '']">
                        <dynamic-html [ngClass]="{'dynamic-html':true, 'blockCopy': this.blockCopy}" [content]="tab?.title"></dynamic-html>
                    </a>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <div class="tab-content">
                <div [ngClass]="['tab-pane', 'fade', 'in', openTabs[content?.identifier] == i ? 'active' : '', openTabs[content?.identifier] === undefined && i == 0 ? 'active' : '']" id="tab{{tab?.identifier}}" *ngFor="let tab of content?.contents; let i = index">
                  <p *ngIf="downloading == true">{{'general.loading' | translate}}</p>
                  <dynamic-html [ngClass]="{'dynamic-html':true, 'blockCopy': this.blockCopy}" [content]="tab?.htmlContent"></dynamic-html>
                  <div *ngIf="tab?.contents?.length > 0">
                    <html-plus [contents]="tab.contents" [child]="true" [not-a-feature]="notAFeature"></html-plus>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

