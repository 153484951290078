import { NgModule } from "@angular/core";
import { UserValue } from "./userValue.pipe";
import { UserStatusPipe } from "./userStatus.pipe";
import { TrueFalsePipe } from "./trueFalse.pipe";
import { TimesPipe } from "./times.pipe";
import { AuthImagePipe } from "./authImage.pipe";
import { ObjectKeys } from "./ObjectKeys.pipe";
import { FilterByAttr } from "./filterByAttr.pipe";
import { SafePipe } from "./safe.pipe";

@NgModule({
    declarations: [
        UserValue,
        AuthImagePipe,
        ObjectKeys,
        FilterByAttr,
        SafePipe
    ],
    exports: [
        UserValue,
        AuthImagePipe,
        ObjectKeys,
        FilterByAttr,
        SafePipe
    ]
})

export class PipesModules{

}
