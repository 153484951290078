import { Homepage, HomepageData, HomepageColorPayload, HomepageBannerPayload, Widget } from "../../../models/homepage";
import { Injectable } from '@angular/core'
import { Action } from '@ngrx/store'

export const SET_FORM = '[HOMEPAGEDEFINITION] Set'
export const SET_FORM_EDIT = '[HOMEPAGEDEFINITION] Set Edit'
export const RESET_FORM = '[HOMEPAGEDEFINITION] Reset'
export const SET_HOMEPAGE_DATA = '[HOMEPAGEDEFINITION] Set Homepage Data'
export const SET_HOMEPAGE_LOGO = '[HOMEPAGEDEFINITION] Set Homepage Logo'
export const SET_HOMEPAGE_COLORS = '[HOMEPAGEDEFINITION] Set Homepage Colors'
export const SET_HOMEPAGE_BANNER = '[HOMEPAGEDEFINITION] Set Homepage Banner'
export const SET_HOMEPAGE_WIDGET = '[HOMEPAGEDEFINITION] Set Homepage Wiget'
export const SET_HOMEPAGE_WIDGET_CONFIG = '[HOMEPAGEDEFINITION] Set Homepage Wigdet Config'


export class SetForm implements Action{
    readonly type = SET_FORM;

    constructor(public payload: Homepage){}
}

export class SetFormEdit implements Action{
    readonly type = SET_FORM_EDIT;

    constructor(public payload: Homepage){}
}

export class SetHomepageData implements Action{
    readonly type = SET_HOMEPAGE_DATA

    constructor(public payload: HomepageData){}
}

export class SetHomepageColors implements Action {
    readonly type = SET_HOMEPAGE_COLORS

    constructor(public payload: HomepageColorPayload){}
}

export class SetHomepageBanner implements Action {
    readonly type = SET_HOMEPAGE_BANNER

    constructor(public payload: HomepageBannerPayload){}
}

export class SetHomepageLogo implements Action {
    readonly type = SET_HOMEPAGE_LOGO

    constructor(){}
}

export class SetHomepageWidget implements Action {
    readonly type = SET_HOMEPAGE_WIDGET

    constructor(public payload: Widget){}
}

export class SetHomepageWidgetsConfig implements Action {
    readonly type = SET_HOMEPAGE_WIDGET_CONFIG

    constructor(public payload: Widget){}
}


export class ResetForm implements Action {
    readonly type = RESET_FORM;

    constructor(){}
}

export type Actions = SetForm | 
                      SetFormEdit |
                      ResetForm |
                      SetHomepageData | 
                      SetHomepageColors | 
                      SetHomepageBanner |
                      SetHomepageWidget |
                      SetHomepageWidgetsConfig |
                      SetHomepageLogo