import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FieldsetHarukiSelectOption } from '../fieldset-haruki/fieldset-haruki-select-option';

@Component({
  selector: 'mm-dual-list',
  templateUrl: './mm-duallist.component.html',
  styleUrls: ['./mm-duallist.component.scss']
})
export class MmDuallistComponent implements OnInit {

  @Input('source') source: FieldsetHarukiSelectOption[];

  @Input('destination') destination: FieldsetHarukiSelectOption[] = [];
  @Output() destinationChange = new EventEmitter<FieldsetHarukiSelectOption[]>();
  selectedItem: FieldsetHarukiSelectOption;
  selectedItemIndex: string;
  addedSelectedItemIndex: number;

  constructor() { }

  ngOnInit(): void {
  }

  selectItem(item: FieldsetHarukiSelectOption, index: string){
    this.selectedItem = item;
    this.selectedItemIndex = index;
  }

  selectAddedItem(item: FieldsetHarukiSelectOption, index: number){
    this.addedSelectedItemIndex = index;
  }

  addItem(){
    this.destination.push(this.selectedItem);
    this.destinationChange.emit(this.destination);
    this.source = this.source.filter(item => item.value != this.selectedItem.value);
    this.selectedItemIndex = undefined;
    this.selectedItem = undefined;
  }

  removeItem(){
    let item = this.destination[this.addedSelectedItemIndex];
    this.destination = this.destination.filter((item, index) => index != this.addedSelectedItemIndex);
    this.source.push(item);
    this.addedSelectedItemIndex = undefined;
    this.destinationChange.emit(this.destination);
  }
}
