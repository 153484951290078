import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'mmp5-accessdenied',
    templateUrl: './accessdenied.component.html',
    styleUrls: ['./accessdenied.component.scss']
  })
export class  AccessDeniedComponent implements OnInit {

    clicked: any = {};
    user: any;
    @Input() imgurl: Array<string> = ['']
    @Input() title: Array<string> = ['']
    @Input() message: Array<string> = ['']
    @Input() imgclass: Array<string> = ['']
    @Input() subMessage: string;


    constructor() {
    }

    ngOnInit() {
      
  
    }

    searchAutocomplete() {
        return new Observable( observer => {
          observer.next([{ name: 'name 1' }, { name: 'name 2' }]);
        });
      }
    
      handleClick(event) {
        this.clicked = event;
      }
}
