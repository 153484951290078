import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AppConfig } from 'projects/f-cms/src/app/app.config';
import { CustomAlert, CustomAlertPageable } from 'projects/f-cms/src/app/interfaces/customAlert';
import { of } from 'rxjs';
import { tap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CustomAlertService {
  apiVersion = `v${AppConfig.data['apiVersion']}`;
  service = AppConfig.data['services']['cms'];
  endpoint = `${this.service}/${this.apiVersion}`
  alertList: CustomAlert[];

  constructor(
    private http: HttpClient
  ) { }


  getAlertsList() {
    return this.http.get<CustomAlert[]>(`${this.endpoint}/customAlerts/list`).pipe(tap(alerts => this.alertList = alerts))
    /* if(this.alertList){
        return of<CustomAlert[]>(this.alertList);
    }else {
    } */
  }

  getAlertsListPageable(page: number, size: number) {
    return this.http.get<CustomAlertPageable>(`${this.endpoint}/customAlerts/list/pageable?page=${page}&size=${size}`)
  }

  async getAlertsListAsy() {
    return await this.http.get<CustomAlert[]>(`${this.endpoint}/customAlerts/list`).toPromise()
  }

  async getAlertByIdAsy(id: string) {
    return await this.http.get<CustomAlert>(`${this.endpoint}/customAlerts/${id}`).toPromise()
  }

  getAlertById(id: string) {
    return this.http.get<CustomAlert>(`${this.endpoint}/customAlerts/${id}`)
  }
}
