import { Homepage } from "./homepage";
import { Template } from "../interfaces/template";
import { BreadcrumbFeatureConfig, HTMLFeatureConfig, TitleFeatureConfig, ListPageConfig, featureType, PageListContent, PageListPage } from "../interfaces/featuresConfig";
import { Workflow, WorkflowStatus } from "./workflow";
import { UserInterface } from "../interfaces/userInterface";
import { AclInterface } from "./aclInterface";
import { Pageable } from "../interfaces/pageable";
import { Observable } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { PageList } from "./widgets";

// Generated by https://quicktype.io

export interface PageFather {
  id: string;
  templateTitle?: string;
  title?: string;
}

export interface Page {
  homePage?: Homepage;
  homePageParent: string;
  pageFather: PageFather
  id: string;
  menuId?: string;
  pageId?: string;
  title: string;
  topViewMenu: boolean;
  version?: string;
  menuTitle: string;
  publishingDate: string;
  expirationDate: string;
  pages: Page[]
  description: string;
  editNote?: string;
  nameCoverImage: string;
  isObsolete?: boolean;
  base64CoverImage?: any;
  createdById: string;
  creationDate?: string;
  creationHour?: string;
  creationDateOnlyDate?: string
  lastModifiedById?: string;
  lastModifiedBy?: UserInterface;
  createdBy?: UserInterface;
  lastModifiedDate?: string;
  lastModifiedHour?: string;
  lastModifiedDateOnlyDate?: string
  createdByIp: string;
  lastModifiedByIp: string;
  expired: boolean;
  published: boolean;
  order: number
  publishingHour?: string;
  expirationHour?: string;
  invalid?: boolean;
  featureContents?: FeatureContent[],
  features?: (BreadcrumbFeatureConfig | HTMLFeatureConfig | TitleFeatureConfig | ListPageConfig)[]
  error?: any;
  futurePublishingDate?: boolean;
  publishingDateNull?: boolean;
  inheritTemplate: string;
  template: Template;
  coverImage?: CoverImage;
  inheritWorkflow?: boolean;
  workflow?: WorkflowStatus;
  workflowId?: string;
  pageVersion?: PageVersionClass;
  pagesUpdateResult?: any[];
  widget?: {};
  sideBar?: any;
  acl?: AclInterface;
  canEditAcl?: boolean;
  canSegregateAcl?: boolean;
  inheritAcl?: boolean;
  userCanWrite?: boolean;
  userCanRead?: boolean;
  parentId?: string;
  originalMenuId?: string;
  importation?: boolean;
  tags?: string[];
  feedbackConfig?: FeedbackConfig;
  commentConfig?: CommentConfig;
  templateId?: string;
  feedbackAverage?: any;
  keywords?: any;
  accessed?: boolean;
  read?: boolean;
  hasChildren?: boolean;
  observation: string
}
// Generated by https://quicktype.io

export interface PageVersionClass {
  id: string;
  publishingDate: string;
  versionNumber: string;
}

// Generated by https://quicktype.io

export interface FeatureContents {
  featureContents: FeatureContent[];
}

export interface FeatureContent {
  feature: (BreadcrumbFeatureConfig | HTMLFeatureConfig | TitleFeatureConfig | ListPageConfig);
  contentType: string;
  htmlContent: string;
  id: string;
}

export interface Feature {
  id: string;
  featureType: string;
}

export interface CoverImage {
  fileName: string,
  mediaUrl: string,
  lastModifiedDate: string,
  id: string,
  type: featureType.coverImage,
  creationDate: string,
  mediaId: string,
  contentType: string,
  base64: any
}

// Generated by https://quicktype.io

export interface SiteMapPages {
  id: string;
  menuId: string;
  title: string;
  subPagesVisible: boolean;
  pages?: SiteMapPages[]
}

export interface versionDetail {
  id?: string,
  versionNumber?: string,
  publishingDate?: string,
  lastModifiedById: string,
  lastModifiedBy: {
    notViewUser: boolean,
    clientId: string,
    fullName: string,
    photo: {
      mediaUrl: string,
      mediaId: string
    }
  }
}

export interface CompareVersion {
  pageFields: string[],
  newPageVersion: Page,
  oldPageVersion: Page
}

export interface PagesPageable extends Pageable {
  content: Page[];
}

export enum OrderParamEnum {
  moreRecentFirst = 'MORE_RECENT_FIRST',
  oldestFirst = 'OLDEST_FIRST',
  negativeFirst = 'NEGATIVE_FIRST',
  positiveFirst = 'POSITIVE_FIRST'
}

// Generated by https://quicktype.io

export interface PageComment {
  id: string;
  clientId: string;
  createdById: string;
  creationDate: string;
  text: string;
  status: CommentStatus
  lastModifiedById: null;
  lastModifiedDate: null;
  pageId: string;
  menuTitle: string;
  title: string;
  description: null;
  homePageId: string;
  breadcrumb: Breadcrumb;
  pageBreadcrumb?: Observable<{ breadcrumb: Breadcrumb }>
  select?: UntypedFormControl
  from?: string
}

export enum CommentStatus {
  pending = "PENDING",
  ok = "OK",
  notOk = "NOT_OK",
  out = "OUT"
}

export interface Breadcrumb {
  textSize: string;
  labelFirstLevel: string;
  displayLimit: boolean;
  itemsBetweenEllipsis: number;
  separator: string;
  pages: PageBreadcrumb[];
}

export interface PageBreadcrumb {
  id: string;
  title: string;
}

// Generated by https://quicktype.io

export interface WriteGroup {
  name: string;
  id: string;
}

export interface CommentConfig {
  commentApprovalGroups: any[];
  allowedClap: boolean;
  allowedLike: boolean;
  hasApproval: string;
  numberDenunciationOccurrence: number;
  justWriteGroupsCanRepply: boolean;
  perUser: boolean;
  noticeType: string;
  allowedDislike: boolean;
  whoWillApprove: string;
  limitedDenunciation: boolean;
}export interface FeedbackConfig {
  gradeFiveText: string;
  groups: any[];
  perUser: boolean;
  emailTo: string;
  introText: string;
  gradeFourText: string;
  gradeThreeText: string;
  definedEmailTo: string;
  gradeOneText: string;
  gradeTwoText: string;
  showOtherUsersFeedbacks: boolean;
  id: null;
  categories: WriteGroup[];
  requireTextFeedback: boolean;
  gradeRequiredTextFeedback: number;
}
